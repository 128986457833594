import React from "react";
import "./style.css";
import { CgFacebook } from "react-icons/cg";
import { FaWhatsappSquare } from "react-icons/fa";
import CustomSeparator from "../../../components/common/customrSeparator";
import CustomSpacer from "../../../components/common/customSpacer";

const PageFooter = () => {
    return (
        <div className="page-footer-content flex-colum">
            <div className="page-footer">
                <div className="page-footer-items">
                    <span className="column-title margin-bottom-10">
                        Company
                    </span>
                    <CustomSeparator size={8} />
                    <div className="flex-colum">
                        <a href="/#">What we do</a>
                    </div>
                    <CustomSeparator size={5} />
                    <div className="flex-colum">
                        <a href="/#">How it works</a>
                    </div>
                </div>
                <div className="page-footer-items">
                    <span className="column-title margin-bottom-10">
                        Contact
                    </span>
                    <CustomSeparator size={8} />
                    <div className="flex-colum">
                        <a href="/#">Phone: +250 782 015 589</a>
                        <CustomSeparator size={5} />
                        <a href="/#">Email: nankim45@gmail.com</a>
                    </div>
                </div>
                <div className="page-footer-items">
                    <span className="column-title margin-bottom-10">
                        Follow Us
                    </span>
                    <CustomSeparator size={8} />
                    <div className="last-row">
                        <a href="/#">
                            <CgFacebook size={30} />
                        </a>
                        <CustomSpacer />
                        <a href="/#">
                            <FaWhatsappSquare size={30} />
                        </a>
                    </div>
                </div>
            </div>
            <div className="site-footer">
                <span>© Copyright nanKim. All Rights Reserved</span>
            </div>
        </div>
    );
};

export default PageFooter;
