import { createAsyncThunk } from "@reduxjs/toolkit/dist";
import apiRequest from "../../../../../helpers/apiActions/apiRequest";
import endPoints from "../../../../../utils/Endpoint";

export const UpdatePaymentRequest = createAsyncThunk(
    "updatePaymentRequest",
    async (requestData, { rejectWithValue }) => {
        const response = await apiRequest({
            url: endPoints.updatePaymentRequest,
            method: "PUT",
            requestData: requestData,
            rejectWithValue: rejectWithValue,
        });
        return response;
    }
);
