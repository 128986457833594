import { createSlice } from "@reduxjs/toolkit";
import { SetClassTimeManagement } from "../../../actions/school/otherConfiguration/setClassTimeManagementActions";

const initialState = {
    setClassTimeManagement: null,
    LoadSetClassTimeManagement: false,
    setClassTimeManagementError: null,
};

const setClassTimeManagementSlice = createSlice({
    name: "setClassTimeManagement",
    initialState: initialState,
    reducers: {
        clearSetClassTimeManagement: (state) => {
            state.setClassTimeManagement = null;
            state.setClassTimeManagementError = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(SetClassTimeManagement.pending, (state) => {
                state.LoadSetClassTimeManagement = true;
                state.setClassTimeManagementError = null;
            })
            .addCase(SetClassTimeManagement.fulfilled, (state, action) => {
                state.LoadSetClassTimeManagement = false;
                state.setClassTimeManagement = action.payload;
            })
            .addCase(SetClassTimeManagement.rejected, (state, action) => {
                state.LoadSetClassTimeManagement = false;
                state.setClassTimeManagementError = action.payload;
            });
    },
});

export const { clearSetClassTimeManagement } =
    setClassTimeManagementSlice.actions;

export default setClassTimeManagementSlice.reducer;
