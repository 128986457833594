import { createSlice } from "@reduxjs/toolkit";
import { RetrieveEmployeeWithSalary } from "../../../actions/school/employee/retrieveEmployeeWithSalary";

const initialState = {
    retrieveEmployeeWithSalary: null,
    LoadRetrieveEmployeeWithSalary: false,
    retrieveEmployeeWithSalaryError: null,
};

const retrieveEmployeeWithSalarySlice = createSlice({
    name: "retrieveEmployeeWithSalary",
    initialState: initialState,
    reducers: {
        clearRetrieveEmployeeWithSalary: (state) => {
            state.retrieveEmployeeWithSalary = null;
            state.retrieveEmployeeWithSalaryError = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(RetrieveEmployeeWithSalary.pending, (state) => {
                state.LoadRetrieveEmployeeWithSalary = true;
                state.retrieveEmployeeWithSalaryError = null;
            })
            .addCase(RetrieveEmployeeWithSalary.fulfilled, (state, action) => {
                state.LoadRetrieveEmployeeWithSalary = false;
                state.retrieveEmployeeWithSalary = action.payload;
            })
            .addCase(RetrieveEmployeeWithSalary.rejected, (state, action) => {
                state.LoadRetrieveEmployeeWithSalary = false;
                state.retrieveEmployeeWithSalaryError = action.payload;
            });
    },
});

export const { clearRetrieveEmployeeWithSalary } =
    retrieveEmployeeWithSalarySlice.actions;

export default retrieveEmployeeWithSalarySlice.reducer;
