import React from "react";
import { Drawer, Table, Space, Button, Badge } from "antd";
import { AiOutlineCloseCircle } from "react-icons/ai";

const StudentMarks = ({
    open,
    onClose,
    setOpenDrawer,
    student,
    retrieveStudentMarks,
}) => {
    const column = [
        {
            title: "Course",
            dataIndex: "course",
            key: "course",
        },
        {
            title: "Total Score",
            dataIndex: "studentScores",
            key: "studentScores",
        },
        {
            title: "Total Marks",
            dataIndex: "totalMarks",
            key: "totalMarks",
        },
        {
            title: "Status",
            dataIndex: "xxx",
            key: "xxx",
            render: (_, state) => (
                <Space>
                    <Badge
                        status={state.isArchived === true ? "success" : "error"}
                        text={state.isArchived === true ? "Archived" : "Open"}
                    />
                </Space>
            ),
        },
    ];
    const tableData =
        retrieveStudentMarks?.data &&
        retrieveStudentMarks?.data.map((item, index) => ({
            key: index,
            name: `${item?.first_name} ${item?.last_name}`,
            isArchived: item?.item_archived,
            isFinalGrade: item?.is_final_grade,
            isNormalEvaluation: item?.is_normal_evaluation,
            isTerm: item?.is_term,
            lastName: item?.last_name,
            moduleId: item?.module_id,
            course: item?.name,
            sex: item?.sex,
            state: item?.status,
            studentScores: item?.student_scores,
            totalMarks: item?.total_marks,
        }));
    return (
        <Drawer
            width={800}
            title={`${student?.student} (${student?.studentCode})`}
            placement="right"
            open={open}
            closable={false}
            destroyOnClose={false}
            closeIcon
            extra={
                <Space className="flex-row-sb">
                    <Button
                        danger
                        shape="circle"
                        type="text"
                        onClick={() => {
                            onClose();
                        }}
                    >
                        <AiOutlineCloseCircle size={25} />
                    </Button>
                </Space>
            }
        >
            <Table columns={column} pagination={false} dataSource={tableData} />
        </Drawer>
    );
};

export default StudentMarks;
