import { createSlice } from "@reduxjs/toolkit";
import { RecordNewCourse } from "../../../actions/school/teachers/recordNewCourseActions";

const initialState = {
    recordNewCourse: null,
    LoadRecordNewCourse: false,
    recordNewCourseError: null,
};

const recordNewCourseSlice = createSlice({
    name: "recordNewCourse",
    initialState: initialState,
    reducers: {
        clearRecordNewCourse: (state) => {
            state.recordNewCourse = null;
            state.recordNewCourseError = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(RecordNewCourse.pending, (state) => {
                state.LoadRecordNewCourse = true;
                state.recordNewCourseError = null;
            })
            .addCase(RecordNewCourse.fulfilled, (state, action) => {
                state.LoadRecordNewCourse = false;
                state.recordNewCourse = action.payload;
            })
            .addCase(RecordNewCourse.rejected, (state, action) => {
                state.LoadRecordNewCourse = false;
                state.recordNewCourseError = action.payload;
            });
    },
});

export const { clearRecordNewCourse } = recordNewCourseSlice.actions;

export default recordNewCourseSlice.reducer;
