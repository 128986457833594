import { createSlice } from "@reduxjs/toolkit";
import { GenerateAssessmentFile } from "../../../actions/teacher/levels/generateAssessmentFileAction";

const initialState = {
    generateAssessmentFile: null,
    LoadGenerateAssessmentFile: false,
    generateAssessmentFileError: null,
};

const generateAssessmentFileSlice = createSlice({
    name: "generateAssessmentFile",
    initialState: initialState,
    reducers: {
        clearGenerateAssessmentFile: (state) => {
            state.generateAssessmentFile = null;
            state.generateAssessmentFileError = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GenerateAssessmentFile.pending, (state) => {
                state.LoadGenerateAssessmentFile = true;
                state.generateAssessmentFileError = null;
            })
            .addCase(GenerateAssessmentFile.fulfilled, (state, action) => {
                state.LoadGenerateAssessmentFile = false;
                state.generateAssessmentFile = action.payload;
            })
            .addCase(GenerateAssessmentFile.rejected, (state, action) => {
                state.LoadGenerateAssessmentFile = false;
                state.generateAssessmentFileError = action.payload;
            });
    },
});

export const { clearGenerateAssessmentFile } =
    generateAssessmentFileSlice.actions;

export default generateAssessmentFileSlice.reducer;
