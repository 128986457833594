import { createSlice } from "@reduxjs/toolkit";
import { FetchDefaultClassLevels } from "../../../actions/school/levels/fetchDefaultClassLevelsReducer";

const initialState = {
  fetchDefaultClassLevels: null,
  LoadFetchDefaultClassLevels: false,
  fetchDefaultClassLevelsError: null,
};

const fetchDefaultClassLevelsSlice = createSlice({
  name: "fetchDefaultClassLevels",
  initialState: initialState,
  reducers: {
    clearFetchDefaultClassLevels: (state) => {
      state.fetchDefaultClassLevels = null;
      state.fetchDefaultClassLevelsError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(FetchDefaultClassLevels.pending, (state) => {
        state.LoadFetchDefaultClassLevels = true;
        state.fetchDefaultClassLevelsError = null;
      })
      .addCase(FetchDefaultClassLevels.fulfilled, (state, action) => {
        state.LoadFetchDefaultClassLevels = false;
        state.fetchDefaultClassLevels = action.payload;
      })
      .addCase(FetchDefaultClassLevels.rejected, (state, action) => {
        state.LoadFetchDefaultClassLevels = false;
        state.fetchDefaultClassLevelsError = action.payload;
      });
  },
});

export const { clearFetchDefaultClassLevels } =
  fetchDefaultClassLevelsSlice.actions;

export default fetchDefaultClassLevelsSlice.reducer;
