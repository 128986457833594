import { createSlice } from "@reduxjs/toolkit";
import { FetchEmployeePaymentBalance } from "../../../../actions/school/finance/payments/fetchEmployeePaymentBalanceAction";

const initialState = {
  fetchEmployeePaymentBalance: null,
  LoadFetchEmployeePaymentBalance: false,
  fetchEmployeePaymentBalanceError: null,
};

const fetchEmployeePaymentBalanceSlice = createSlice({
  name: "fetchEmployeePaymentBalance",
  initialState: initialState,
  reducers: {
    clearFetchEmployeePaymentBalance: (state) => {
      state.fetchEmployeePaymentBalance = null;
      state.fetchEmployeePaymentBalanceError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(FetchEmployeePaymentBalance.pending, (state) => {
        state.LoadFetchEmployeePaymentBalance = true;
        state.fetchEmployeePaymentBalanceError = null;
      })
      .addCase(FetchEmployeePaymentBalance.fulfilled, (state, action) => {
        state.LoadFetchEmployeePaymentBalance = false;
        state.fetchEmployeePaymentBalance = action.payload;
      })
      .addCase(FetchEmployeePaymentBalance.rejected, (state, action) => {
        state.LoadFetchEmployeePaymentBalance = false;
        state.fetchEmployeePaymentBalanceError = action.payload;
      });
  },
});

export const { clearFetchEmployeePaymentBalance } =
  fetchEmployeePaymentBalanceSlice.actions;

export default fetchEmployeePaymentBalanceSlice.reducer;
