/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import {
    LogoutOutlined,
    ProfileOutlined,
    ToolOutlined,
    UserOutlined
  } from "@ant-design/icons";
import PageContainer from "../../../components/pageContainer";
import TopNavigationBar from "../../../components/topNavigationBar";
import LeftNavigationBar from "../../../components/leftNavigationBar";
import { Menu } from "antd";
import { useLocation } from 'react-router-dom';
import NotFoundComponent from "../../Error";
import RightContainerBox from "../../../components/rightContainerBox";
import { teacherNavBarItems } from "../../../components/leftNavigationBar/leftNavBarItems";
import TDashboard from "../tDashboard";
import { Space } from 'antd';
import { Dropdown } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import authSelector from "../../../redux/selectors/authSelector";
import { clearUserLogoutData } from "../../../redux/reducers/auth/logoutUserReducer";
import { clearUserLoginData } from "../../../redux/reducers/auth/loginUserReducer";
import { useNavigate } from 'react-router-dom';
import { CurrentUserData } from "../../../redux/actions/auth/currentUserDataActions";
import { LogoutUser } from "../../../redux/actions/auth/logoutActions";
import { RetrieveSchoolConfiguration } from "../../../redux/actions/school/otherConfiguration/retrievSchoolConfigurationActions";
import otherConfigurationSelector from "../../../redux/selectors/otherConfigurationSelector";
import { Tag } from 'antd';
import TeachingLevels from "../TeachingLevels";
import StudentEvaluation from "../studentEvaluation";
import { FetchDefaultData } from "../../../redux/actions/school/otherConfiguration/fetchDefaultDataActions";

const ManageTeacherPages = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { pathname } = location;
    const splitLocation: any = pathname.split("/");
    const { userLogout, currentUserData } = useSelector(authSelector);
    const { retrieveSchoolConfiguration: {
      retrieveSchoolConfiguration,
    }} = useSelector(otherConfigurationSelector)

    const currentTerm = retrieveSchoolConfiguration?.data && 
          retrieveSchoolConfiguration?.data?.studying_terms
          .find((term) => term?.is_current_term === true)

    const currentYear = retrieveSchoolConfiguration?.data && 
          retrieveSchoolConfiguration?.data?.studying_years.find((term) => term?.is_current_year === true)


    const routeComponents = {
        "teacher-classes": TeachingLevels,
        "teacher-dashboard": TDashboard,
        "students-evaluation": StudentEvaluation
      };

      const logout = () => {
        dispatch(LogoutUser());
      };

    const items = [
        {
          icon: (
            <span className="menu-icon">
              <ProfileOutlined />
            </span>
          ),
          label: <a href="#">Profile</a>,
          key: "0",
        },
        {
          icon: (
            <span className="menu-icon">
              <ToolOutlined />
            </span>
          ),
          label: <a href="#">Settings</a>,
          key: "1",
        },
        {
          icon: (
            <span className="menu-icon">
              <LogoutOutlined />
            </span>
          ),
          label: (
            <a href="#" onClick={logout}>
              Logout
            </a>
          ),
          key: "2",
        },
      ];

    const managePages = () => {
        const routeName = splitLocation[1];
        const PageComponent = routeComponents[routeName];
    
        if (!PageComponent) {
          return <NotFoundComponent />;
        }
        return <PageComponent />;
    };


    useEffect(() => {
        if (userLogout) {
          dispatch(clearUserLoginData());
          dispatch(clearUserLogoutData());
          localStorage.removeItem("userToken");
          navigate("/school-admin");
          window.location.reload();
        }
        dispatch(CurrentUserData());
        dispatch(FetchDefaultData());
        dispatch(RetrieveSchoolConfiguration())
      }, [dispatch, navigate, userLogout]);

    return (
        <PageContainer>
            <TopNavigationBar>
               <div className="top-nav-bar__content-box">
                  <Space>
                    <div >
                        <Tag color="blue">
                          <span className="dropdown-text">Year: </span>
                          <span className="dropdown-text bold-text">
                            {currentYear?.year?.toUpperCase()}
                          </span>
                        </Tag>
                        <span className="text-grey-color-l">| </span>
                        <Tag color="blue">
                          <span className="dropdown-text">Term: </span>
                          <span className="dropdown-text bold-text">
                            {currentTerm?.term_name?.toUpperCase()}
                          </span>
                        </Tag>
                      </div>
            
                    <Space>
                        <Dropdown
                        menu={{
                            items,
                        }}
                        trigger={["click"]}
                        >
                        <a className="link-color">
                            <Space>
                                <span className="dropdown-text bold-text">
                                    {currentUserData?.data?.username?.toUpperCase()}
                                </span>
                                <UserOutlined className="custom-icon-style" />
                            </Space>
                        </a>
                        </Dropdown>
                    </Space>
                  </Space>
                </div>
            </TopNavigationBar>
            <LeftNavigationBar>
                <div className="left-nav-bar__top-content">
                    <span>DARASSA</span>
                </div>
                <div className="left-nav-bar__bottom-content">
                    <Menu
                        theme="dark"
                        defaultSelectedKeys={[splitLocation[1]]}
                        mode="inline"
                        items={teacherNavBarItems}
                        className="menu-item"
                    />
                </div>
            </LeftNavigationBar>
            <RightContainerBox>
                {managePages()}
            </RightContainerBox>
        </PageContainer>
    );
};

export default ManageTeacherPages;
