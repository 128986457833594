import { createAsyncThunk } from "@reduxjs/toolkit/dist";
import apiRequest from "../../../helpers/apiActions/apiRequest";
import endPoints from "../../../utils/Endpoint";

export const LogoutUser = createAsyncThunk(
  "logoutUser",
  async (requestData, { rejectWithValue }) => {
    const response = await apiRequest({
      url: endPoints.logout,
      method: "GET",
      requestData: null,
      rejectWithValue: rejectWithValue,
    });
    return response;
  }
);
