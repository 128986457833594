import { createSlice } from "@reduxjs/toolkit";
import { RetrieveStudentMarks } from "../../../actions/teacher/levels/retrieveStudentMarksAction";

const initialState = {
    retrieveStudentMarks: null,
    LoadRetrieveStudentMarks: false,
    retrieveStudentMarksError: null,
};

const retrieveStudentMarksSlice = createSlice({
    name: "retrieveStudentMarks",
    initialState: initialState,
    reducers: {
        clearRetrieveStudentMarks: (state) => {
            state.retrieveStudentMarks = null;
            state.retrieveStudentMarksError = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(RetrieveStudentMarks.pending, (state) => {
                state.LoadRetrieveStudentMarks = true;
                state.retrieveStudentMarksError = null;
            })
            .addCase(RetrieveStudentMarks.fulfilled, (state, action) => {
                state.LoadRetrieveStudentMarks = false;
                state.retrieveStudentMarks = action.payload;
            })
            .addCase(RetrieveStudentMarks.rejected, (state, action) => {
                state.LoadRetrieveStudentMarks = false;
                state.retrieveStudentMarksError = action.payload;
            });
    },
});

export const { clearRetrieveStudentMarks } = retrieveStudentMarksSlice.actions;

export default retrieveStudentMarksSlice.reducer;
