import { createAsyncThunk } from "@reduxjs/toolkit/dist";
import apiRequest from "../../../../helpers/apiActions/apiRequest";
import endPoints from "../../../../utils/Endpoint";

export const AddNewSubLevel = createAsyncThunk(
    "addNewSubLevel",
    async (requestData, { rejectWithValue }) => {
        const response = await apiRequest({
            url: endPoints.addNewSubLevel,
            method: "POST",
            requestData: requestData,
            rejectWithValue: rejectWithValue,
        });
        return response;
    }
);
