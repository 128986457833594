import React from "react";
import "./style.css";
import { CgCheck } from "react-icons/cg";
import CustomSeparator from "../../../components/common/customrSeparator";

const ProductPageCard = ({ mainTitle, description }) => (
    <div className="product-page-card">
        <div className="product-page-card-title flex-row grey-border-bottom margin-bottom-10">
            <CgCheck size={48} />
            <span>{mainTitle}</span>
        </div>

        <span className="product-page-card-title-description">
            {description}
        </span>
    </div>
);

const ProductPage = () => {
    return (
        <div className="product-page flex-colum">
            <div className="top-title-items flex-colum margin-bottom-20 width-100-percent">
                <span className="product-page-title">
                    Our standout features
                </span>
                <CustomSeparator size={8} />
                <span className="product-page-title-description">
                    Explore our wide range of quality products, carefully
                    curated to meet your needs
                </span>
                <CustomSeparator size={18} />
                <div className="custom-page-separator"></div>
            </div>
            <div className="card-container-all-items">
                <div className="card-container">
                    <ProductPageCard
                        mainTitle={"Fee Oversight"}
                        description={`Efficiently oversee and manage fees through streamlined
                administrative processes`}
                    />
                    <ProductPageCard
                        mainTitle={"Student Discipline"}
                        description={`Monitoring and guiding student conduct and behavior`}
                    />
                    <ProductPageCard
                        mainTitle={"Finance and Accounting"}
                        description={`Handling financial matters and accounting tasks with precision and expertise`}
                    />
                    <ProductPageCard
                        mainTitle={"School Management"}
                        description={`Effectively coordinate and administer all aspects of school operations`}
                    />

                    <ProductPageCard
                        mainTitle={"Library Management"}
                        description={`Efficiently overseeing library operations and resources for enhanced accessibility and organization`}
                    />

                    <ProductPageCard
                        mainTitle={"Communication"}
                        description={`Fostering effective communication channels to facilitate seamless interaction and collaboration`}
                    />

                    <ProductPageCard
                        mainTitle={"Sophisticated Data Analysis"}
                        description={`Utilizing cutting-edge analytics methodologies to derive deep insights and drive informed decision-making`}
                    />

                    <ProductPageCard
                        mainTitle={"Educational Endeavors"}
                        description={`Nurturing academic excellence through comprehensive educational programs and support`}
                    />
                </div>
            </div>
        </div>
    );
};

export default ProductPage;
