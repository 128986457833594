import { createSlice } from "@reduxjs/toolkit";
import { ManagePaymentMethodData } from "../../../actions/school/otherConfiguration/managePaymentMethodActions";

const initialState = {
  managePaymentMethodData: null,
  LoadManagePaymentMethodData: false,
  managePaymentMethodDataError: null,
};

const managePaymentMethodDataSlice = createSlice({
  name: "managePaymentMethodData",
  initialState: initialState,
  reducers: {
    clearManagePaymentMethodData: (state) => {
      state.managePaymentMethodData = null;
      state.managePaymentMethodDataError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(ManagePaymentMethodData.pending, (state) => {
        state.LoadManagePaymentMethodData = true;
        state.managePaymentMethodDataError = null;
      })
      .addCase(ManagePaymentMethodData.fulfilled, (state, action) => {
        state.LoadManagePaymentMethodData = false;
        state.managePaymentMethodData = action.payload;
      })
      .addCase(ManagePaymentMethodData.rejected, (state, action) => {
        state.LoadManagePaymentMethodData = false;
        state.managePaymentMethodDataError = action.payload;
      });
  },
});

export const { clearManagePaymentMethodData } =
  managePaymentMethodDataSlice.actions;

export default managePaymentMethodDataSlice.reducer;
