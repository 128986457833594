import { createSlice } from "@reduxjs/toolkit";
import { AddNewSubLevel } from "../../../actions/school/levels/addNewSubLevelActions";

const initialState = {
    addNewSubLevel: null,
    loadingAddNewSubLevel: false,
    addNewSubLevelError: null,
};

const addNewSubLevelSlice = createSlice({
    name: "addNewSubLevel",
    initialState: initialState,
    reducers: {
        clearAddNewSubLevel: (state) => {
            state.addNewSubLevel = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(AddNewSubLevel.pending, (state) => {
                state.loadingAddNewSubLevel = true;
                state.addNewSubLevelError = null;
            })
            .addCase(AddNewSubLevel.fulfilled, (state, action) => {
                state.loadingAddNewSubLevel = false;
                state.addNewSubLevel = action.payload;
            })
            .addCase(AddNewSubLevel.rejected, (state, action) => {
                state.loadingAddNewSubLevel = false;
                state.addNewSubLevelError = action.payload;
            });
    },
});

export const { clearAddNewSubLevel } = addNewSubLevelSlice.actions;

export default addNewSubLevelSlice.reducer;
