import { createSlice } from "@reduxjs/toolkit";
import { FetchStudentPaymentDetails } from "../../../../actions/school/finance/payments/fetchStudentPaymentDetailsAction";

const initialState = {
  fetchStudentPaymentDetails: null,
  LoadFetchStudentPaymentDetails: false,
  fetchStudentPaymentDetailsError: null,
};

const fetchStudentPaymentDetailsSlice = createSlice({
  name: "fetchStudentPaymentDetails",
  initialState: initialState,
  reducers: {
    clearFetchStudentPaymentDetails: (state) => {
      state.fetchStudentPaymentDetails = null;
      state.fetchStudentPaymentDetailsError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(FetchStudentPaymentDetails.pending, (state) => {
        state.LoadFetchStudentPaymentDetails = true;
        state.fetchStudentPaymentDetailsError = null;
      })
      .addCase(FetchStudentPaymentDetails.fulfilled, (state, action) => {
        state.LoadFetchStudentPaymentDetails = false;
        state.fetchStudentPaymentDetails = action.payload;
      })
      .addCase(FetchStudentPaymentDetails.rejected, (state, action) => {
        state.LoadFetchStudentPaymentDetails = false;
        state.fetchStudentPaymentDetailsError = action.payload;
      });
  },
});

export const { clearFetchStudentPaymentDetails } =
  fetchStudentPaymentDetailsSlice.actions;

export default fetchStudentPaymentDetailsSlice.reducer;
