import { Result } from "antd";
import React from "react";
import "./index.css";

const NotFoundComponent = () => {
  return (
    <div className="error-page">
      <Result
        status="404"
        title="404"
        style={{ marginTop: "10%" }}
        subTitle="Sorry, the page you visited does not exist."
      />
    </div>
  );
};

export default NotFoundComponent;
