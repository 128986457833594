import { createSlice } from "@reduxjs/toolkit";
import { BorrowBooks } from "../../../actions/school/library/borrowBooksAction";

const initialState = {
    borrowBooks: null,
    loadingBorrowBooks: false,
    borrowBooksError: null,
};

const borrowBooksSlice = createSlice({
    name: "borrowBooks",
    initialState: initialState,
    reducers: {
        clearBorrowBooks: (state) => {
            state.borrowBooks = null;
            state.borrowBooksError = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(BorrowBooks.pending, (state) => {
                state.loadingBorrowBooks = true;
                state.borrowBooksError = null;
            })
            .addCase(BorrowBooks.fulfilled, (state, action) => {
                state.loadingBorrowBooks = false;
                state.borrowBooks = action.payload;
            })
            .addCase(BorrowBooks.rejected, (state, action) => {
                state.loadingBorrowBooks = false;
                state.borrowBooksError = action.payload;
            });
    },
});

export const { clearBorrowBooks } = borrowBooksSlice.actions;

export default borrowBooksSlice.reducer;
