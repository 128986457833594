import { createSlice } from "@reduxjs/toolkit";
import { FetchClassOption } from "../../../actions/school/levels/fetchClassOptionActions";

const initialState = {
  fetchClassOption: null,
  LoadFetchClassOption: false,
  fetchClassOptionError: null,
};

const fetchClassOptionSlice = createSlice({
  name: "fetchClassOption",
  initialState: initialState,
  reducers: {
    clearFetchClassOption: (state) => {
      state.fetchClassOption = null;
      state.fetchClassOptionError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(FetchClassOption.pending, (state) => {
        state.LoadFetchClassOption = true;
        state.fetchClassOptionError = null;
      })
      .addCase(FetchClassOption.fulfilled, (state, action) => {
        state.LoadFetchClassOption = false;
        state.fetchClassOption = action.payload;
      })
      .addCase(FetchClassOption.rejected, (state, action) => {
        state.LoadFetchClassOption = false;
        state.fetchClassOptionError = action.payload;
      });
  },
});

export const { clearFetchClassOption } = fetchClassOptionSlice.actions;

export default fetchClassOptionSlice.reducer;
