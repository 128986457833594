import { createSlice } from "@reduxjs/toolkit";
import { RetrieveAssessmentFile } from "../../../actions/teacher/levels/retrieveAssessmentFileAction";

const initialState = {
    retrieveAssessmentFile: null,
    LoadRetrieveAssessmentFile: false,
    retrieveAssessmentFileError: null,
};

const retrieveAssessmentFileSlice = createSlice({
    name: "retrieveAssessmentFile",
    initialState: initialState,
    reducers: {
        clearRetrieveAssessmentFile: (state) => {
            state.retrieveAssessmentFile = null;
            state.retrieveAssessmentFileError = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(RetrieveAssessmentFile.pending, (state) => {
                state.LoadRetrieveAssessmentFile = true;
                state.retrieveAssessmentFileError = null;
            })
            .addCase(RetrieveAssessmentFile.fulfilled, (state, action) => {
                state.LoadRetrieveAssessmentFile = false;
                state.retrieveAssessmentFile = action.payload;
            })
            .addCase(RetrieveAssessmentFile.rejected, (state, action) => {
                state.LoadRetrieveAssessmentFile = false;
                state.retrieveAssessmentFileError = action.payload;
            });
    },
});

export const { clearRetrieveAssessmentFile } =
    retrieveAssessmentFileSlice.actions;

export default retrieveAssessmentFileSlice.reducer;
