import { createAsyncThunk } from "@reduxjs/toolkit/dist";
import apiRequest from "../../../../../helpers/apiActions/apiRequest";
import endPoints from "../../../../../utils/Endpoint";

export const AddFeeType = createAsyncThunk(
  "addFeeType",
  async (requestData, { rejectWithValue }) => {
    const response = await apiRequest({
      url: endPoints.addFeeType,
      method: "POST",
      requestData: requestData,
      rejectWithValue: rejectWithValue,
    });
    return response;
  }
);
