/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Button, Table, Drawer, Space, Tag, Input } from "antd";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { UndoOutlined } from "@ant-design/icons";
import teacherSelector from "../../../../redux/selectors/teacherSelector";
import { useDispatch, useSelector } from "react-redux";
import Toast from "../../../../components/common/toast";
import { RecordStudentScores } from "../../../../redux/actions/teacher/levels/recordStudentScoresAction";
import { clearRecordStudentScores } from "../../../../redux/reducers/teacher/levels/recordStudentScoresReducer";
import moment from "moment";

const AssessmentFileDetails = ({
    api,
    open,
    onClose,
    fileId,
    setOpenDrawer,
}) => {
    const dispatch = useDispatch();
    const [editable, setEditable] = useState(false);
    const [dataSource, setDataSource] = useState();

    const {
        recordStudentScores: {
            recordStudentScores,
            LoadRecordStudentScores,
            recordStudentScoresError,
        },
    } = useSelector(teacherSelector);

    const tableColumns = [
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
        },
        {
            title: "Student",
            dataIndex: "student",
            key: "student",
        },
        {
            title: "Score",
            dataIndex: "score",
            key: "score",
            editable: editable,
            width: "100px",
            render: (text, record) =>
                editable ? (
                    <Input
                        type="number"
                        value={text}
                        onChange={(e) =>
                            handleInputChange(e, record.key, "score")
                        }
                    />
                ) : (
                    text
                ),
        },
        {
            title: "Total Marks",
            dataIndex: "total_marks",
            key: "total_marks",
        },
        {
            title: "status",
            dataIndex: "attended",
            key: "attended",
            render: (tags) => (
                <span>
                    {tags === true && (
                        <Tag color={"green"} key={tags}>
                            {"Attended".toUpperCase()}
                        </Tag>
                    )}
                    {tags === false && (
                        <Tag color={"geekblue"} key={tags}>
                            {"Pending".toUpperCase()}
                        </Tag>
                    )}
                </span>
            ),
        },
        {
            title: "Actions",
            dataIndex: "undoButton",
            key: "undoButton",
            render: (_, record) => (
                <a onClick={() => handleUndo(record.key)} disabled={!editable}>
                    <div className="flex-row" style={{ color: "orange" }}>
                        <UndoOutlined style={{ marginRight: "5px" }} />
                        Undo
                    </div>
                </a>
            ),
        },
    ];

    const handleInputChange = (e, key, dataIndex) => {
        setDataSource((prevDataSource) => {
            const newData = [...prevDataSource];
            const index = newData.findIndex((item) => key === item.key);
            if (index > -1) {
                const item = newData[index];
                if (item["total_marks"] < e.target.value) {
                    Toast(
                        api,
                        "Info",
                        "The record you entered is greater than the total marks"
                    );
                }
                if (item["total_marks"] >= e.target.value) {
                    item[dataIndex] =
                        e.target.value !== "" ? parseFloat(e.target.value) : "";
                }
            }
            return newData;
        });
    };

    const handleUndo = (key) => {
        setDataSource((prevDataSource) => {
            const newData = [...prevDataSource];
            const index = newData.findIndex((item) => key === item.key);
            if (index > -1) {
                const item = newData[index];
                item["score"] = item["originalMarks"];
            }
            return newData;
        });
    };

    const fileInfo =
        recordStudentScores?.data && recordStudentScores?.data?.file_info;

    const formatFileInfo = `${fileInfo?.file_name || "None name provide"}`;

    const tableData =
        recordStudentScores?.data?.students_assessment &&
        recordStudentScores?.data?.students_assessment.map((item, index) => ({
            key: index,
            date: moment(item?.created_at).format("YYYY-MM-DD HH:MM"),
            attended: item?.attended,
            recordId: item?.id,
            student: `${item?.first_name} ${item?.last_name}`,
            student_code: item?.student_code,
            score:
                item?.student_scores === null ||
                item?.student_scores === undefined
                    ? "-"
                    : item?.student_scores,
            total_marks: item?.total_marks,
            studentId: item?.student_id,
            originalMarks:
                item?.student_scores === null ||
                item?.student_scores === undefined
                    ? "-"
                    : item?.student_scores,
        }));

    const handleToggleEditable = () => {
        setEditable(!editable);
        setDataSource(tableData);
    };

    const handleSave = () => {
        const formData = {
            record_scores: true,
            file_id: fileId,
            students_marks:
                dataSource &&
                dataSource.map((item) => ({
                    id: item.recordId,
                    student_scores: item?.score === "-" ? null : item?.score,
                    student_id: item?.studentId,
                })),
            retrieve_file: false,
        };
        dispatch(RecordStudentScores(formData));
    };

    useEffect(() => {
        if (recordStudentScoresError?.error?.retrieve_file_failed) {
            Toast(api, "Error", recordStudentScoresError.message);
            dispatch(clearRecordStudentScores());
        }

        if (recordStudentScores?.data?.record_scores) {
            Toast(api, "Success", recordStudentScores.message);
            dispatch(
                RecordStudentScores({
                    record_scores: false,
                    file_id: fileId,
                    students_marks: [],
                    retrieve_file: true,
                })
            );
            setEditable(false);
            setDataSource();
        }

        if (recordStudentScores?.data?.record_scores === false) {
            setOpenDrawer(true);
        }
    }, [
        api,
        dispatch,
        fileId,
        recordStudentScores?.data?.record_scores,
        recordStudentScores,
        recordStudentScoresError,
        recordStudentScoresError?.error?.retrieve_file_failed,
        setOpenDrawer,
    ]);

    useEffect(() => {
        if (fileId) {
            dispatch(
                RecordStudentScores({
                    record_scores: false,
                    file_id: fileId,
                    students_marks: [],
                    retrieve_file: true,
                })
            );
        }
    }, [dispatch, fileId]);

    return (
        <Drawer
            width={800}
            title={formatFileInfo || ""}
            placement="right"
            open={open}
            closable={false}
            destroyOnClose={false}
            closeIcon
            extra={
                <Space className="flex-row-sb">
                    <Space>
                        <Button
                            ghost
                            type="primary"
                            onClick={handleToggleEditable}
                        >
                            {editable
                                ? "You are recording marks"
                                : "Start recording marks"}
                        </Button>
                        <Button
                            type="primary"
                            onClick={handleSave}
                            disabled={!editable}
                        >
                            Save Change
                        </Button>
                    </Space>
                    <Button
                        danger
                        shape="circle"
                        type="text"
                        onClick={() => {
                            onClose();
                            setEditable(false);
                            setDataSource();
                        }}
                    >
                        <AiOutlineCloseCircle size={25} />
                    </Button>
                </Space>
            }
        >
            <Table
                loading={LoadRecordStudentScores}
                columns={tableColumns}
                dataSource={dataSource || tableData}
                pagination={false}
            />
        </Drawer>
    );
};

export default AssessmentFileDetails;
