import React from "react";

const TopNavigationBar = ({ children }) => {
  return (
    <div className="top-nav-bar-container">
      <div className="top-nav-bar-container__box">{children}</div>
    </div>
  );
};

export default TopNavigationBar;
