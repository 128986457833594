import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AuthMiddleware from "./middleware/authMiddleware";
import routes from "./pages/routes";
import NotFoundComponent from "./pages/Error";
import { ConfigProvider } from "antd";

function App() {
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: "#212529",
                },
            }}
        >
            <div className="App">
                <BrowserRouter>
                    <Routes>
                        {routes.map((route, index) => {
                            return route.isProtected ? (
                                <Route
                                    key={index}
                                    element={
                                        <AuthMiddleware
                                            isAuthProtected={route.isProtected}
                                        />
                                    }
                                >
                                    <Route
                                        path={route.path}
                                        key={route.key}
                                        index={route.index}
                                        element={route.element}
                                    />
                                </Route>
                            ) : (
                                <Route
                                    path={route.path}
                                    key={route.key}
                                    index={route.index || true}
                                    element={route.element}
                                />
                            );
                        })}
                        <Route path="*" element={<NotFoundComponent />} />
                    </Routes>
                </BrowserRouter>
            </div>
        </ConfigProvider>
    );
}

export default App;
