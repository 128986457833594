import { createSelector } from "reselect";

const retrieveTeacherSummaryInformation = (state) =>
    state.retrieveTeacherSummaryInformation;

const retrieveTeachingLevels = (state) => state.retrieveTeachingLevels;
const retrieveStudentSubjectMarks = (state) =>
    state.retrieveStudentSubjectMarks;
const retrieveAssessmentFile = (state) => state.retrieveAssessmentFile;
const generateAssessmentFile = (state) => state.generateAssessmentFile;
const recordStudentScores = (state) => state.recordStudentScores;
const retrieveStudentMarksDetails = (state) =>
    state.retrieveStudentMarksDetails;
const createFormativeAssessmentFile = (state) =>
    state.createFormativeAssessmentFile;
const retrieveAllStudentsMarks = (state) => state.retrieveAllStudentsMarks;
const retrieveStudentMarks = (state) => state.retrieveStudentMarks;
const generateFinaleStudentMarks = (state) => state.generateFinaleStudentMarks;

const teacherSelector = createSelector(
    [
        retrieveTeacherSummaryInformation,
        retrieveTeachingLevels,
        retrieveStudentSubjectMarks,
        retrieveAssessmentFile,
        generateAssessmentFile,
        recordStudentScores,
        retrieveStudentMarksDetails,
        createFormativeAssessmentFile,
        retrieveAllStudentsMarks,
        retrieveStudentMarks,
        generateFinaleStudentMarks,
    ],
    (
        retrieveTeacherSummaryInformation,
        retrieveTeachingLevels,
        retrieveStudentSubjectMarks,
        retrieveAssessmentFile,
        generateAssessmentFile,
        recordStudentScores,
        retrieveStudentMarksDetails,
        createFormativeAssessmentFile,
        retrieveAllStudentsMarks,
        retrieveStudentMarks,
        generateFinaleStudentMarks
    ) => ({
        retrieveTeacherSummaryInformation,
        retrieveTeachingLevels,
        retrieveStudentSubjectMarks,
        retrieveAssessmentFile,
        generateAssessmentFile,
        recordStudentScores,
        retrieveStudentMarksDetails,
        createFormativeAssessmentFile,
        retrieveAllStudentsMarks,
        retrieveStudentMarks,
        generateFinaleStudentMarks,
    })
);

export default teacherSelector;
