import { createSlice } from "@reduxjs/toolkit";
import { FetchClassLevelsData } from "../../../actions/school/levels/fetchClassLevelsActions";

const initialState = {
  fetchClassLevelsData: null,
  loading: false,
  error: null,
};

const fetchClassLevelsDataSlice = createSlice({
  name: "fetchClassLevelsData",
  initialState: initialState,
  reducers: {
    clearFetchClassLevelsData: (state) => {
      state.fetchClassLevelsData = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(FetchClassLevelsData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(FetchClassLevelsData.fulfilled, (state, action) => {
        state.loading = false;
        state.fetchClassLevelsData = action.payload;
      })
      .addCase(FetchClassLevelsData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearFetchClassLevelsDataData } =
  fetchClassLevelsDataSlice.actions;

export default fetchClassLevelsDataSlice.reducer;
