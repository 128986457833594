import { createSlice } from "@reduxjs/toolkit";
import { SaveEmployeePayment } from "../../../../actions/school/finance/payments/saveEmployeePaymentActions";

const initialState = {
  saveEmployeePayment: null,
  LoadSaveEmployeePayment: false,
  saveEmployeePaymentError: null,
};

const saveEmployeePaymentSlice = createSlice({
  name: "saveEmployeePayment",
  initialState: initialState,
  reducers: {
    clearSaveEmployeePayment: (state) => {
      state.saveEmployeePayment = null;
      state.saveEmployeePaymentError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(SaveEmployeePayment.pending, (state) => {
        state.LoadSaveEmployeePayment = true;
        state.saveEmployeePaymentError = null;
      })
      .addCase(SaveEmployeePayment.fulfilled, (state, action) => {
        state.LoadSaveEmployeePayment = false;
        state.saveEmployeePayment = action.payload;
      })
      .addCase(SaveEmployeePayment.rejected, (state, action) => {
        state.LoadSaveEmployeePayment = false;
        state.saveEmployeePaymentError = action.payload;
      });
  },
});

export const { clearSaveEmployeePayment } = saveEmployeePaymentSlice.actions;

export default saveEmployeePaymentSlice.reducer;
