import { createSlice } from "@reduxjs/toolkit";
import { RetrieveFacilityCourses } from "../../../actions/school/teachers/retrieveFacilityCourseActions";

const initialState = {
    retrieveFacilityCourse: null,
    LoadRetrieveFacilityCourse: false,
    retrieveFacilityCourseError: null,
};

const retrieveFacilityCourseSlice = createSlice({
    name: "retrieveFacilityCourse",
    initialState: initialState,
    reducers: {
        clearRetrieveFacilityCourse: (state) => {
            state.retrieveFacilityCourse = null;
            state.retrieveFacilityCourseError = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(RetrieveFacilityCourses.pending, (state) => {
                state.LoadRetrieveFacilityCourse = true;
                state.retrieveFacilityCourseError = null;
            })
            .addCase(RetrieveFacilityCourses.fulfilled, (state, action) => {
                state.LoadRetrieveFacilityCourse = false;
                state.retrieveFacilityCourse = action.payload;
            })
            .addCase(RetrieveFacilityCourses.rejected, (state, action) => {
                state.LoadRetrieveFacilityCourse = false;
                state.retrieveFacilityCourseError = action.payload;
            });
    },
});

export const { clearRetrieveFacilityCourse } =
    retrieveFacilityCourseSlice.actions;

export default retrieveFacilityCourseSlice.reducer;
