import React, { useEffect, useState } from "react";
import { Modal, Divider, Switch, Button } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Toast from "../../../../components/common/toast";
import { useDispatch } from "react-redux";
import { clearCreateFormativeAssessmentFile } from "../../../../redux/reducers/teacher/levels/createFormativeAssessmentFileReducer";
import { CreateFormativeAssessmentFile } from "../../../../redux/actions/teacher/levels/creatFormativeAssessmentFileAction";

const CloseFolderModal = ({
    api,
    onClose,
    open,
    fileId,
    loading,
    createFormativeAssessmentFile,
    createFormativeAssessmentFileError,
}) => {
    const dispatch = useDispatch();
    const [checked, setChecked] = useState(true);

    const onHandleSubmit = () => {
        const formData = {
            file_name: "",
            class_id: "",
            total_marks: "",
            assessment_file_id: fileId,
            assessment_type: "",
            close_file: checked,
        };
        dispatch(CreateFormativeAssessmentFile(formData));
    };

    useEffect(() => {
        if (createFormativeAssessmentFileError) {
            onClose();
            dispatch(clearCreateFormativeAssessmentFile());
        }

        if (createFormativeAssessmentFile?.data?.close_file) {
            dispatch(clearCreateFormativeAssessmentFile());
            onClose();
        }
    }, [
        api,
        dispatch,
        createFormativeAssessmentFile,
        createFormativeAssessmentFileError,
        onClose,
    ]);

    return (
        <Modal
            onCancel={onClose}
            title={`Close assessment file`}
            open={open}
            closeIcon={true}
            footer={[]}
        >
            <Divider />

            <div className="flex-row padding-bottom-10 margin-top-20">
                <div style={{ alignSelf: "center", color: "#707070" }}>
                    You are about to close these assessment files. Ensure that
                    all assessments students need to pass have been completed
                </div>
                <Switch
                    checked={checked}
                    onClick={(data) => setChecked(data)}
                    className="custom-switch"
                    style={{ marginLeft: "10px" }}
                />
            </div>
            <Divider />
            <Button
                type="primary"
                size="large"
                onClick={onHandleSubmit}
                className="margin-top-25 width-100-percent"
            >
                {loading && (
                    <LoadingOutlined
                        size={30}
                        style={{
                            alignSelf: "center",
                        }}
                    />
                )}
                {!loading && (
                    <>
                        <span style={{ marginLeft: "10px" }}>Close file</span>
                    </>
                )}
            </Button>
        </Modal>
    );
};

export default CloseFolderModal;
