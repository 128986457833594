import { createSlice } from "@reduxjs/toolkit";
import { RetrieveTeachingLevels } from "../../../actions/teacher/levels/retrieveTeachingLevelsAction";

const initialState = {
    retrieveTeachingLevels: null,
    LoadRetrieveTeachingLevels: false,
    retrieveTeachingLevelsError: null,
};

const retrieveTeachingLevelsSlice = createSlice({
    name: "retrieveTeachingLevels",
    initialState: initialState,
    reducers: {
        clearRetrieveTeachingLevels: (state) => {
            state.retrieveTeachingLevels = null;
            state.retrieveTeachingLevelsError = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(RetrieveTeachingLevels.pending, (state) => {
                state.LoadRetrieveTeachingLevels = true;
                state.retrieveTeachingLevelsError = null;
            })
            .addCase(RetrieveTeachingLevels.fulfilled, (state, action) => {
                state.LoadRetrieveTeachingLevels = false;
                state.retrieveTeachingLevels = action.payload;
            })
            .addCase(RetrieveTeachingLevels.rejected, (state, action) => {
                state.LoadRetrieveTeachingLevels = false;
                state.retrieveTeachingLevelsError = action.payload;
            });
    },
});

export const { clearRetrieveTeachingLevels } =
    retrieveTeachingLevelsSlice.actions;

export default retrieveTeachingLevelsSlice.reducer;
