import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Outlet, Navigate, useNavigate } from "react-router-dom";

const AuthMiddleware = ({ isAuthProtected }) => {
    const navigate = useNavigate();

    useEffect(() => {
        const userToken = localStorage.getItem("userToken");
        if (!userToken) {
            navigate("/school-admin");
        }
    }, [navigate]);

    return isAuthProtected === true ? <Outlet /> : <Navigate to={"/login"} />;
};

AuthMiddleware.prototypes = {
    isAuthProtected: PropTypes.bool,
};

export default AuthMiddleware;
