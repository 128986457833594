import { createSlice } from "@reduxjs/toolkit";
import { UpdateCourse } from "../../../actions/school/teachers/updateCourseActions";

const initialState = {
    updateCourse: null,
    LoadUpdateCourse: false,
    updateCourseError: null,
};

const updateCourseSlice = createSlice({
    name: "updateCourse",
    initialState: initialState,
    reducers: {
        clearUpdateCourse: (state) => {
            state.updateCourse = null;
            state.updateCourseError = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(UpdateCourse.pending, (state) => {
                state.LoadUpdateCourse = true;
                state.updateCourseError = null;
            })
            .addCase(UpdateCourse.fulfilled, (state, action) => {
                state.LoadUpdateCourse = false;
                state.updateCourse = action.payload;
            })
            .addCase(UpdateCourse.rejected, (state, action) => {
                state.LoadUpdateCourse = false;
                state.updateCourseError = action.payload;
            });
    },
});

export const { clearUpdateCourse } = updateCourseSlice.actions;

export default updateCourseSlice.reducer;
