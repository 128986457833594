import { createSlice } from "@reduxjs/toolkit";
import { RetrieveTeacherSummaryInformation } from "../../../actions/teacher/summary/retrieveTeacherSummaryInformationActions";

const initialState = {
    retrieveTeacherSummaryInformation: null,
    LoadRetrieveTeacherSummaryInformation: false,
    retrieveTeacherSummaryInformationError: null,
};

const retrieveTeacherSummaryInformationSlice = createSlice({
    name: "retrieveTeacherSummaryInformation",
    initialState: initialState,
    reducers: {
        clearRetrieveTeacherSummaryInformation: (state) => {
            state.retrieveTeacherSummaryInformation = null;
            state.retrieveTeacherSummaryInformationError = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(RetrieveTeacherSummaryInformation.pending, (state) => {
                state.LoadRetrieveTeacherSummaryInformation = true;
                state.retrieveTeacherSummaryInformationError = null;
            })
            .addCase(
                RetrieveTeacherSummaryInformation.fulfilled,
                (state, action) => {
                    state.LoadRetrieveTeacherSummaryInformation = false;
                    state.retrieveTeacherSummaryInformation = action.payload;
                }
            )
            .addCase(
                RetrieveTeacherSummaryInformation.rejected,
                (state, action) => {
                    state.LoadRetrieveTeacherSummaryInformation = false;
                    state.retrieveTeacherSummaryInformationError =
                        action.payload;
                }
            );
    },
});

export const { clearRetrieveTeacherSummaryInformation } =
    retrieveTeacherSummaryInformationSlice.actions;

export default retrieveTeacherSummaryInformationSlice.reducer;
