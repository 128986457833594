import { Select } from "antd";
import React from "react";

const systemOrganization = [
    {
        value: "isPublic",
        label: "Public",
    },
    {
        value: "isPrivate",
        label: "Private",
    },
];

const SchoolSystem = () => {
    return (
        <Select
            showSearch
            style={{ width: "45%" }}
            size="large"
            placeholder="Select your system organization"
            optionFilterProp="children"
            filterOption={(input, option) =>
                (option?.label ?? "").includes(input)
            }
            filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={systemOrganization}
        />
    );
};

export default SchoolSystem;
