import { createSlice } from "@reduxjs/toolkit";
import { AddSchedule } from "../../../actions/school/teachers/addScheduleActions";

const initialState = {
    addSchedule: null,
    LoadAddSchedule: false,
    addScheduleError: null,
};

const addScheduleSlice = createSlice({
    name: "addSchedule",
    initialState: initialState,
    reducers: {
        clearAddSchedule: (state) => {
            state.addSchedule = null;
            state.addScheduleError = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(AddSchedule.pending, (state) => {
                state.LoadAddSchedule = true;
                state.addScheduleError = null;
            })
            .addCase(AddSchedule.fulfilled, (state, action) => {
                state.LoadAddSchedule = false;
                state.addSchedule = action.payload;
            })
            .addCase(AddSchedule.rejected, (state, action) => {
                state.LoadAddSchedule = false;
                state.addScheduleError = action.payload;
            });
    },
});

export const { clearAddSchedule } = addScheduleSlice.actions;

export default addScheduleSlice.reducer;
