import { createSelector } from "reselect";

const fetchDefaultData = (state) => state.fetchDefaultData;
const manageCurrenciesData = (state) => state.manageCurrenciesData;
const fetchDefaultCurrenciesData = (state) => state.fetchDefaultCurrenciesData;
const fetchPaymentMethodData = (state) => state.fetchPaymentMethodData;
const managePaymentMethodData = (state) => state.managePaymentMethodData;
const retrieveCourse = (state) => state.retrieveCourse;
const retrieveFacilityCourse = (state) => state.retrieveFacilityCourse;
const recordNewCourse = (state) => state.recordNewCourse;
const updateCourse = (state) => state.updateCourse;
const retrieveFacilityTeachers = (state) => state.retrieveFacilityTeachers;
const addTeacher = (state) => state.addTeacher;
const filterTeacherCourse = (state) => state.filterTeacherCourse;
const retrieveStudentClasses = (state) => state.retrieveStudentClasses;
const fetchGlobalSummary = (state) => state.fetchGlobalSummary;
const addSchedule = (state) => state.addSchedule;
const retrieveSchoolTeacherSchedule = (state) =>
    state.retrieveSchoolTeacherSchedule;
const retrieveSummaryOnTeachers = (state) => state.retrieveSummaryOnTeachers;
const retrieveClassTimeManagement = (state) =>
    state.retrieveClassTimeManagement;
const setClassTimeManagement = (state) => state.setClassTimeManagement;
const retrieveSchoolConfiguration = (state) =>
    state.retrieveSchoolConfiguration;
const schoolConfiguration = (state) => state.schoolConfiguration;
const fetchEductionSystem = (state) => state.fetchEductionSystem;

const otherConfigurationSelector = createSelector(
    [
        fetchDefaultData,
        manageCurrenciesData,
        fetchDefaultCurrenciesData,
        fetchPaymentMethodData,
        managePaymentMethodData,
        retrieveCourse,
        retrieveFacilityCourse,
        recordNewCourse,
        updateCourse,
        retrieveFacilityTeachers,
        addTeacher,
        filterTeacherCourse,
        retrieveStudentClasses,
        fetchGlobalSummary,
        addSchedule,
        retrieveSchoolTeacherSchedule,
        retrieveSummaryOnTeachers,
        retrieveClassTimeManagement,
        setClassTimeManagement,
        retrieveSchoolConfiguration,
        schoolConfiguration,
        fetchEductionSystem,
    ],
    (
        fetchDefaultData,
        manageCurrenciesData,
        fetchDefaultCurrenciesData,
        fetchPaymentMethodData,
        managePaymentMethodData,
        retrieveCourse,
        retrieveFacilityCourse,
        recordNewCourse,
        updateCourse,
        retrieveFacilityTeachers,
        addTeacher,
        filterTeacherCourse,
        retrieveStudentClasses,
        fetchGlobalSummary,
        addSchedule,
        retrieveSchoolTeacherSchedule,
        retrieveSummaryOnTeachers,
        retrieveClassTimeManagement,
        setClassTimeManagement,
        retrieveSchoolConfiguration,
        schoolConfiguration,
        fetchEductionSystem
    ) => ({
        fetchDefaultData,
        manageCurrenciesData,
        fetchDefaultCurrenciesData,
        fetchPaymentMethodData,
        managePaymentMethodData,
        retrieveCourse,
        retrieveFacilityCourse,
        recordNewCourse,
        updateCourse,
        retrieveFacilityTeachers,
        addTeacher,
        filterTeacherCourse,
        retrieveStudentClasses,
        fetchGlobalSummary,
        addSchedule,
        retrieveSchoolTeacherSchedule,
        retrieveSummaryOnTeachers,
        retrieveClassTimeManagement,
        setClassTimeManagement,
        retrieveSchoolConfiguration,
        schoolConfiguration,
        fetchEductionSystem,
    })
);

export default otherConfigurationSelector;
