import { createSlice } from "@reduxjs/toolkit";
import { RecordStudentPayment } from "../../../../actions/school/finance/payments/recordStudentPaymentAction";

const initialState = {
  recordStudentPayment: null,
  LoadRecordStudentPayment: false,
  recordStudentPaymentError: null,
};

const recordStudentPaymentSlice = createSlice({
  name: "recordStudentPayment",
  initialState: initialState,
  reducers: {
    clearRecordStudentPayment: (state) => {
      state.recordStudentPayment = null;
      state.recordStudentPaymentError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(RecordStudentPayment.pending, (state) => {
        state.LoadRecordStudentPayment = true;
        state.recordStudentPaymentError = null;
      })
      .addCase(RecordStudentPayment.fulfilled, (state, action) => {
        state.LoadRecordStudentPayment = false;
        state.recordStudentPayment = action.payload;
      })
      .addCase(RecordStudentPayment.rejected, (state, action) => {
        state.LoadRecordStudentPayment = false;
        state.recordStudentPaymentError = action.payload;
      });
  },
});

export const { clearRecordStudentPayment } = recordStudentPaymentSlice.actions;

export default recordStudentPaymentSlice.reducer;
