import { createAsyncThunk } from "@reduxjs/toolkit/dist";
import apiRequest from "../../../../helpers/apiActions/apiRequest";
import endPoints from "../../../../utils/Endpoint";

export const ManagePaymentMethodData = createAsyncThunk(
  "managePaymentMethodData",
  async (requestData, { rejectWithValue }) => {
    const response = await apiRequest({
      url: endPoints.managePaymentMethod,
      method: "POST",
      requestData: requestData,
      rejectWithValue: rejectWithValue,
    });
    return response;
  }
);
