import React from "react";
import PubPageTopBar from "../pubPagetopBar";
import { WelcomePage } from "../welcomePage";
import ProductPage from "../productPage";
import PageFooter from "../footer";
import "./style.css";
import useLanguage from "../../../hooks/useLanguage";

const StartingPage = () => {
    const { translate } = useLanguage();
    return (
        <div className="flex-colum width-100-percent">
            <PubPageTopBar />
            <div className="button-bub-page">
                <WelcomePage />
                <div className="welcome-page-registration-words">
                    <div className="welcome-page-registration-words__items">
                        <span className="title">
                            {translate("Ready to experience MY DARASSA?")}
                        </span>
                        <span className="sub-title">
                            {translate("Register your school with a simple")}
                        </span>
                        <a href="/#" className="click-me-btn">
                            {translate("click!")}
                        </a>
                    </div>
                </div>
                <ProductPage />
                <PageFooter />
            </div>
        </div>
    );
};

export default StartingPage;
