import { createSlice } from "@reduxjs/toolkit";
import { RetrieveFacilitySubLevels } from "../../../actions/school/levels/retrieveFacilitySubLevelsActions";

const initialState = {
    retrieveFacilitySubLevels: null,
    loadingRetrieveSubLevel: false,
    retrieveSubLevelError: null,
};

const retrieveFacilitySubLevelsSlice = createSlice({
    name: "retrieveFacilitySubLevels",
    initialState: initialState,
    reducers: {
        clearRetrieveFacilitySubLevels: (state) => {
            state.retrieveFacilitySubLevels = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(RetrieveFacilitySubLevels.pending, (state) => {
                state.loadingRetrieveSubLevel = true;
                state.retrieveSubLevelError = null;
            })
            .addCase(RetrieveFacilitySubLevels.fulfilled, (state, action) => {
                state.loadingRetrieveSubLevel = false;
                state.retrieveFacilitySubLevels = action.payload;
            })
            .addCase(RetrieveFacilitySubLevels.rejected, (state, action) => {
                state.loadingRetrieveSubLevel = false;
                state.retrieveSubLevelError = action.payload;
            });
    },
});

export const { clearRetrieveFacilitySubLevels } =
    retrieveFacilitySubLevelsSlice.actions;

export default retrieveFacilitySubLevelsSlice.reducer;
