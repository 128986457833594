import { createSlice } from "@reduxjs/toolkit";
import { AddFeeType } from "../../../../actions/school/finance/manageFees/addFeeTypeAction";

const initialState = {
  addFeeType: null,
  LoadAddFeeType: false,
  addFeeTypeError: null,
};

const addFeeTypeSlice = createSlice({
  name: "addFeeType",
  initialState: initialState,
  reducers: {
    clearAddFeeType: (state) => {
      state.addFeeType = null;
      state.addFeeTypeError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(AddFeeType.pending, (state) => {
        state.LoadAddFeeType = true;
        state.addFeeTypeError = null;
      })
      .addCase(AddFeeType.fulfilled, (state, action) => {
        state.LoadAddFeeType = false;
        state.addFeeType = action.payload;
      })
      .addCase(AddFeeType.rejected, (state, action) => {
        state.LoadAddFeeType = false;
        state.addFeeTypeError = action.payload;
      });
  },
});

export const { clearAddFeeType } = addFeeTypeSlice.actions;

export default addFeeTypeSlice.reducer;
