export const languageDropdownItems = [
    {
        label: "Français",
        key: "fr",
    },
    {
        label: "English",
        key: "en",
    },
];

export const defaultLang = "en";
