import { createSlice } from "@reduxjs/toolkit";
import { UpdateEmployeeProfile } from "../../../actions/school/employee/updateEmployeeProfileActions";

const initialState = {
  updateEmployeeProfile: null,
  LoadUpdateEmployeeProfile: false,
  updateEmployeeProfileError: null,
};

const updateEmployeeProfileSlice = createSlice({
  name: "updateEmployeeProfile",
  initialState: initialState,
  reducers: {
    clearUpdateEmployeeProfile: (state) => {
      state.updateEmployeeProfile = null;
      state.updateEmployeeProfileError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(UpdateEmployeeProfile.pending, (state) => {
        state.LoadUpdateEmployeeProfile = true;
        state.updateEmployeeProfileError = null;
      })
      .addCase(UpdateEmployeeProfile.fulfilled, (state, action) => {
        state.LoadUpdateEmployeeProfile = false;
        state.updateEmployeeProfile = action.payload;
      })
      .addCase(UpdateEmployeeProfile.rejected, (state, action) => {
        state.LoadUpdateEmployeeProfile = false;
        state.updateEmployeeProfileError = action.payload;
      });
  },
});

export const { clearUpdateEmployeeProfile } =
  updateEmployeeProfileSlice.actions;

export default updateEmployeeProfileSlice.reducer;
