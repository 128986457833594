import React from "react";

const Toast = (api, message, description) => {
  const placement = "topRight";
  if (message === "Success") {
    api.success({
      message: message,
      description: <div>{description}</div>,
      placement,
    });
  }

  if (message === "Info") {
    api.info({
      message: message,
      description: <div>{description}</div>,
      placement,
    });
  }

  if (message === "Error") {
    api.error({
      message: message,
      description: <div>{description}</div>,
      placement,
    });
  }
};

export default Toast;
