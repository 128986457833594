import { createSlice } from "@reduxjs/toolkit";
import { FetchStudentPaymentData } from "../../../../actions/school/finance/report/fetchStudentPaymentActoin";

const initialState = {
  fetchStudentPayment: null,
  LoadFetchStudentPayment: false,
  fetchStudentPaymentError: null,
};

const fetchStudentPaymentSlice = createSlice({
  name: "fetchStudentPayment",
  initialState: initialState,
  reducers: {
    clearFetchStudentPayment: (state) => {
      state.fetchStudentPayment = null;
      state.fetchStudentPaymentError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(FetchStudentPaymentData.pending, (state) => {
        state.LoadFetchStudentPayment = true;
        state.fetchStudentPaymentError = null;
      })
      .addCase(FetchStudentPaymentData.fulfilled, (state, action) => {
        state.LoadFetchStudentPayment = false;
        state.fetchStudentPayment = action.payload;
      })
      .addCase(FetchStudentPaymentData.rejected, (state, action) => {
        state.LoadFetchStudentPayment = false;
        state.fetchStudentPaymentError = action.payload;
      });
  },
});

export const { clearFetchStudentPayment } = fetchStudentPaymentSlice.actions;

export default fetchStudentPaymentSlice.reducer;
