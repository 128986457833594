import { createSlice } from "@reduxjs/toolkit";
import { RegisterNewEmployee } from "../../../actions/school/employee/registerNewEmployeeActions";

const initialState = {
  registerNewEmployee: null,
  LoadRegisterNewEmployee: false,
  registerNewEmployeeError: null,
};

const registerNewEmployeeSlice = createSlice({
  name: "registerNewEmployee",
  initialState: initialState,
  reducers: {
    clearRegisterNewEmployee: (state) => {
      state.registerNewEmployee = null;
      state.registerNewEmployeeError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(RegisterNewEmployee.pending, (state) => {
        state.LoadRegisterNewEmployee = true;
        state.registerNewEmployeeError = null;
      })
      .addCase(RegisterNewEmployee.fulfilled, (state, action) => {
        state.LoadRegisterNewEmployee = false;
        state.registerNewEmployee = action.payload;
      })
      .addCase(RegisterNewEmployee.rejected, (state, action) => {
        state.LoadRegisterNewEmployee = false;
        state.registerNewEmployeeError = action.payload;
      });
  },
});

export const { clearRegisterNewEmployee } = registerNewEmployeeSlice.actions;

export default registerNewEmployeeSlice.reducer;
