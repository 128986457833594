import { configureStore } from "@reduxjs/toolkit";

import { combineReducers } from "redux";
import loginUserReducer from "./reducers/auth/loginUserReducer";
import logoutUserReducer from "./reducers/auth/logoutUserReducer";
import resetPasswordReducer from "./reducers/auth/resetPasswordReducer";
import currentUserDataReducer from "./reducers/auth/currentUserDataReducer";
import fetchClassLevelsReducer from "./reducers/school/levels/fetchClassLevelsReducer";
import addClassToFacilityReducer from "./reducers/school/levels/addClassToFacilityReducer";
import addClassOptionReducer from "./reducers/school/levels/addClassOptionReducer";
import fetchClassOptionReducer from "./reducers/school/levels/fetchClassOptionReducer";
import updateClassOptionReducer from "./reducers/school/levels/updateClassOptionReducer";
import fetchDefaultDataReducer from "./reducers/school/otherConfigurations/fetchDefaultDataReducer";
import manageCurrenciesReducer from "./reducers/school/otherConfigurations/manageCurrenciesReducer";
import fetchDefaultCurrenciesDataReducer from "./reducers/school/otherConfigurations/fetchDefaultCurrenciesDataReducer";
import fetchPaymentMethodDataReducer from "./reducers/school/otherConfigurations/fetchPaymentMethodDataReducer";
import managePaymentMethodReducer from "./reducers/school/otherConfigurations/managePaymentMethodReducer";
import fetchDefaultClassLevelsReducer from "./reducers/school/levels/fetchDefaultClassLevelsReducer";
import fetchStudentsReducer from "./reducers/school/students/fetchStudentsReducer";
import registerStudentReducer from "./reducers/school/students/registerStudentReducer";
import updateStudentReducer from "./reducers/school/students/updateStudentReducer";
import updateFeeTypeReducer from "./reducers/school/finance/manageFee/updateFeeTypeReducer";
import manageFeeReducer from "./reducers/school/finance/manageFee/manageFeeReducer";
import addFeeTypeReducer from "./reducers/school/finance/manageFee/addFeeTypeReducer";
import deleteFeeTypeReducer from "./reducers/school/finance/manageFee/deleteFeeTypeReducer";
import fetchCashReportReducer from "./reducers/school/finance/report/fetchCashReportReducer";
import fetchStudentPaymentReducer from "./reducers/school/finance/report/fetchStudentPaymentReducer";
import recordStudentPaymentsReducer from "./reducers/school/finance/payments/recordStudentPaymentsReducer";
import fetchStudentPaymentDetailsReducer from "./reducers/school/finance/payments/fetchStudentPaymentDetailsReducer";
import fetchEmployeeListReducers from "./reducers/school/employee/fetchEmployeeListReducers";
import updateEmployeeProfileReducer from "./reducers/school/employee/updateEmployeeProfileReducer";
import registerNewEmployeeReducer from "./reducers/school/employee/registerNewEmployeeReducer";
import retrieveEmployeeSalaryReducer from "./reducers/school/finance/payments/retrieveEmployeeSalaryReducer";
import setEmployeeCompensationReducer from "./reducers/school/finance/payments/setEmployeeCompensationReducer";
import fetchEmployeePaymentBalanceReducer from "./reducers/school/finance/payments/fetchEmployeePaymentBalanceReducer";
import signUpReducer from "./reducers/auth/signUpReducer";
import fetchFacilityUsersReducer from "./reducers/school/users/fetchFacilityUsersReducer";
import updateUserStatusReducer from "./reducers/school/users/updateUserStatusReducer";
import fetchPendingPaymentReducer from "./reducers/school/finance/payments/fetchPendingPaymentReducer";
import saveEmployeePaymentReducer from "./reducers/school/finance/payments/saveEmployeePaymentReducer";
import updatePaymentRequestReducer from "./reducers/school/finance/payments/updatePaymentRequestReducer";
import retrieveCourseReducer from "./reducers/school/teachers/retrieveCourseReducer";
import retrieveFacilityCourseReducer from "./reducers/school/teachers/retrieveFacilityCourseReducer";
import recordNewCourseReducer from "./reducers/school/teachers/recordNewCourseReducer";
import updateCourseReducer from "./reducers/school/teachers/updateCourseReducer";
import retrieveFacilityTeachersReducer from "./reducers/school/teachers/retrieveFacilityTeachersReducer";
import addTeacherReducer from "./reducers/school/teachers/addTeacherReducer";
import filterTeacherCourseReducer from "./reducers/school/teachers/filterTeacherCourseReducer";
import retrieveStudentClassesReducer from "./reducers/school/teachers/retrieveStudentClassesReducer";
import fetchGlobalSummaryReducer from "./reducers/school/summary/fetchGlobalSummaryReducer";
import addScheduleReducer from "./reducers/school/teachers/addScheduleReducer";
import retrieveSubLevelReducer from "./reducers/school/levels/retrieveSubLevelReducer";
import addNewSubLevelReducer from "./reducers/school/levels/addNewSubLevelReducer";
import updateSubLevelReducer from "./reducers/school/levels/updateSubLevelReducer";
import retrieveFacilitySubLevelsReducer from "./reducers/school/levels/retrieveFacilitySubLevelsReducer";
import retrieveSchoolTeacherScheduleReducer from "./reducers/school/teachers/retrieveSchoolTeacherScheduleReducer";
import retrieveSummaryOnTeachersReducer from "./reducers/school/teachers/retrieveSummaryOnTeachersReducer";
import retrieveClassTimeManagementReducer from "./reducers/school/otherConfigurations/retrieveClassTimeManagementReducer";
import retrieveTeacherSummaryInformationReducer from "./reducers/teacher/summary/retrieveTeacherSummaryInformationReducer";
import setClassTimeManagementReducer from "./reducers/school/otherConfigurations/setClassTimeManagementReducer";
import retrieveEmployeeWithSalaryReducer from "./reducers/school/employee/retrieveEmployeeWithSalaryReducer";
import retrieveEmployeePaymentFileReducer from "./reducers/school/employee/retrieveEmployeePaymentFileReducer";
import employeeBulkPaymentReducer from "./reducers/school/employee/employeeBulkPaymentReducer";
import retrieveSchoolConfigurationReducer from "./reducers/school/otherConfigurations/retrieveSchoolConfigurationReducer";
import retrieveTeachingLevelsReducer from "./reducers/teacher/levels/retrieveTeachingLevelsReducer";
import retrieveStudentSubjectMarksReducer from "./reducers/teacher/levels/retrieveStudentSubjectMarksReducer";
import retrieveAssessmentFileReducer from "./reducers/teacher/levels/retrieveAssessmentFileReducer";
import generateAssessmentFileReducer from "./reducers/teacher/levels/generateAssessmentFileReducer";
import recordStudentScoresReducer from "./reducers/teacher/levels/recordStudentScoresReducer";
import retrieveStudentMarksDetailsReducer from "./reducers/teacher/levels/retrieveStudentMarksDetailsReducer";
import retrieveSchoolClassesReducer from "./reducers/school/students/retrieveSchoolClassesReducer";
import retrieveClassesStudentsReducer from "./reducers/school/students/retrieveClassesStudentsReducer";
import retrieveStudentCoursesMarksReducer from "./reducers/school/students/retrieveStudentCoursesMarksReducer";
import executeEmployeeBulkPaymentReducer from "./reducers/school/employee/executeEmployeeBulkPaymentReducer";
import schoolConfigurationReducer from "./reducers/school/otherConfigurations/schoolConfigurationReducer";
import recordNewBookReducer from "./reducers/school/library/recordNewBookReducer";
import retrieveRecordedBooksReducer from "./reducers/school/library/retrieveRecordedBooksReducer";
import borrowBooksReducer from "./reducers/school/library/borrowBooksReducer";
import retrieveBorrowingStudentsReducer from "./reducers/school/library/retrieveBorrowingStudentsReducer";
import retrieveBorrowingTeachersReducer from "./reducers/school/library/retrieveBorrowingTeachersReducer";
import createFormativeAssessmentFileReducer from "./reducers/teacher/levels/createFormativeAssessmentFileReducer";
import retrieveStudentMarksReducer from "./reducers/teacher/levels/retrieveStudentMarksReducer";
import retrieveAlltudentsMarksReducer from "./reducers/teacher/levels/retrieveAlltudentsMarksReducer";
import generateFinaleStudentMarksReducer from "./reducers/teacher/levels/generateFinaleStudentMarksReducer";
import fetchEducationSystemReducer from "./reducers/school/otherConfigurations/fetchEducationSystemReducer";
import setLanguageReducer from "./reducers/auth/setLanguageReducer";
import changeLanguageReducer from "./reducers/auth/changeLanguageReducer";

const rootReducer = combineReducers({
    userData: loginUserReducer,
    userLogout: logoutUserReducer,
    signUp: signUpReducer,
    resetUserPassword: resetPasswordReducer,
    currentUserData: currentUserDataReducer,
    fetchClassLevelsData: fetchClassLevelsReducer,
    addClassToFacility: addClassToFacilityReducer,
    addClassOption: addClassOptionReducer,
    fetchClassOption: fetchClassOptionReducer,
    updateClassOption: updateClassOptionReducer,
    fetchDefaultData: fetchDefaultDataReducer,
    manageCurrenciesData: manageCurrenciesReducer,
    fetchDefaultCurrenciesData: fetchDefaultCurrenciesDataReducer,
    fetchPaymentMethodData: fetchPaymentMethodDataReducer,
    managePaymentMethodData: managePaymentMethodReducer,
    fetchDefaultClassLevels: fetchDefaultClassLevelsReducer,
    fetchStudentsData: fetchStudentsReducer,
    registerStudent: registerStudentReducer,
    updateStudentInfo: updateStudentReducer,
    updateFeeType: updateFeeTypeReducer,
    addFeeType: addFeeTypeReducer,
    fetchManageFeeType: manageFeeReducer,
    deleteFeeType: deleteFeeTypeReducer,
    fetchCashReport: fetchCashReportReducer,
    fetchStudentPayment: fetchStudentPaymentReducer,
    recordStudentPayment: recordStudentPaymentsReducer,
    fetchStudentPaymentDetails: fetchStudentPaymentDetailsReducer,
    fetchEmployeeList: fetchEmployeeListReducers,
    updateEmployeeProfile: updateEmployeeProfileReducer,
    registerNewEmployee: registerNewEmployeeReducer,
    retrieveEmployeeSalary: retrieveEmployeeSalaryReducer,
    setEmployeeCompensation: setEmployeeCompensationReducer,
    fetchEmployeePaymentBalance: fetchEmployeePaymentBalanceReducer,
    fetchFacilityUsers: fetchFacilityUsersReducer,
    updateUserStatus: updateUserStatusReducer,
    fetchPendingPayment: fetchPendingPaymentReducer,
    saveEmployeePayment: saveEmployeePaymentReducer,
    updatePaymentRequest: updatePaymentRequestReducer,
    retrieveCourse: retrieveCourseReducer,
    retrieveFacilityCourse: retrieveFacilityCourseReducer,
    recordNewCourse: recordNewCourseReducer,
    updateCourse: updateCourseReducer,
    retrieveFacilityTeachers: retrieveFacilityTeachersReducer,
    addTeacher: addTeacherReducer,
    filterTeacherCourse: filterTeacherCourseReducer,
    retrieveStudentClasses: retrieveStudentClassesReducer,
    fetchGlobalSummary: fetchGlobalSummaryReducer,
    addSchedule: addScheduleReducer,
    retrieveSubLevels: retrieveSubLevelReducer,
    addNewSubLevel: addNewSubLevelReducer,
    updateSubLevel: updateSubLevelReducer,
    retrieveFacilitySubLevels: retrieveFacilitySubLevelsReducer,
    retrieveSchoolTeacherSchedule: retrieveSchoolTeacherScheduleReducer,
    retrieveSummaryOnTeachers: retrieveSummaryOnTeachersReducer,
    retrieveClassTimeManagement: retrieveClassTimeManagementReducer,
    retrieveTeacherSummaryInformation: retrieveTeacherSummaryInformationReducer,
    setClassTimeManagement: setClassTimeManagementReducer,
    retrieveEmployeeWithSalary: retrieveEmployeeWithSalaryReducer,
    retrieveEmployeePaymentFile: retrieveEmployeePaymentFileReducer,
    employeeBulkPayment: employeeBulkPaymentReducer,
    retrieveSchoolConfiguration: retrieveSchoolConfigurationReducer,
    retrieveTeachingLevels: retrieveTeachingLevelsReducer,
    retrieveStudentSubjectMarks: retrieveStudentSubjectMarksReducer,
    retrieveAssessmentFile: retrieveAssessmentFileReducer,
    generateAssessmentFile: generateAssessmentFileReducer,
    recordStudentScores: recordStudentScoresReducer,
    retrieveStudentMarksDetails: retrieveStudentMarksDetailsReducer,
    retrieveSchoolClasses: retrieveSchoolClassesReducer,
    retrieveClassesStudents: retrieveClassesStudentsReducer,
    retrieveStudentCoursesMarks: retrieveStudentCoursesMarksReducer,
    executeEmployeeBulkPayment: executeEmployeeBulkPaymentReducer,
    schoolConfiguration: schoolConfigurationReducer,
    recordNewBook: recordNewBookReducer,
    retrieveRecordedBooks: retrieveRecordedBooksReducer,
    borrowBooks: borrowBooksReducer,
    retrieveBorrowingStudents: retrieveBorrowingStudentsReducer,
    retrieveBorrowingTeachers: retrieveBorrowingTeachersReducer,
    createFormativeAssessmentFile: createFormativeAssessmentFileReducer,
    retrieveStudentMarks: retrieveStudentMarksReducer,
    retrieveAllStudentsMarks: retrieveAlltudentsMarksReducer,
    generateFinaleStudentMarks: generateFinaleStudentMarksReducer,
    fetchEductionSystem: fetchEducationSystemReducer,
    setLanguage: setLanguageReducer,
    changeLanguage: changeLanguageReducer,
});

const store = configureStore({
    reducer: rootReducer,
});

export default store;
