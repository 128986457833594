import { createSlice } from "@reduxjs/toolkit";
import { EmployeeBulkPayment } from "../../../actions/school/employee/employeeBulkPaymentAction";

const initialState = {
    employeeBulkPayment: null,
    LoadEmployeeBulkPayment: false,
    employeeBulkPaymentError: null,
};

const employeeBulkPaymentSlice = createSlice({
    name: "employeeBulkPayment",
    initialState: initialState,
    reducers: {
        clearEmployeeBulkPayment: (state) => {
            state.employeeBulkPayment = null;
            state.employeeBulkPaymentError = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(EmployeeBulkPayment.pending, (state) => {
                state.LoadEmployeeBulkPayment = true;
                state.employeeBulkPaymentError = null;
            })
            .addCase(EmployeeBulkPayment.fulfilled, (state, action) => {
                state.LoadEmployeeBulkPayment = false;
                state.employeeBulkPayment = action.payload;
            })
            .addCase(EmployeeBulkPayment.rejected, (state, action) => {
                state.LoadEmployeeBulkPayment = false;
                state.employeeBulkPaymentError = action.payload;
            });
    },
});

export const { clearEmployeeBulkPayment } = employeeBulkPaymentSlice.actions;

export default employeeBulkPaymentSlice.reducer;
