import { createSlice } from "@reduxjs/toolkit";
import { UpdateSubLevel } from "../../../actions/school/levels/updateSubLevelActions";

const initialState = {
    updateSubLevel: null,
    LoadUpdateSubLevel: false,
    updateSubLevelError: null,
};

const updateSubLevelSlice = createSlice({
    name: "updateSubLevel",
    initialState: initialState,
    reducers: {
        clearUpdateSubLevel: (state) => {
            state.updateSubLevel = null;
            state.updateSubLevelError = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(UpdateSubLevel.pending, (state) => {
                state.LoadUpdateSubLevel = true;
                state.updateSubLevelError = null;
            })
            .addCase(UpdateSubLevel.fulfilled, (state, action) => {
                state.LoadUpdateSubLevel = false;
                state.updateSubLevel = action.payload;
            })
            .addCase(UpdateSubLevel.rejected, (state, action) => {
                state.LoadUpdateSubLevel = false;
                state.updateSubLevelError = action.payload;
            });
    },
});

export const { clearUpdateSubLevel } = updateSubLevelSlice.actions;

export default updateSubLevelSlice.reducer;
