import React from "react";
import { Drawer, Table, Space, Button } from "antd";
import { AiOutlineCloseCircle } from "react-icons/ai";
import CustomSeparator from "../../../../components/common/customrSeparator";

const AllStudentsMarks = ({
    open,
    onClose,
    setOpenDrawer,
    levelName,
    retrieveStudentMarks,
}) => {
    const column = [
        {
            title: "Student",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Student Code",
            dataIndex: "studentCode",
            key: "studentCode",
        },
        {
            title: "Total Score",
            dataIndex: "studentScores",
            key: "studentScores",
        },
        {
            title: "Total Marks",
            dataIndex: "totalMarks",
            key: "totalMarks",
        },
    ];
    const tableData =
        retrieveStudentMarks?.data &&
        retrieveStudentMarks?.data?.student_marks.map((item, index) => ({
            key: index,
            id: item?.id,
            name: `${item?.first_name} ${item?.last_name}`,
            studentCode: item?.student_code,
            studentScores: item?.total_scores,
            totalMarks: item?.assessment_total_marks,
        }));

    const subjectInfo =
        retrieveStudentMarks?.data && retrieveStudentMarks?.data?.subject_info;

    return (
        <Drawer
            width={650}
            title={`${levelName}`}
            placement="right"
            open={open}
            closable={false}
            destroyOnClose={false}
            closeIcon
            extra={
                <Space className="flex-row-sb">
                    <Button
                        danger
                        shape="circle"
                        type="text"
                        onClick={() => {
                            onClose();
                        }}
                    >
                        <AiOutlineCloseCircle size={25} />
                    </Button>
                </Space>
            }
        >
            <div className="flex-column">
                <div className="flex-row-sb grey-border-bottom padding-bottom-10">
                    <strong>Subject</strong>
                    <div>{subjectInfo?.name}</div>
                </div>
                <CustomSeparator size={5} />
                <div className="flex-row-sb  grey-border-bottom padding-bottom-10">
                    <strong>Course hours</strong>
                    <div>{subjectInfo?.hours}</div>
                </div>
                <CustomSeparator size={5} />
                <div className="flex-row-sb ">
                    <strong>Total Subject Marks</strong>
                    <div>{subjectInfo?.total_marks}</div>
                </div>
            </div>
            <CustomSeparator />
            <Table columns={column} pagination={false} dataSource={tableData} />
        </Drawer>
    );
};

export default AllStudentsMarks;
