import { createSlice } from "@reduxjs/toolkit";
import { RecordNewBook } from "../../../actions/school/library/recordNewBookAction";

const initialState = {
    recordNewBooks: null,
    loadingRecordNewBooks: false,
    recordNewBooksError: null,
};

const recordNewBooksSlice = createSlice({
    name: "recordNewBooks",
    initialState: initialState,
    reducers: {
        clearRecordNewBook: (state) => {
            state.recordNewBooks = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(RecordNewBook.pending, (state) => {
                state.loadingRecordNewBooks = true;
                state.recordNewBooksError = null;
            })
            .addCase(RecordNewBook.fulfilled, (state, action) => {
                state.loadingRecordNewBooks = false;
                state.recordNewBooks = action.payload;
            })
            .addCase(RecordNewBook.rejected, (state, action) => {
                state.loadingRecordNewBooks = false;
                state.recordNewBooksError = action.payload;
            });
    },
});

export const { clearRecordNewBook } = recordNewBooksSlice.actions;

export default recordNewBooksSlice.reducer;
