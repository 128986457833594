import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BottomContainerItem from "../../../components/bottomContainerItem";
import HorizontalScrollableDiv from "../../../components/common/HorizontalScrollableDiv";

import LocalTab from "./localTab";
import { Space, Button, Radio } from "antd";
import { DoubleLeftOutlined } from "@ant-design/icons";
import CustomSeparator from "../../../components/common/customrSeparator";
import StudentCoursesMarks from "./studentCoursesMarks";
import { useDispatch, useSelector } from "react-redux";
import { clearRetrieveStudentSubjectMarks } from "../../../redux/reducers/teacher/levels/retrieveStudentSubjectMarksReducer";
import AssessmentFiles from "./assessmentFiles";
import teacherSelector from "../../../redux/selectors/teacherSelector";

const StudentEvaluation = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const receivedData = location.state && location?.state.data;
    const [summaryData, setSummaryData] = useState([]);
    const [changeTab, setChangeTab] = useState("1");
    const [selectedClass, setSelectedClass] = useState();

    const {
        retrieveAssessmentFile: { retrieveAssessmentFile },
        retrieveStudentSubjectMarks: { retrieveStudentSubjectMarks },
    } = useSelector(teacherSelector);

    const subjectInfo =
        retrieveStudentSubjectMarks?.data?.subject_info &&
        retrieveStudentSubjectMarks?.data?.subject_info;

    const assessmentFolderInfo =
        retrieveAssessmentFile?.data &&
        retrieveAssessmentFile.data?.description;

    const assessmentFileNumber =
        retrieveAssessmentFile?.data &&
        retrieveAssessmentFile.data?.file_number;

    const assessmentFiles = retrieveAssessmentFile?.data;

    const folders =
        assessmentFiles?.files &&
        assessmentFiles?.files.map((file, index) => ({
            key: index,
            label: file?.file_name,
            value: file?.id,
        }));

    const classInfoData = useCallback(() => {
        setSummaryData(() => [
            {
                title1Value: receivedData?.classes?.levelName,
                title2Value: receivedData?.classes?.studentNumber,
                title1: "Class Name",
                title2: "Number of students",
            },
            assessmentFolderInfo && {
                title1Value: "All",
                title2Value:
                    assessmentFolderInfo && assessmentFolderInfo?.number,
                title1: "Assessment Folder",
                title2: "Number",
            },
            assessmentFileNumber && {
                title1Value: "All",
                title2Value: assessmentFileNumber,
                title1: "Assessment files",
                title2: "Number",
            },
            changeTab === "2" &&
                subjectInfo && {
                    title1Value: subjectInfo?.name,
                    title2Value:
                        subjectInfo?.new_total_marks ||
                        subjectInfo?.total_marks,
                    title1: "Subject",
                    title2: "Total Marks",
                },
        ]);
    }, [
        assessmentFileNumber,
        assessmentFolderInfo,
        changeTab,
        receivedData?.classes?.levelName,
        receivedData?.classes?.studentNumber,
        subjectInfo,
    ]);

    const subjectsData =
        receivedData?.subjects &&
        receivedData?.subjects.filter(
            (subject) => subject.level_id === receivedData?.classes?.levelId
        );

    const dropdownData =
        subjectsData &&
        subjectsData.map((item, index) => ({
            key: index,
            label: item.subject,
            value: item.id,
        }));

    useEffect(() => {
        classInfoData();
    }, [classInfoData]);

    useEffect(() => {
        if (receivedData?.classes) {
            setSelectedClass(receivedData?.classes?.subLevelId);
        }
    }, [receivedData?.classes]);

    return (
        <div className="item-container-box">
            <BottomContainerItem>
                <div className="flex-column width-100-percent">
                    <div className="flex-row-sb margin-bottom-10 grey-border-bottom padding-bottom-10">
                        <Space key={"1"}>
                            <Button
                                type="link"
                                onClick={() => {
                                    navigate("/teacher-classes");
                                    dispatch(
                                        clearRetrieveStudentSubjectMarks()
                                    );
                                }}
                            >
                                <DoubleLeftOutlined
                                    size={30}
                                    style={{ alignSelf: "center" }}
                                />
                                Back
                            </Button>
                        </Space>
                        <Space>
                            <Radio.Group
                                buttonStyle="solid"
                                value={changeTab}
                                onChange={(e) => setChangeTab(e.target.value)}
                            >
                                <Radio.Button value="1">
                                    Assessment Folders
                                </Radio.Button>
                                <Radio.Button value="2">
                                    Student Subject Marks
                                </Radio.Button>
                            </Radio.Group>
                        </Space>
                    </div>
                    <HorizontalScrollableDiv>
                        {summaryData.map((item, index) => {
                            if (item) {
                                return (
                                    <LocalTab
                                        key={index}
                                        index={index}
                                        item={item}
                                    />
                                );
                            }
                        })}
                    </HorizontalScrollableDiv>
                </div>
            </BottomContainerItem>
            <CustomSeparator size={5} />
            <BottomContainerItem>
                {changeTab === "1" && (
                    <AssessmentFiles
                        dropdownData={dropdownData}
                        subLevelId={selectedClass}
                    />
                )}
                {changeTab === "2" && (
                    <StudentCoursesMarks
                        dropdownData={dropdownData}
                        subLevelId={selectedClass}
                        folders={folders}
                    />
                )}
            </BottomContainerItem>
        </div>
    );
};

export default StudentEvaluation;
