import { createSlice } from "@reduxjs/toolkit";
import { FetchStudentsData } from "../../../actions/school/students/fetchStudentsActions";

const initialState = {
  fetchStudentsData: null,
  LoadFetchStudentsData: false,
  fetchStudentsDataError: null,
};

const fetchStudentsDataSlice = createSlice({
  name: "fetchStudentsData",
  initialState: initialState,
  reducers: {
    clearFetchStudentsData: (state) => {
      state.fetchStudentsData = null;
      state.fetchStudentsDataError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(FetchStudentsData.pending, (state) => {
        state.LoadFetchStudentsData = true;
        state.fetchStudentsDataError = null;
      })
      .addCase(FetchStudentsData.fulfilled, (state, action) => {
        state.LoadFetchStudentsData = false;
        state.fetchStudentsData = action.payload;
      })
      .addCase(FetchStudentsData.rejected, (state, action) => {
        state.LoadFetchStudentsData = false;
        state.fetchStudentsDataError = action.payload;
      });
  },
});

export const { clearFetchStudentsData } = fetchStudentsDataSlice.actions;

export default fetchStudentsDataSlice.reducer;
