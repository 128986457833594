import { createSlice } from "@reduxjs/toolkit";
import { RecordStudentScores } from "../../../actions/teacher/levels/recordStudentScoresAction";

const initialState = {
    recordStudentScores: null,
    LoadRecordStudentScores: false,
    recordStudentScoresError: null,
};

const recordStudentScoresSlice = createSlice({
    name: "recordStudentScores",
    initialState: initialState,
    reducers: {
        clearRecordStudentScores: (state) => {
            state.recordStudentScores = null;
            state.recordStudentScoresError = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(RecordStudentScores.pending, (state) => {
                state.LoadRecordStudentScores = true;
                state.recordStudentScoresError = null;
            })
            .addCase(RecordStudentScores.fulfilled, (state, action) => {
                state.LoadRecordStudentScores = false;
                state.recordStudentScores = action.payload;
            })
            .addCase(RecordStudentScores.rejected, (state, action) => {
                state.LoadRecordStudentScores = false;
                state.recordStudentScoresError = action.payload;
            });
    },
});

export const { clearRecordStudentScores } = recordStudentScoresSlice.actions;

export default recordStudentScoresSlice.reducer;
