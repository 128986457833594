import { createSelector } from "reselect";

const getUserLogout = (state) => state.userLogout.userLogout;
const getCurrentUserData = (state) => state.currentUserData.currentUserData;
const signUp = (state) => state.signUp;
const changeLanguage = (state) => state.changeLanguage;
const setLanguage = (state) => state.setLanguage;

const authSelector = createSelector(
    [getCurrentUserData, getUserLogout, signUp, setLanguage, changeLanguage],
    (currentUserData, userLogout, changeLanguage, setLanguage) => ({
        userLogout,
        currentUserData,
        signUp,
        setLanguage,
        changeLanguage,
    })
);

export default authSelector;
