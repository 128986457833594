import { createSlice } from "@reduxjs/toolkit";
import { FetchPaymentMethodData } from "../../../actions/school/otherConfiguration/fetchPaymentMethodActions";

const initialState = {
  fetchPaymentMethodData: null,
  LoadFetchPaymentMethodData: false,
  fetchPaymentMethodDataError: null,
};

const fetchPaymentMethodDataSlice = createSlice({
  name: "fetchPaymentMethodData",
  initialState: initialState,
  reducers: {
    clearFetchPaymentMethodData: (state) => {
      state.fetchPaymentMethodData = null;
      state.fetchPaymentMethodDataError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(FetchPaymentMethodData.pending, (state) => {
        state.LoadFetchPaymentMethodData = true;
        state.fetchPaymentMethodDataError = null;
      })
      .addCase(FetchPaymentMethodData.fulfilled, (state, action) => {
        state.LoadFetchPaymentMethodData = false;
        state.fetchPaymentMethodData = action.payload;
      })
      .addCase(FetchPaymentMethodData.rejected, (state, action) => {
        state.LoadFetchPaymentMethodData = false;
        state.fetchPaymentMethodDataError = action.payload;
      });
  },
});

export const { clearFetchPaymentMethodData } =
  fetchPaymentMethodDataSlice.actions;

export default fetchPaymentMethodDataSlice.reducer;
