import { createSlice } from "@reduxjs/toolkit";
import { RetrieveClassTimeManagement } from "../../../actions/school/otherConfiguration/retrieveClassTimeManagementActions";

const initialState = {
    retrieveClassTimeManagement: null,
    LoadRetrieveClassTimeManagement: false,
    retrieveClassTimeManagementError: null,
};

const retrieveClassTimeManagementSlice = createSlice({
    name: "retrieveClassTimeManagement",
    initialState: initialState,
    reducers: {
        clearRetrieveClassTimeManagement: (state) => {
            state.retrieveClassTimeManagement = null;
            state.retrieveClassTimeManagementError = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(RetrieveClassTimeManagement.pending, (state) => {
                state.LoadRetrieveClassTimeManagement = true;
                state.retrieveClassTimeManagementError = null;
            })
            .addCase(RetrieveClassTimeManagement.fulfilled, (state, action) => {
                state.LoadRetrieveClassTimeManagement = false;
                state.retrieveClassTimeManagement = action.payload;
            })
            .addCase(RetrieveClassTimeManagement.rejected, (state, action) => {
                state.LoadRetrieveClassTimeManagement = false;
                state.retrieveClassTimeManagementError = action.payload;
            });
    },
});

export const { clearRetrieveClassTimeManagement } =
    retrieveClassTimeManagementSlice.actions;

export default retrieveClassTimeManagementSlice.reducer;
