import { createSlice } from "@reduxjs/toolkit";
import { SchoolConfiguration } from "../../../actions/school/otherConfiguration/schoolConfigurationAction";

const initialState = {
    schoolConfiguration: null,
    LoadSchoolConfiguration: false,
    schoolConfigurationError: null,
};

const schoolConfigurationSlice = createSlice({
    name: "schoolConfiguration",
    initialState: initialState,
    reducers: {
        clearSchoolConfiguration: (state) => {
            state.schoolConfiguration = null;
            state.schoolConfigurationError = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(SchoolConfiguration.pending, (state) => {
                state.LoadSchoolConfiguration = true;
                state.schoolConfigurationError = null;
            })
            .addCase(SchoolConfiguration.fulfilled, (state, action) => {
                state.LoadSchoolConfiguration = false;
                state.schoolConfiguration = action.payload;
            })
            .addCase(SchoolConfiguration.rejected, (state, action) => {
                state.LoadSchoolConfiguration = false;
                state.schoolConfigurationError = action.payload;
            });
    },
});

export const { clearSchoolConfiguration } = schoolConfigurationSlice.actions;

export default schoolConfigurationSlice.reducer;
