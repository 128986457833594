import { createSlice } from "@reduxjs/toolkit";
import { RetrieveSchoolClasses } from "../../../actions/school/students/retrieveSchoolClassesAction";

const initialState = {
    retrieveSchoolClasses: null,
    LoadRetrieveSchoolClasses: false,
    retrieveSchoolClassesError: null,
};

const retrieveSchoolClassesSlice = createSlice({
    name: "retrieveSchoolClasses",
    initialState: initialState,
    reducers: {
        clearRetrieveSchoolClasses: (state) => {
            state.retrieveSchoolClasses = null;
            state.retrieveSchoolClassesError = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(RetrieveSchoolClasses.pending, (state) => {
                state.LoadRetrieveSchoolClasses = true;
                state.retrieveSchoolClassesError = null;
            })
            .addCase(RetrieveSchoolClasses.fulfilled, (state, action) => {
                state.LoadRetrieveSchoolClasses = false;
                state.retrieveSchoolClasses = action.payload;
            })
            .addCase(RetrieveSchoolClasses.rejected, (state, action) => {
                state.LoadRetrieveSchoolClasses = false;
                state.retrieveSchoolClassesError = action.payload;
            });
    },
});

export const { clearRetrieveSchoolClasses } =
    retrieveSchoolClassesSlice.actions;

export default retrieveSchoolClassesSlice.reducer;
