import React, { useEffect } from "react";
import { Table, Tag } from "antd";
import DashboardCard from "../../dashboard/DashboardCard";
import { MdClass } from "react-icons/md";
import "../style/index.css";
import CustomSeparator from "../../../components/common/customrSeparator";
import { PiStudentFill } from "react-icons/pi";
import { GiBookshelf } from "react-icons/gi";
import CustomSpacer from "../../../components/common/customSpacer/index";
import { useDispatch, useSelector } from "react-redux";
import teacherSelector from "../../../redux/selectors/teacherSelector";
import { LoadingOutlined } from "@ant-design/icons";
import { RetrieveTeacherSummaryInformation } from "../../../redux/actions/teacher/summary/retrieveTeacherSummaryInformationActions";

const TDashboard = () => {
    const dispatch = useDispatch();
    const {
        retrieveTeacherSummaryInformation: {
            retrieveTeacherSummaryInformation,
            LoadRetrieveTeacherSummaryInformation,
        },
    } = useSelector(teacherSelector);

    const summaryData =
        retrieveTeacherSummaryInformation &&
        retrieveTeacherSummaryInformation?.data;

    const columns = [
        {
            title: "Monday",
            dataIndex: "monday",
            key: "monday",
            render: (text) => (
                <span>
                    {text === "Day Off" ? <Tag color="red">{text}</Tag> : text}
                </span>
            ),
        },
        {
            title: "Tuesday",
            dataIndex: "tuesday",
            key: "tuesday",
            render: (text) => (
                <span>
                    {text === "Day Off" ? <Tag color="red">{text}</Tag> : text}
                </span>
            ),
        },
        {
            title: "Wednesday",
            dataIndex: "wednesday",
            key: "wednesday",
            render: (text) => (
                <span>
                    {text === "Day Off" ? <Tag color="red">{text}</Tag> : text}
                </span>
            ),
        },
        {
            title: "Thursday",
            dataIndex: "thursday",
            key: "thursday",
            render: (text) => (
                <span>
                    {text === "Day Off" ? <Tag color="red">{text}</Tag> : text}
                </span>
            ),
        },
        {
            title: "Friday",
            dataIndex: "friday",
            key: "friday",
            render: (text) => (
                <span>
                    {text === "Day Off" ? <Tag color="red">{text}</Tag> : text}
                </span>
            ),
        },
    ];

    const teacherTimetableData =
        summaryData &&
        summaryData?.time_table.map((item, index) => ({
            key: index,
            monday:
                item?.week_day === "Monday" &&
                `${item?.sub_level_name} - ${item?.name} (${item?.time_slot})`,
            tuesday:
                item?.week_day === "Tuesday" &&
                `${item?.sub_level_name} - ${item?.name}  (${item?.time_slot})`,
            wednesday:
                item?.week_day === "Wednesday" &&
                `${item?.sub_level_name} - ${item?.name}  (${item?.time_slot})`,
            thursday:
                item?.week_day === "Thursday" &&
                `${item?.sub_level_name} - ${item?.name}  (${item?.time_slot})`,
            friday:
                item?.week_day === "Friday" &&
                `${item?.sub_level_name} - ${item?.name}  (${item?.time_slot})`,
        }));

    useEffect(() => {
        dispatch(RetrieveTeacherSummaryInformation());
    }, [dispatch]);

    return (
        <div className="dashboard-item-container-box">
            <div className="item-container-box">
                <div className="flex-column width-100-percent tiny-padding">
                    <div className="report-card">
                        <DashboardCard
                            title="Classes"
                            Icon={
                                LoadRetrieveTeacherSummaryInformation
                                    ? LoadingOutlined
                                    : MdClass
                            }
                            number={summaryData?.classes || 0}
                            iconColor={"rgb(4, 78, 84)"}
                            background={"rgb(224, 232, 249)"}
                        />
                        <DashboardCard
                            title="Courses"
                            Icon={
                                LoadRetrieveTeacherSummaryInformation
                                    ? LoadingOutlined
                                    : GiBookshelf
                            }
                            number={summaryData?.courses || 0}
                            iconColor={"rgb(4, 78, 84)"}
                            background={"rgb(224, 232, 249)"}
                        />
                        <DashboardCard
                            title="Students"
                            Icon={
                                LoadRetrieveTeacherSummaryInformation
                                    ? LoadingOutlined
                                    : PiStudentFill
                            }
                            number={summaryData?.students || 0}
                            iconColor={"rgb(4, 78, 84)"}
                            background={"rgb(224, 232, 249)"}
                        />
                    </div>
                    <div className="flex-row-sb width-100-percent">
                        <div className="container-box tiny-padding">
                            <CustomSeparator size={10} />
                            <div className="flex-column width-100-percent grey-border-bottom padding-bottom-10">
                                <span className="bold-text padding-top-20">
                                    Today
                                </span>
                            </div>
                            <CustomSeparator size={10} />
                            {summaryData &&
                                summaryData?.today_schedule?.map(
                                    (item, index) => (
                                        <>
                                            {item?.is_break_time === false ? (
                                                <div
                                                    key={index}
                                                    className="grey-border-bottom flex-column width-100-percent padding-10"
                                                >
                                                    <div
                                                        className="align-text-left"
                                                        style={{
                                                            width: "250px",
                                                        }}
                                                    >
                                                        <span>
                                                            {item?.name}
                                                        </span>
                                                    </div>
                                                    <CustomSeparator size={5} />
                                                    <div
                                                        className="flex-row align-text-left"
                                                        style={{
                                                            width: "250px",
                                                        }}
                                                    >
                                                        <span className="text-grey-color">
                                                            {item?.time_slot}
                                                        </span>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div
                                                    key={index}
                                                    className="grey-border-bottom flex-column width-100-percent padding-10"
                                                >
                                                    <div
                                                        className="align-text-left"
                                                        style={{
                                                            width: "250px",
                                                            color: "red",
                                                        }}
                                                    >
                                                        <span>Break Time</span>
                                                    </div>
                                                    <CustomSeparator size={5} />
                                                    <div
                                                        className="flex-row align-text-left"
                                                        style={{
                                                            width: "250px",
                                                        }}
                                                    >
                                                        <span className="text-grey-color">
                                                            {item?.time_slot}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    )
                                )}
                            {!summaryData && (
                                <div className="grey-border-bottom flex-column width-100-percent padding-10">
                                    <div
                                        className="align-text-left"
                                        style={{
                                            width: "250px",
                                        }}
                                    >
                                        <span>Loading</span>
                                    </div>
                                    <CustomSeparator size={5} />
                                    <div
                                        className="flex-row align-text-left"
                                        style={{
                                            width: "250px",
                                        }}
                                    >
                                        <span className="text-grey-color">
                                            Loading
                                        </span>
                                    </div>
                                </div>
                            )}
                            {summaryData?.today_schedule.length === 0 && (
                                <div className="grey-border-bottom flex-column width-100-percent padding-10">
                                    <div
                                        className="align-text-left"
                                        style={{
                                            width: "250px",
                                        }}
                                    ></div>
                                    <CustomSeparator size={5} />
                                    <div
                                        className="flex-row align-text-left"
                                        style={{
                                            width: "250px",
                                        }}
                                    >
                                        <span className="text-grey-color">
                                            No schedule found
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <CustomSpacer />
                        <Table
                            bordered
                            columns={columns}
                            title={() => <h3>Time table</h3>}
                            className="width-80-percent"
                            dataSource={teacherTimetableData}
                            pagination={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TDashboard;
