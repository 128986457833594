import { createSlice } from "@reduxjs/toolkit";
import { FilterTeacherCourses } from "../../../actions/school/teachers/filterTeacherCourseActions";

const initialState = {
    filterTeacherCourse: null,
    LoadFilterTeacherCourse: false,
    filterTeacherCourseError: null,
};

const filterTeacherCourseSlice = createSlice({
    name: "filterTeacherCourse",
    initialState: initialState,
    reducers: {
        clearFilterTeacherCourse: (state) => {
            state.filterTeacherCourse = null;
            state.filterTeacherCourseError = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(FilterTeacherCourses.pending, (state) => {
                state.LoadFilterTeacherCourse = true;
                state.filterTeacherCourseError = null;
            })
            .addCase(FilterTeacherCourses.fulfilled, (state, action) => {
                state.LoadFilterTeacherCourse = false;
                state.filterTeacherCourse = action.payload;
            })
            .addCase(FilterTeacherCourses.rejected, (state, action) => {
                state.LoadFilterTeacherCourse = false;
                state.filterTeacherCourseError = action.payload;
            });
    },
});

export const { clearFilterTeacherCourse } = filterTeacherCourseSlice.actions;

export default filterTeacherCourseSlice.reducer;
