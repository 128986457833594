import { createAsyncThunk } from "@reduxjs/toolkit/dist";
import apiRequest from "../../../helpers/apiActions/apiRequest";
import endPoints from "../../../utils/Endpoint";

export const SignUp = createAsyncThunk(
  "signUp",
  async (requestData, { rejectWithValue }) => {
    const response = await apiRequest({
      url: endPoints.signUp,
      method: "POST",
      requestData: requestData,
      rejectWithValue: rejectWithValue,
    });
    return response;
  }
);
