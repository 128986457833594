import { createSlice } from "@reduxjs/toolkit";
import { RetrieveEmployeeSalary } from "../../../../actions/school/finance/payments/retrieveEmployeeSalaryAction";

const initialState = {
  retrieveEmployeeSalary: null,
  LoadRetrieveEmployeeSalary: false,
  retrieveEmployeeSalaryError: null,
};

const retrieveEmployeeSalarySlice = createSlice({
  name: "retrieveEmployeeSalary",
  initialState: initialState,
  reducers: {
    clearRetrieveEmployeeSalary: (state) => {
      state.retrieveEmployeeSalary = null;
      state.retrieveEmployeeSalaryError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(RetrieveEmployeeSalary.pending, (state) => {
        state.LoadRetrieveEmployeeSalary = true;
        state.retrieveEmployeeSalaryError = null;
      })
      .addCase(RetrieveEmployeeSalary.fulfilled, (state, action) => {
        state.LoadRetrieveEmployeeSalary = false;
        state.retrieveEmployeeSalary = action.payload;
      })
      .addCase(RetrieveEmployeeSalary.rejected, (state, action) => {
        state.LoadRetrieveEmployeeSalary = false;
        state.retrieveEmployeeSalaryError = action.payload;
      });
  },
});

export const { clearRetrieveEmployeeSalary } =
  retrieveEmployeeSalarySlice.actions;

export default retrieveEmployeeSalarySlice.reducer;
