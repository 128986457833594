import { createSlice } from "@reduxjs/toolkit";
import { RegisterStudents } from "../../../actions/school/students/registerStudentActions";

const initialState = {
  registerStudent: null,
  LoadRegisterStudent: false,
  registerStudentError: null,
};

const registerStudentSlice = createSlice({
  name: "registerStudent",
  initialState: initialState,
  reducers: {
    clearRegisterStudent: (state) => {
      state.registerStudent = null;
      state.registerStudentError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(RegisterStudents.pending, (state) => {
        state.LoadRegisterStudent = true;
        state.registerStudentError = null;
      })
      .addCase(RegisterStudents.fulfilled, (state, action) => {
        state.LoadRegisterStudent = false;
        state.registerStudent = action.payload;
      })
      .addCase(RegisterStudents.rejected, (state, action) => {
        state.LoadRegisterStudent = false;
        state.registerStudentError = action.payload;
      });
  },
});

export const { clearRegisterStudent } = registerStudentSlice.actions;

export default registerStudentSlice.reducer;
