import { Suspense, lazy } from "react";
import SignUpPage from "../auth/signup";
import ManageTeacherPages from "../teacherPages/ManageTeachersPages";
import StartingPage from "../publicClients/startingPage";

const LoginPage = lazy(() => import("../auth/login"));
const ManagePages = lazy(() => import("../managePage"));
const LandingPage = lazy(() => import("../auth/login"));

const managePagesElement = (
    <Suspense>
        <ManagePages />
    </Suspense>
);

const manageTeacherPagesElement = (
    <Suspense>
        <ManageTeacherPages />
    </Suspense>
);

const routes = [
    {
        path: "/",
        element: (
            <Suspense>
                <StartingPage />
            </Suspense>
        ),
        isProtected: false,
        key: "starting-page",
    },
    {
        path: "/school-admin",
        element: (
            <Suspense>
                <LandingPage />
            </Suspense>
        ),
        isProtected: false,
        key: "login",
    },
    {
        path: "/login",
        element: (
            <Suspense>
                <LoginPage />
            </Suspense>
        ),
        isProtected: false,
        key: "login",
    },
    {
        path: "/signup",
        element: (
            <Suspense>
                <SignUpPage />
            </Suspense>
        ),
        isProtected: false,
        key: "signup",
    },
    {
        path: "/dashboard",
        element: managePagesElement,
        isProtected: true,
        key: "dashboard",
    },
    {
        path: "/teacher-dashboard",
        element: manageTeacherPagesElement,
        isProtected: true,
        key: "teacher-dashboard",
    },
    {
        path: "/teacher-students",
        element: manageTeacherPagesElement,
        isProtected: true,
        key: "teacher-students",
    },
    {
        path: "/teacher-classes",
        element: manageTeacherPagesElement,
        isProtected: true,
        key: "teacher-classes",
    },
    {
        path: "/students-evaluation",
        element: manageTeacherPagesElement,
        isProtected: true,
        key: "students-evaluation",
    },
    {
        path: "/school-students-evaluation",
        element: managePagesElement,
        isProtected: true,
        key: "school-students-evaluation",
    },
    {
        path: "/configuration",
        element: managePagesElement,
        isProtected: true,
        key: "configuration",
    },
    {
        path: "/school-library",
        element: managePagesElement,
        isProtected: true,
        key: "school-library",
    },
    {
        path: "/manage-students",
        element: managePagesElement,
        isProtected: true,
        key: "manage-students",
    },
    {
        path: "/payments",
        element: managePagesElement,
        isProtected: true,
        key: "payments",
    },
    {
        path: "/employees",
        element: managePagesElement,
        isProtected: true,
        key: "employees",
    },
    {
        path: "/report",
        element: managePagesElement,
        isProtected: true,
        key: "report",
    },
    {
        path: "/employee",
        element: managePagesElement,
        isProtected: true,
        key: "employee",
    },
    {
        path: "*",
        element: managePagesElement,
        isProtected: true,
        key: "Error page",
    },
];

export default routes;
