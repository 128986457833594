import { Select } from "antd";
import { useSelector } from "react-redux";
import otherConfigurationSelector from "../../../../redux/selectors/otherConfigurationSelector";

const EductionSystem = () => {
    const {
        fetchEductionSystem: { fetchEducationSystem, LoadFetchEducationSystem },
    } = useSelector(otherConfigurationSelector);

    const data = (fetchEducationSystem && fetchEducationSystem.data) || [];

    const levelOrganized = data.map((item, index) => ({
        key: index,
        label: item?.level,
        value: item?.id,
    }));

    console.log(data);

    return (
        <Select
            showSearch
            style={{ width: "100%" }}
            size="large"
            placeholder="Select your level of study you organize"
            optionFilterProp="children"
            loading={LoadFetchEducationSystem}
            filterOption={(input, option) =>
                (option?.label ?? "").includes(input)
            }
            filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={levelOrganized}
        />
    );
};

export default EductionSystem;
