import { createSlice } from "@reduxjs/toolkit";
import { FetchDefaultCurrenciesData } from "../../../actions/school/otherConfiguration/fetchDefaultCurrenciesDataActions";

const initialState = {
  fetchDefaultCurrenciesData: null,
  LoadFetchDefaultCurrenciesData: false,
  fetchDefaultCurrenciesDataError: null,
};

const fetchDefaultCurrenciesDataSlice = createSlice({
  name: "fetchDefaultCurrenciesData",
  initialState: initialState,
  reducers: {
    clearFetchDefaultCurrenciesData: (state) => {
      state.fetchDefaultCurrenciesData = null;
      state.fetchDefaultCurrenciesDataError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(FetchDefaultCurrenciesData.pending, (state) => {
        state.LoadFetchDefaultCurrenciesData = true;
        state.fetchDefaultCurrenciesDataError = null;
      })
      .addCase(FetchDefaultCurrenciesData.fulfilled, (state, action) => {
        state.LoadFetchDefaultCurrenciesData = false;
        state.fetchDefaultCurrenciesData = action.payload;
      })
      .addCase(FetchDefaultCurrenciesData.rejected, (state, action) => {
        state.LoadFetchDefaultCurrenciesData = false;
        state.fetchDefaultCurrenciesDataError = action.payload;
      });
  },
});

export const { clearFetchDefaultCurrenciesData } =
  fetchDefaultCurrenciesDataSlice.actions;

export default fetchDefaultCurrenciesDataSlice.reducer;
