/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import BottomContainerItem from "../../../components/bottomContainerItem";
import { useDispatch, useSelector } from "react-redux";
import { RetrieveTeachingLevels } from "../../../redux/actions/teacher/levels/retrieveTeachingLevelsAction";
import teacherSelector from "../../../redux/selectors/teacherSelector";
import {
    Table,
    Space,
    Badge,
    Button,
    Modal,
    Switch,
    Divider,
    Select,
    Empty,
    Dropdown,
    notification,
} from "antd";
import { useNavigate } from "react-router-dom";
import { CgDetailsMore } from "react-icons/cg";
import CustomSeparator from "../../../components/common/customrSeparator";

import otherConfigurationSelector from "../../../redux/selectors/otherConfigurationSelector";
import { RetrieveStudentMarks } from "../../../redux/actions/teacher/levels/retrieveStudentMarksAction";
import Toast from "../../../components/common/toast";
import { clearRetrieveStudentMarks } from "../../../redux/reducers/teacher/levels/retrieveStudentMarksReducer";
import StudentMarks from "../studentEvaluation/studentMarks";
import { RetrieveAllStudentsMarks } from "../../../redux/actions/teacher/levels/retrieveAlltudentsMarksAction";
import { clearRetrieveAllStudentsMarks } from "../../../redux/reducers/teacher/levels/retrieveAlltudentsMarksReducer";
import AllStudentsMarks from "../studentEvaluation/allStudentMarks";
import { CheckOutlined } from "@ant-design/icons";
import { CloseOutlined } from "@ant-design/icons";
import { clearGenerateFinaleStudentMarks } from "../../../redux/reducers/teacher/levels/generateFinaleStudentMarksReducer";
import { GenerateFinaleStudentMarks } from "../../../redux/actions/teacher/levels/generateFinaleStudentMarksAction";

const defaultFormData = {
    getTerm: false,
    getYear: false,
    otherEvaluation: true,
    subjectId: undefined,
    ssf_assessment: true,
    sst_assessment: false,
};

const TeachingLevels = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [showStudentMarks, setShowStudentMarks] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [selectedSubLevel, setSelectedSubLevel] = useState();
    const [selectedClass, setSelectedClass] = useState();
    const [selectedYear, setSelectedYear] = useState(undefined);
    const [selectedTerm, setSelectedTerm] = useState(undefined);
    const [selectedStudent, setSelectedStudent] = useState();
    const [onSend, setOnSend] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openDrawer1, setOpenDrawer1] = useState(false);
    const [selectedOption, setSelectedOption] = useState(defaultFormData);
    const [
        generateFinaleStudentMarksAction,
        setGenerateFinaleStudentMarksAction,
    ] = useState(false);

    const {
        retrieveTeachingLevels: {
            retrieveTeachingLevels,
            LoadRetrieveTeachingLevels,
        },
        retrieveStudentMarks: {
            retrieveStudentMarks,
            LoadingRetrieveStudentMarks,
            retrieveStudentMarksError,
        },
        retrieveAllStudentsMarks: {
            retrieveAllStudentsMarks,
            LoadRetrieveAllStudentsMarks,
            retrieveAllStudentsMarksError,
        },
        generateFinaleStudentMarks: {
            generateFinaleStudentMarks,
            LoadGenerateFinaleStudentMarks,
            generateFinaleStudentMarksError,
        },
    } = useSelector(teacherSelector);

    const {
        retrieveSchoolConfiguration: {
            retrieveSchoolConfiguration,
            LoadRetrieveSchoolConfiguration,
        },
    } = useSelector(otherConfigurationSelector);

    const subjects =
        retrieveTeachingLevels?.data?.subjects &&
        retrieveTeachingLevels?.data?.subjects;

    const subjectsData =
        subjects &&
        subjects.map((item, index) => ({
            key: index,
            label: item.subject,
            value: item.id,
        }));

    const onHandleShowStudentMarks = () => setShowStudentMarks(true);
    const onHandleCloseShowStudentMarks = () => {
        setShowStudentMarks(false);
        setSelectedOption(defaultFormData);
        setSelectedTerm(undefined);
        setSelectedYear(undefined);
    };

    const goToStudentEvaluation = () => setOnSend(true);
    const onHandleSelectYear = (value) => setSelectedYear(value);
    const onHandleSelectTerm = (value) => setSelectedTerm(value);

    const onHandleSelectSST = (value) =>
        setSelectedOption((prev) => ({
            ...prev,
            sst_assessment: value,
            ssf_assessment: false,
        }));
    const onHandleSelectSSF = (value) =>
        setSelectedOption((prev) => ({
            ...prev,
            ssf_assessment: value,
            sst_assessment: false,
        }));

    const onHandleSelectSubject = (value) =>
        setSelectedOption((prev) => ({ ...prev, subjectId: value }));

    const onHandleChangeGetTerm = (value) =>
        setSelectedOption((prev) => ({
            ...prev,
            getTerm: value,
            getYear: false,
            otherEvaluation: false,
        }));

    const onHandleChangeGetYear = (value) =>
        setSelectedOption((prev) => ({
            ...prev,
            getTerm: false,
            getYear: value,
            otherEvaluation: false,
        }));

    const onHandleChangeAdditionalEvaluation = (value) =>
        setSelectedOption((prev) => ({
            ...prev,
            getTerm: false,
            getYear: false,
            otherEvaluation: value,
        }));

    const fetchTerm =
        retrieveSchoolConfiguration?.data &&
        retrieveSchoolConfiguration?.data?.studying_terms.map(
            (term, index) => ({
                key: index,
                label: term?.term_name,
                value: term?.id,
            })
        );

    const fetchYear =
        retrieveSchoolConfiguration?.data &&
        retrieveSchoolConfiguration?.data?.studying_years.map(
            (year, index) => ({
                key: index,
                label: year?.year,
                value: year?.id,
            })
        );

    useEffect(() => {
        if (selectedClass && onSend) {
            navigate("/students-evaluation", {
                state: {
                    data: {
                        classes: selectedClass,
                        subjects: subjects,
                    },
                },
            });
        }
    }, [selectedClass, onSend, subjects, navigate]);

    const items = [
        { key: "2", label: "Attendance" },
        {
            key: "1",
            label: (
                <a rel="students evaluation" onClick={goToStudentEvaluation}>
                    Students evaluation
                </a>
            ),
        },
    ];

    const onHandleModalOpen = () => setIsOpen(true);
    const onHandleCloseDrawer = () => {
        setOpenDrawer(false);
        dispatch(clearRetrieveStudentMarks());
    };

    const onHandleCloseDrawer1 = () => {
        setOpenDrawer1(false);
        dispatch(clearRetrieveAllStudentsMarks());
    };

    const onHandleGenerateStudentFinalMarks = (value) =>
        setGenerateFinaleStudentMarksAction(value);

    const onHandleDisplay = () => {
        const formData = {
            year_id: selectedYear || "",
            term_id: selectedTerm || "",
            subject_id: selectedOption.subjectId || "",
            level_id: selectedSubLevel,
            student_code: selectedStudent.studentCode || "",
            get_term: selectedOption.getTerm,
            get_year: selectedOption.getYear,
            other_evaluation: selectedOption.otherEvaluation,
        };

        dispatch(RetrieveStudentMarks(formData));
    };

    const onHandleDisplayAllStudentMarks = () => {
        const formData = {
            year_id: selectedYear || "",
            term_id: selectedTerm || "",
            subject_id: selectedOption.subjectId || "",
            level_id: selectedClass?.subLevelId,
            sst_assessment: selectedOption?.sst_assessment,
            ssf_assessment: selectedOption?.ssf_assessment,
        };

        dispatch(RetrieveAllStudentsMarks(formData));
    };

    const onHandleSaveGenerateStudentFinalMarks = () => {
        const formData = {
            year_id: selectedYear || "",
            term_id: selectedTerm || "",
            subject_id: selectedOption.subjectId || "",
            level_id: selectedClass?.subLevelId,
            sst_assessment: selectedOption?.sst_assessment,
            ssf_assessment: selectedOption?.ssf_assessment,
        };

        dispatch(GenerateFinaleStudentMarks(formData));
    };

    const expandedRowRender = (classesStudents) => {
        const columns = [
            {
                title: "Student code",
                dataIndex: "studentCode",
                key: "studentCode",
            },
            {
                title: "Student",
                dataIndex: "student",
                key: "student",
            },
            {
                title: "Status",
                dataIndex: "state",
                key: "state",
                render: (state) => (
                    <Space>
                        <Badge
                            status={state === true ? "success" : "error"}
                            text={state === true ? "Active" : "Left"}
                        />
                    </Space>
                ),
            },
            {
                title: "Actions",
                dataIndex: "xxx",
                key: "xxxx",
                render: (state, row) => (
                    <Space>
                        <a
                            className="flex-row"
                            onClick={() => {
                                onHandleModalOpen();
                                setSelectedStudent(row);
                                setSelectedSubLevel(
                                    classesStudents?.subLevelId
                                );
                            }}
                        >
                            <CgDetailsMore
                                size={20}
                                style={{ alignSelf: "center" }}
                            />
                            <span style={{ marginLeft: "5px" }}>Marks</span>
                        </a>
                    </Space>
                ),
            },
        ];

        const data =
            classesStudents?.students &&
            classesStudents?.students.map((student, index) => ({
                key: index,
                student: student?.student,
                studentCode: student?.student_code,
                state: student?.is_current_student,
            }));

        return <Table columns={columns} dataSource={data} pagination={false} />;
    };

    const teachingClasses =
        retrieveTeachingLevels?.data?.student_classes &&
        retrieveTeachingLevels?.data?.student_classes.map((level, index) => ({
            key: index,
            levelId: level?.level_id,
            subLevelId: level?.sub_level_id,
            studentNumber: level?.students_number,
            levelName: level?.sub_level_name,
            students: level?.students,
            activeStudent: level?.active_students,
            leftStudent: level?.left_students,
        }));

    const tableColumns = [
        {
            title: "Class",
            dataIndex: "levelName",
            key: "levelName",
            render: (text) => <a>{text}</a>,
        },
        {
            title: "Number of students",
            dataIndex: "studentNumber",
            key: "studentNumber",
        },
        {
            title: "Current Students",
            dataIndex: "activeStudent",
            key: "activeStudent",
        },
        {
            title: "Left students",
            dataIndex: "leftStudent",
            key: "leftStudent",
        },
        {
            title: "Actions",
            dataIndex: "x",
            key: "x",
            render: (_, data, index) => (
                <Space size="middle">
                    <a
                        onClick={() => {
                            onHandleShowStudentMarks();
                            setSelectedClass(data);
                        }}
                    >
                        Students Marks
                    </a>
                    <Dropdown menu={{ items }} trigger={["click"]}>
                        <a
                            onClick={() => setSelectedClass(data)}
                            className="flex-row"
                        >
                            <CgDetailsMore
                                size={20}
                                style={{
                                    alignSelf: "center",
                                    color: "#212529",
                                }}
                            />
                            <span
                                style={{ marginLeft: "5px", color: "#212529" }}
                            >
                                More
                            </span>
                        </a>
                    </Dropdown>
                </Space>
            ),
        },
    ];

    useEffect(() => {
        if (retrieveStudentMarksError) {
            Toast(api, "Error", retrieveStudentMarksError.message);
            dispatch(clearRetrieveStudentMarks());
        }
        if (retrieveAllStudentsMarksError) {
            Toast(api, "Error", retrieveAllStudentsMarksError.message);
            dispatch(clearRetrieveAllStudentsMarks());
        }
        if (generateFinaleStudentMarksError) {
            Toast(api, "Error", generateFinaleStudentMarksError.message);
            dispatch(clearGenerateFinaleStudentMarks());
        }
        if (generateFinaleStudentMarks) {
            Toast(api, "Success", generateFinaleStudentMarks.message);
            setGenerateFinaleStudentMarksAction(false);
            onHandleCloseShowStudentMarks();
            dispatch(clearGenerateFinaleStudentMarks());
        }
        if (retrieveAllStudentsMarks) {
            onHandleCloseShowStudentMarks();
            setOpenDrawer1(true);
        }
        if (
            retrieveStudentMarks &&
            retrieveStudentMarks?.message !== "Success"
        ) {
            Toast(api, "Success", retrieveStudentMarks.message);
        }
        if (retrieveStudentMarks) {
            setOpenDrawer(true);
            setIsOpen(false);
            setSelectedSubLevel(undefined);
            setSelectedTerm(undefined);
            setSelectedYear(undefined);
            setSelectedOption(defaultFormData);
        }
    }, [
        api,
        dispatch,
        generateFinaleStudentMarks,
        generateFinaleStudentMarksError,
        retrieveAllStudentsMarks,
        retrieveAllStudentsMarksError,
        retrieveStudentMarks,
        retrieveStudentMarksError,
    ]);

    useEffect(() => {
        dispatch(RetrieveTeachingLevels());
    }, [dispatch]);

    return (
        <div className="item-container-box">
            {contextHolder}
            <BottomContainerItem>
                <Table
                    size="middle"
                    className="width-100-percent"
                    columns={tableColumns}
                    loading={LoadRetrieveTeachingLevels}
                    dataSource={teachingClasses}
                    pagination={
                        teachingClasses && teachingClasses.length > 4
                            ? { pageSize: 4 }
                            : false
                    }
                    locale={{
                        emptyText: (
                            <Empty description="You don't have any class yet" />
                        ),
                    }}
                    expandable={{
                        expandedRowRender: (row) => expandedRowRender(row),
                        defaultExpandedRowKeys: ["0"],
                    }}
                />
            </BottomContainerItem>
            <Modal
                title={selectedStudent?.student}
                open={isOpen}
                closeIcon={false}
                footer={[
                    <div className="flex-row-sb" key={"display-marks"}>
                        <Button
                            danger
                            key="cancel"
                            onClick={() => {
                                setIsOpen(false);
                                setSelectedSubLevel(undefined);
                                setSelectedTerm(undefined);
                                setSelectedYear(undefined);
                                setSelectedOption(defaultFormData);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            ghost
                            type="primary"
                            onClick={onHandleDisplay}
                            loading={LoadingRetrieveStudentMarks}
                        >
                            Display
                        </Button>
                    </div>,
                ]}
            >
                <div className="flex-column margin-top-20">
                    <div className="margin-top-20 flex-row-sb">
                        <span className="bold-text">Student code</span>
                        <span className="bold-text">
                            {selectedStudent?.studentCode}
                        </span>
                    </div>

                    <Divider />

                    <div className="small-marin-bottom">
                        <span>Select subject</span>
                        <Select
                            placeholder={"Select subject"}
                            style={{
                                width: "100%",
                                textAlign: "left",
                            }}
                            options={subjectsData}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                (option?.label?.toLowerCase() ?? "").includes(
                                    input.toLowerCase()
                                )
                            }
                            showSearch
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? "")
                                    .toLowerCase()
                                    .localeCompare(
                                        (optionB?.label ?? "").toLowerCase()
                                    )
                            }
                            value={selectedOption.subjectId}
                            onChange={onHandleSelectSubject}
                        />
                    </div>

                    <div className="flex-column">
                        <div className="small-marin-bottom">
                            <span>Select Study Year</span>
                            <Select
                                style={{ width: "100%" }}
                                loading={LoadRetrieveSchoolConfiguration}
                                options={fetchYear}
                                onChange={onHandleSelectYear}
                                value={selectedYear}
                                placeholder={"Select year"}
                            />
                        </div>

                        <div className="small-marin-bottom">
                            <span>Select Study Term</span>
                            <Select
                                style={{ width: "100%" }}
                                loading={LoadRetrieveSchoolConfiguration}
                                options={fetchTerm}
                                onChange={onHandleSelectTerm}
                                value={selectedTerm}
                                placeholder={"Select term"}
                            />
                        </div>
                    </div>

                    <div className=" margin-top-20 flex-row-sb">
                        <span>Retrieve student year marks</span>
                        <Switch
                            checked={selectedOption?.getYear}
                            onChange={onHandleChangeGetYear}
                        />
                    </div>
                    <div className="margin-top-20 flex-row-sb">
                        <span>Retrieve student term marks</span>
                        <Switch
                            checked={selectedOption?.getTerm}
                            onChange={onHandleChangeGetTerm}
                        />
                    </div>
                    <div className=" margin-top-20 flex-row-sb">
                        <span>Retrieve additional student marks</span>
                        <Switch
                            checked={selectedOption?.otherEvaluation}
                            onChange={onHandleChangeAdditionalEvaluation}
                        />
                    </div>

                    <CustomSeparator />
                </div>
            </Modal>

            <Modal
                title={selectedClass?.levelName}
                open={showStudentMarks}
                closeIcon={false}
                footer={[
                    <div className="flex-row-sb" key={"display-marks"}>
                        <Button
                            danger
                            key="cancel"
                            onClick={() => {
                                onHandleCloseShowStudentMarks();
                            }}
                        >
                            Cancel
                        </Button>
                        {!generateFinaleStudentMarksAction ? (
                            <Button
                                ghost
                                type="primary"
                                onClick={onHandleDisplayAllStudentMarks}
                                loading={LoadRetrieveAllStudentsMarks}
                            >
                                Display
                            </Button>
                        ) : (
                            <Button
                                ghost
                                type="primary"
                                onClick={onHandleSaveGenerateStudentFinalMarks}
                                loading={LoadGenerateFinaleStudentMarks}
                            >
                                Execute the calculation
                            </Button>
                        )}
                    </div>,
                ]}
            >
                <div className="small-marin-bottom">
                    <span>Select subject</span>
                    <Select
                        placeholder={"Select subject"}
                        style={{
                            width: "100%",
                            textAlign: "left",
                        }}
                        options={subjectsData}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            (option?.label?.toLowerCase() ?? "").includes(
                                input.toLowerCase()
                            )
                        }
                        showSearch
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                                .toLowerCase()
                                .localeCompare(
                                    (optionB?.label ?? "").toLowerCase()
                                )
                        }
                        value={selectedOption.subjectId}
                        onChange={onHandleSelectSubject}
                    />
                </div>

                <div className="flex-column">
                    <div className="small-marin-bottom">
                        <span>Select Study Year</span>
                        <Select
                            style={{ width: "100%" }}
                            loading={LoadRetrieveSchoolConfiguration}
                            options={fetchYear}
                            onChange={onHandleSelectYear}
                            value={selectedYear}
                            placeholder={"Select year"}
                        />
                    </div>

                    <div className="small-marin-bottom">
                        <span>Select Study Term</span>
                        <Select
                            style={{ width: "100%" }}
                            loading={LoadRetrieveSchoolConfiguration}
                            options={fetchTerm}
                            onChange={onHandleSelectTerm}
                            value={selectedTerm}
                            placeholder={"Select term"}
                        />
                    </div>
                </div>
                <div className="margin-top-20 flex-row-sb">
                    <span>Display student progress scores</span>
                    <Switch
                        checked={selectedOption?.ssf_assessment}
                        onChange={onHandleSelectSSF}
                    />
                </div>
                <div className=" margin-top-20 flex-row-sb">
                    <span>Display student summative assessment scores</span>
                    <Switch
                        checked={selectedOption?.sst_assessment}
                        onChange={onHandleSelectSST}
                    />
                </div>
                <div className=" margin-top-20 flex-row-sb">
                    <span className="deep-green-text bold-text">
                        Generate students' final scores
                    </span>
                    <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        style={{ background: "#0a6c74" }}
                        checked={generateFinaleStudentMarksAction}
                        onChange={onHandleGenerateStudentFinalMarks}
                    />
                </div>

                <CustomSeparator />
            </Modal>

            <StudentMarks
                open={openDrawer}
                onClose={onHandleCloseDrawer}
                setOpenDrawer={setOpenDrawer}
                student={selectedStudent}
                retrieveStudentMarks={retrieveStudentMarks}
            />

            <AllStudentsMarks
                open={openDrawer1}
                onClose={onHandleCloseDrawer1}
                setOpenDrawer={setOpenDrawer1}
                levelName={selectedClass?.levelName}
                retrieveStudentMarks={retrieveAllStudentsMarks}
            />
        </div>
    );
};

export default TeachingLevels;
