import React from "react";

const LocalTab = ({ index, item }) => {
    return (
        <div key={index} className="item-dashboard-card-box">
            <div className="item-dashboard-card-box__row">
                <span className="item-dashboard-card-title">
                    {item?.title1}:
                    <span
                        style={{
                            color: "black",
                            marginLeft: "20px",
                        }}
                    >
                        {item?.title1Value}
                    </span>
                </span>
            </div>
            <div className="item-dashboard-card-box__row-top-10">
                <span className="item-dashboard-card-title">
                    {item?.title2}:
                    <span
                        style={{
                            color: "black",
                            marginLeft: "20px",
                        }}
                    >
                        {item?.title2Value}
                    </span>
                </span>
            </div>
        </div>
    );
};

export default LocalTab;
