import { createSlice } from "@reduxjs/toolkit";
import { CurrentUserData } from "../../actions/auth/currentUserDataActions";

const initialState = {
  currentUserData: null,
  loading: false,
  error: null,
};

const currentUserDataSlice = createSlice({
  name: "currentUserData",
  initialState: initialState,
  reducers: {
    clearCurrentUserData: (state) => {
      state.currentUserData = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(CurrentUserData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(CurrentUserData.fulfilled, (state, action) => {
        state.loading = false;
        state.currentUserData = action.payload;
      })
      .addCase(CurrentUserData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearCurrentUserDataData } = currentUserDataSlice.actions;

export default currentUserDataSlice.reducer;
