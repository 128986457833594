import { createSlice } from "@reduxjs/toolkit";
import { DeleteFeeType } from "../../../../actions/school/finance/manageFees/deleteFeeTypeAction";

const initialState = {
  deleteFeeType: null,
  LoadDeleteFeeType: false,
  deleteFeeTypeError: null,
};

const deleteFeeTypeSlice = createSlice({
  name: "deleteFeeType",
  initialState: initialState,
  reducers: {
    clearDeleteFeeType: (state) => {
      state.deleteFeeType = null;
      state.deleteFeeTypeError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(DeleteFeeType.pending, (state) => {
        state.LoadDeleteFeeType = true;
        state.deleteFeeTypeError = null;
      })
      .addCase(DeleteFeeType.fulfilled, (state, action) => {
        state.LoadDeleteFeeType = false;
        state.deleteFeeType = action.payload;
      })
      .addCase(DeleteFeeType.rejected, (state, action) => {
        state.LoadDeleteFeeType = false;
        state.deleteFeeTypeError = action.payload;
      });
  },
});

export const { clearDeleteFeeType } = deleteFeeTypeSlice.actions;

export default deleteFeeTypeSlice.reducer;
