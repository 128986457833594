import { createSlice } from "@reduxjs/toolkit";
import { RetrieveBorrowingTeachers } from "../../../actions/school/library/retrieveBorrowingTeachersAction";

const initialState = {
    retrieveBorrowingTeachers: null,
    loadingRetrieveBorrowingTeachers: false,
    retrieveBorrowingTeachersError: null,
};

const retrieveBorrowingTeachersSlice = createSlice({
    name: "retrieveBorrowingTeachers",
    initialState: initialState,
    reducers: {
        clearRetrieveBorrowingTeachers: (state) => {
            state.retrieveBorrowingTeachers = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(RetrieveBorrowingTeachers.pending, (state) => {
                state.loadingRetrieveBorrowingTeachers = true;
                state.retrieveBorrowingTeachersError = null;
            })
            .addCase(RetrieveBorrowingTeachers.fulfilled, (state, action) => {
                state.loadingRetrieveBorrowingTeachers = false;
                state.retrieveBorrowingTeachers = action.payload;
            })
            .addCase(RetrieveBorrowingTeachers.rejected, (state, action) => {
                state.loadingRetrieveBorrowingTeachers = false;
                state.retrieveBorrowingTeachersError = action.payload;
            });
    },
});

export const { clearRetrieveBorrowingTeachers } =
    retrieveBorrowingTeachersSlice.actions;

export default retrieveBorrowingTeachersSlice.reducer;
