const endPoints = {
    login: "user/login",
    signUp: "user/signup",
    logout: "user/logout",
    resetPassword: "user/re-set-password",
    userData: "user/user-data",
    fetchClassLevels: "level/retrieve-facility-classes",
    addClassToFacility: "level/add-class-to-facility",
    addClassOptionLevels: "level/add-class-option-levels",
    fetchClassOptionLevels: "level/retrieve-class-options",
    updateClassOptionLevel: "level/update-class-option-levels",
    fetchRetrieveDefaultData: "user/admin/retrieve-default-data",
    manageCurrencies: "user/admin/supported-currencies",
    fetchDefaultCurrenciesData: "user/admin/retrieve-supported-currencies",
    fetchPaymentMethodData: "user/admin/retrieve-supported-payments",
    managePaymentMethod: "user/admin/supported-payments",
    retrieveClassLevels: "level/retrieve-class-levels",
    retrieveFacilityStudents: "user/admin/retrieve-student",
    registerStudent: "user/admin/register-student",
    updateStudentInfo: "user/admin/update-student",
    fetchManageFeeType: "user/finance/retrieve-facility-fee-types",
    updateFeeType: "user/finance/update-fee-types",
    addFeeType: "user/finance/add-fee-types",
    deleteFeeType: "user/finance/delete-fee-type",
    fetchCashReport: "user/report/cash-report",
    fetchStudentPayment: "user/report/student-payment-report",
    recordStudentPayment: "user/payment/student-payment",
    fetchStudentPaymentDetails: "user/payment/student-payment-details",
    fetchEmployeeList: "user/employee/retrieve-employee",
    updateEmployeeProfile: "user/employee/update-employee",
    registerNewEmployee: "user/employee/register-employee",
    retrieveEmployeeSalary: "user/employee/retrieve-employee-salary",
    setEmployeeCompensation: "user/employee/set-employee-salary",
    fetchEmployeePaymentBalance: "user/payment/employee-balance-payment",
    fetchFacilityUsers: "user/facility-users",
    updateUserStatus: "user/update-facility-users",
    fetchPendingPayment: "user/payment/pending-payment",
    saveEmployeePayment: "user/payment/employee-payment",
    updatePaymentRequest: "user/payment/update-payment-request",
    retrieveCourses: "admin/retrieve-modules",
    retrieveFacilityCourses: "admin/retrieve-facility-modules",
    recordNewCourse: "admin/set-facility-modules",
    updateCourse: "admin/update-facility-modules",
    retrieveFacilityTeachers: "admin/retrieve-facility-teachers",
    addTeacher: "admin/add-teacher",
    filterTeacherCourses: "admin/filter-teacher-classes",
    retrieveStudentClasses: "admin/retrieve-classes-students",
    fetchGlobalSummary: "summary/global-summary",
    addSchedule: "admin/set-teacher-schedule",
    retrieveSubLevel: "level/retrieve-facility-sub-classes",
    addNewSubLevel: "level/add-sub-levels",
    updateSubLevel: "level/update-sub-levels",
    retrieveFacilitySubLevels: "level/retrieve-facility-sub-classes",
    retrieveSchoolTeacherSchedule: "admin/retrieve-school-teacher-schedule",
    retrieveSummaryOnTeachers: "admin/retrieve-summary-on-teachers",
    retrieveClassTimeManagement: "admin/retrieve-class-time-management",
    retrieveTeacherSummaryInformation:
        "admin/retrieve-teacher-summary-information",
    setClassTimeManagement: "admin/class-time-management",
    retrieveEmployeeWithSalary: "user/employee/retrieve-employee-salary",
    retrieveEmployeePaymentFile: "user/payment/retrieve-employee-payment-file",
    employeeBulkPayment: "user/payment/employee-bulk-payment",
    retrieveSchoolConfiguration: "user/admin/retrieve-school-configuration",
    retrieveTeachingLevels: "admin/retrieve-teacher-classes",
    studentCourseMarks: "admin/student-course-marks",
    retrieveAssessmentFile: "admin/retrieve-assessment-files",
    generateAssessmentFile: "admin/generate-assessment-file",
    recordStudentScores: "admin/record-student-scores",
    retrieveStudentMarksDetails: "admin/retrieve-student-marks-details",
    retrieveStudentMarks: "admin/retrieve-student-marks",
    retrieveSchoolClasses: "user/admin/retrieve-school-classes",
    retrieveClassesStudents: "user/admin/retrieve-classes-students",
    retrieveStudentCoursesMarks: "user/admin/student-courses-marks",
    executeEmployeeBulkPayment: "user/payment/execute-employee-bulk-payment",
    schoolConfiguration: "user/admin/school-configuration",
    recordNewBook: "library/record-new-book",
    retrieveRecordedBooks: "library/retrieve-all-books",
    borrowBooks: "library/borrow-books",
    retrieveBorrowingStudents: "library/retrieve-borrowing-students",
    retrieveBorrowingTeachers: "library/retrieve-borrowing-teachers",
    createFormativeAssessmentFile: "admin/create-formative-assessment-file",
    retrieveAllStudentsMarks: "admin/retrieve-finale-student-marks",
    generateFinaleStudentMarks: "admin/generate-finale-student-marks",
    retrieveEducationSystem: "user/admin/retrieve-education-system",
    changeLanguage: "user/change-language",
    language: "user/language",
};

export default endPoints;
