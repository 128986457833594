import { createSlice } from "@reduxjs/toolkit";
import { AddClassToFacility } from "../../../actions/school/levels/addClassToFacilityActions";

const initialState = {
  addClassToFacilityData: null,
  LoadAddClassToFacility: false,
  addClassToFacilityDataError: null,
};

const addClassToFacilitySlice = createSlice({
  name: "addClassToFacility",
  initialState: initialState,
  reducers: {
    clearAddClassToFacility: (state) => {
      state.addClassToFacilityData = null;
      state.addClassToFacilityDataError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(AddClassToFacility.pending, (state) => {
        state.LoadAddClassToFacility = true;
        state.addClassToFacilityDataError = null;
      })
      .addCase(AddClassToFacility.fulfilled, (state, action) => {
        state.LoadAddClassToFacility = false;
        state.addClassToFacilityData = action.payload;
      })
      .addCase(AddClassToFacility.rejected, (state, action) => {
        state.LoadAddClassToFacility = false;
        state.addClassToFacilityDataError = action.payload;
      });
  },
});

export const { clearAddClassToFacility } = addClassToFacilitySlice.actions;

export default addClassToFacilitySlice.reducer;
