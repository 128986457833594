import React from "react";
import "./index.css";

const CustomSeparator = ({ size = 16 }) => {
  return (
    <div className="custom-separator" style={{ padding: `${size}px` }}></div>
  );
};

export default CustomSeparator;
