import { createSlice } from "@reduxjs/toolkit";
import { UpdateUserStatus } from "../../../actions/school/users/updateUserStatusActions";

const initialState = {
  updateUserStatus: null,
  LoadUpdateUserStatus: false,
  updateUserStatusError: null,
};

const updateUserStatusSlice = createSlice({
  name: "updateUserStatus",
  initialState: initialState,
  reducers: {
    clearUpdateUserStatus: (state) => {
      state.updateUserStatus = null;
      state.updateUserStatusError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(UpdateUserStatus.pending, (state) => {
        state.LoadUpdateUserStatus = true;
        state.updateUserStatusError = null;
      })
      .addCase(UpdateUserStatus.fulfilled, (state, action) => {
        state.LoadUpdateUserStatus = false;
        state.updateUserStatus = action.payload;
      })
      .addCase(UpdateUserStatus.rejected, (state, action) => {
        state.LoadUpdateUserStatus = false;
        state.updateUserStatusError = action.payload;
      });
  },
});

export const { clearUpdateUserStatus } = updateUserStatusSlice.actions;

export default updateUserStatusSlice.reducer;
