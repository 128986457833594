import React, { useEffect, useState } from "react";
import { Modal, Button, Select } from "antd";
import InputForm from "../../../../components/inputForm";
import CustomSeparator from "../../../../components/common/customrSeparator";
import Toast from "../../../../components/common/toast";
import { clearGenerateAssessmentFile } from "../../../../redux/reducers/teacher/levels/generateAssessmentFileReducer";
import { useDispatch } from "react-redux";
import { RetrieveAssessmentFile } from "../../../../redux/actions/teacher/levels/retrieveAssessmentFileAction";
import { GenerateAssessmentFile } from "../../../../redux/actions/teacher/levels/generateAssessmentFileAction";

const defaultData = {
    fileName: "",
    assessmentType: undefined,
    totalMarks: "",
    subjectId: undefined,
    yearId: "",
    termId: "",
    class: "",
};

export const retrieveAssessmentFileData = {
    assessment_file_id: "",
    formative_assessment: false,
};

const CreateAssessmentFolderModal = ({
    api,
    open,
    onClose,
    loading,
    subjects,
    yearId,
    termId,
    subLevelId,
    generateAssessmentFile,
    generateAssessmentFileError,
}) => {
    const dispatch = useDispatch();
    const [form, setForm] = useState(defaultData);
    const onHandleSubmit = () => {
        const formData = {
            file_name: form.fileName,
            subject_id: form.subjectId || "",
            year_id: yearId,
            term_id: termId,
            class: subLevelId,
        };
        dispatch(GenerateAssessmentFile(formData));
    };

    const onHandleChangeInput = (event) => {
        const { name, value } = event.target;
        const formData = { ...form };
        formData[name] = value;
        setForm(formData);
    };

    const onHandleSelectSubject = (value) =>
        setForm((prev) => ({ ...prev, subjectId: value }));

    useEffect(() => {
        const payload = {
            assessment_file_id: "",
            formative_assessment: false,
            level_id: subLevelId,
        };
        if (generateAssessmentFileError) {
            Toast(api, "Error", generateAssessmentFileError.message);
            dispatch(clearGenerateAssessmentFile());
        }

        if (generateAssessmentFile) {
            Toast(api, "Success", generateAssessmentFile.message);
            dispatch(clearGenerateAssessmentFile());
            setForm(defaultData);
            onClose();
            dispatch(RetrieveAssessmentFile(payload));
        }
    }, [
        api,
        dispatch,
        generateAssessmentFile,
        generateAssessmentFileError,
        onClose,
        subLevelId,
    ]);

    return (
        <Modal
            title={"Create new assessment folder"}
            open={open}
            closeIcon={false}
            footer={[
                <div className="flex-row-sb" key={"gen-new-assessment-file"}>
                    <Button danger key="cancel" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        ghost
                        type="primary"
                        loading={loading}
                        onClick={onHandleSubmit}
                    >
                        Proceed
                    </Button>
                </div>,
            ]}
        >
            <div className="flex-column margin-top-20">
                <div className="flex-colum margin-top-10">
                    <span className="align-text-left margin-bottom-10">
                        Select subject
                    </span>
                    <Select
                        placeholder={"Select subject"}
                        style={{
                            width: "100%",
                            textAlign: "left",
                        }}
                        options={subjects}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            (option?.label?.toLowerCase() ?? "").includes(
                                input.toLowerCase()
                            )
                        }
                        showSearch
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                                .toLowerCase()
                                .localeCompare(
                                    (optionB?.label ?? "").toLowerCase()
                                )
                        }
                        value={form.subjectId}
                        onChange={onHandleSelectSubject}
                    />
                </div>
                <InputForm
                    type={"text"}
                    label={"Folder name"}
                    name="fileName"
                    placeholder={"Enter folder name"}
                    value={form.fileName}
                    onChange={onHandleChangeInput}
                />

                <CustomSeparator />
            </div>
        </Modal>
    );
};

export default CreateAssessmentFolderModal;
