import { createSlice } from "@reduxjs/toolkit";
import { RetrieveSchoolConfiguration } from "../../../actions/school/otherConfiguration/retrievSchoolConfigurationActions";

const initialState = {
    retrieveSchoolConfiguration: null,
    LoadRetrieveSchoolConfiguration: false,
    retrieveSchoolConfigurationError: null,
};

const retrieveSchoolConfigurationSlice = createSlice({
    name: "retrieveSchoolConfiguration",
    initialState: initialState,
    reducers: {
        clearRetrieveSchoolConfiguration: (state) => {
            state.retrieveSchoolConfiguration = null;
            state.retrieveSchoolConfigurationError = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(RetrieveSchoolConfiguration.pending, (state) => {
                state.LoadRetrieveSchoolConfiguration = true;
                state.retrieveSchoolConfigurationError = null;
            })
            .addCase(RetrieveSchoolConfiguration.fulfilled, (state, action) => {
                state.LoadRetrieveSchoolConfiguration = false;
                state.retrieveSchoolConfiguration = action.payload;
            })
            .addCase(RetrieveSchoolConfiguration.rejected, (state, action) => {
                state.LoadRetrieveSchoolConfiguration = false;
                state.retrieveSchoolConfigurationError = action.payload;
            });
    },
});

export const { clearRetrieveSchoolConfiguration } =
    retrieveSchoolConfigurationSlice.actions;

export default retrieveSchoolConfigurationSlice.reducer;
