import { createSlice } from "@reduxjs/toolkit";
import { RetrieveRecordedBooks } from "../../../actions/school/library/retrieveRecordedBooksAction";

const initialState = {
    retrieveRecordedBooks: null,
    loadingRetrieveRecordedBooks: false,
    retrieveRecordedBooksError: null,
};

const retrieveRecordedBooksSlice = createSlice({
    name: "retrieveRecordedBooks",
    initialState: initialState,
    reducers: {
        clearRetrieveRecordedBook: (state) => {
            state.retrieveRecordedBooks = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(RetrieveRecordedBooks.pending, (state) => {
                state.loadingRetrieveRecordedBooks = true;
                state.retrieveRecordedBooksError = null;
            })
            .addCase(RetrieveRecordedBooks.fulfilled, (state, action) => {
                state.loadingRetrieveRecordedBooks = false;
                state.retrieveRecordedBooks = action.payload;
            })
            .addCase(RetrieveRecordedBooks.rejected, (state, action) => {
                state.loadingRetrieveRecordedBooks = false;
                state.retrieveRecordedBooksError = action.payload;
            });
    },
});

export const { clearRetrieveRecordedBook } = retrieveRecordedBooksSlice.actions;

export default retrieveRecordedBooksSlice.reducer;
