import { createSlice } from "@reduxjs/toolkit";
import { RetrieveSubLevels } from "../../../actions/school/levels/retrieveSubLevelActions";

const initialState = {
    retrieveSubLevels: null,
    loadingRetrieveSubLevel: false,
    retrieveSubLevelError: null,
};

const retrieveSubLevelsSlice = createSlice({
    name: "retrieveSubLevels",
    initialState: initialState,
    reducers: {
        clearRetrieveSubLevels: (state) => {
            state.retrieveSubLevels = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(RetrieveSubLevels.pending, (state) => {
                state.loadingRetrieveSubLevel = true;
                state.retrieveSubLevelError = null;
            })
            .addCase(RetrieveSubLevels.fulfilled, (state, action) => {
                state.loadingRetrieveSubLevel = false;
                state.retrieveSubLevels = action.payload;
            })
            .addCase(RetrieveSubLevels.rejected, (state, action) => {
                state.loadingRetrieveSubLevel = false;
                state.retrieveSubLevelError = action.payload;
            });
    },
});

export const { clearRetrieveSubLevels } = retrieveSubLevelsSlice.actions;

export default retrieveSubLevelsSlice.reducer;
