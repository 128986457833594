import { createSlice } from "@reduxjs/toolkit";
import { RetrieveSummaryOnTeachers } from "../../../actions/school/teachers/retrieveSummaryOnTeachersActions";

const initialState = {
    retrieveSummaryOnTeachers: null,
    LoadRetrieveSummaryOnTeachers: false,
    retrieveSummaryOnTeachersError: null,
};

const retrieveSummaryOnTeachersSlice = createSlice({
    name: "retrieveSummaryOnTeachers",
    initialState: initialState,
    reducers: {
        clearRetrieveSummaryOnTeachers: (state) => {
            state.retrieveSummaryOnTeachers = null;
            state.retrieveSummaryOnTeachersError = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(RetrieveSummaryOnTeachers.pending, (state) => {
                state.LoadRetrieveSummaryOnTeachers = true;
                state.retrieveSummaryOnTeachersError = null;
            })
            .addCase(RetrieveSummaryOnTeachers.fulfilled, (state, action) => {
                state.LoadRetrieveSummaryOnTeachers = false;
                state.retrieveSummaryOnTeachers = action.payload;
            })
            .addCase(RetrieveSummaryOnTeachers.rejected, (state, action) => {
                state.LoadRetrieveSummaryOnTeachers = false;
                state.retrieveSummaryOnTeachersError = action.payload;
            });
    },
});

export const { clearRetrieveSummaryOnTeachers } =
    retrieveSummaryOnTeachersSlice.actions;

export default retrieveSummaryOnTeachersSlice.reducer;
