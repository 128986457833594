import React from "react";
import "./style.css";

const HorizontalScrollableDiv = ({ children }) => {
    return (
        <div className="horizontal-scrollable-container">
            <div className="scrollable-content">{children}</div>
        </div>
    );
};

export default HorizontalScrollableDiv;
