import { Input } from "antd";
import React from "react";

const InputForm = ({
    label,
    placeholder,
    value,
    onChange,
    type,
    name,
    disabled = false,
    style = {},
}) => {
    return (
        <div className="form-input-content">
            <span className="input-label">{label}</span>
            <div className="input-field">
                <Input
                    disabled={disabled}
                    type={type}
                    value={value}
                    placeholder={placeholder}
                    name={name}
                    onChange={onChange}
                    size="large"
                    style={style}
                />
            </div>
        </div>
    );
};

export default InputForm;
