import { Modal } from "antd";
import React from "react";
import InputForm from "../../../../components/inputForm";
import SchoolSystem from "./SchoolSystem";
import LevelOrganized from "./LevelOrganized";
import EductionSystem from "./EducationSystem";

const SchoolRegistration = ({ open, setOpen }) => {
    return (
        <Modal open={open} onCancel={() => setOpen(false)} footer={[]}>
            <InputForm
                type={"text"}
                label={"School name"}
                placeholder={"Enter your school name"}
            />
            <InputForm
                type={"text"}
                label={"School email"}
                placeholder={"Enter your school email"}
            />

            <InputForm
                type={"text"}
                label={"School Phone number"}
                placeholder={"Enter your school Phone number"}
            />

            <div className="form-input-content">
                <span className="input-label">
                    Select an eduction system you use
                </span>
                <div className="flex-row-sb">
                    <EductionSystem />
                </div>
            </div>

            <div className="form-input-content">
                <span className="input-label">Options</span>
                <div className="flex-row-sb">
                    <SchoolSystem />
                    <LevelOrganized />
                </div>
            </div>
        </Modal>
    );
};

export default SchoolRegistration;
