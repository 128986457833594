import { createSlice } from "@reduxjs/toolkit";
import { UpdateStudentInfo } from "../../../actions/school/students/updateStudentActions";

const initialState = {
  updateStudentInfo: null,
  LoadUpdateStudentInfo: false,
  updateStudentInfoError: null,
};

const updateStudentInfoSlice = createSlice({
  name: "updateStudentInfo",
  initialState: initialState,
  reducers: {
    clearUpdateStudentInfo: (state) => {
      state.updateStudentInfo = null;
      state.updateStudentInfoError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(UpdateStudentInfo.pending, (state) => {
        state.LoadUpdateStudentInfo = true;
        state.updateStudentInfoError = null;
      })
      .addCase(UpdateStudentInfo.fulfilled, (state, action) => {
        state.LoadUpdateStudentInfo = false;
        state.updateStudentInfo = action.payload;
      })
      .addCase(UpdateStudentInfo.rejected, (state, action) => {
        state.LoadUpdateStudentInfo = false;
        state.updateStudentInfoError = action.payload;
      });
  },
});

export const { clearUpdateStudentInfo } = updateStudentInfoSlice.actions;

export default updateStudentInfoSlice.reducer;
