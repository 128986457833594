import { createSlice } from "@reduxjs/toolkit";
import { ManageCurrenciesData } from "../../../actions/school/otherConfiguration/manageCurrenciesActions";

const initialState = {
  manageCurrenciesData: null,
  LoadManageCurrenciesData: false,
  manageCurrenciesDataError: null,
};

const manageCurrenciesDataSlice = createSlice({
  name: "manageCurrenciesData",
  initialState: initialState,
  reducers: {
    clearManageCurrenciesData: (state) => {
      state.manageCurrenciesData = null;
      state.manageCurrenciesDataError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(ManageCurrenciesData.pending, (state) => {
        state.LoadManageCurrenciesData = true;
        state.manageCurrenciesDataError = null;
      })
      .addCase(ManageCurrenciesData.fulfilled, (state, action) => {
        state.LoadManageCurrenciesData = false;
        state.manageCurrenciesData = action.payload;
      })
      .addCase(ManageCurrenciesData.rejected, (state, action) => {
        state.LoadManageCurrenciesData = false;
        state.manageCurrenciesDataError = action.payload;
      });
  },
});

export const { clearManageCurrenciesData } = manageCurrenciesDataSlice.actions;

export default manageCurrenciesDataSlice.reducer;
