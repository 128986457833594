import { createSlice } from "@reduxjs/toolkit";
import { ExecuteEmployeeBulkPayment } from "../../../actions/school/employee/executeEmployeeBulkPaymentAction";

const initialState = {
    executeEmployeeBulkPayment: null,
    LoadExecuteEmployeeBulkPayment: false,
    executeEmployeeBulkPaymentError: null,
};

const executeEmployeeBulkPaymentSlice = createSlice({
    name: "executeEmployeeBulkPayment",
    initialState: initialState,
    reducers: {
        clearExecuteEmployeeBulkPayment: (state) => {
            state.executeEmployeeBulkPayment = null;
            state.executeEmployeeBulkPaymentError = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(ExecuteEmployeeBulkPayment.pending, (state) => {
                state.LoadExecuteEmployeeBulkPayment = true;
                state.executeEmployeeBulkPaymentError = null;
            })
            .addCase(ExecuteEmployeeBulkPayment.fulfilled, (state, action) => {
                state.LoadExecuteEmployeeBulkPayment = false;
                state.executeEmployeeBulkPayment = action.payload;
            })
            .addCase(ExecuteEmployeeBulkPayment.rejected, (state, action) => {
                state.LoadExecuteEmployeeBulkPayment = false;
                state.executeEmployeeBulkPaymentError = action.payload;
            });
    },
});

export const { clearExecuteEmployeeBulkPayment } =
    executeEmployeeBulkPaymentSlice.actions;

export default executeEmployeeBulkPaymentSlice.reducer;
