import { createSlice } from "@reduxjs/toolkit";
import { RetrieveEmployeePaymentFile } from "../../../actions/school/employee/retrieveEmployeePaymentFileActions";

const initialState = {
    retrieveEmployeePaymentFile: null,
    LoadRetrieveEmployeePaymentFile: false,
    retrieveEmployeePaymentFileError: null,
};

const retrieveEmployeePaymentFileSlice = createSlice({
    name: "retrieveEmployeePaymentFile",
    initialState: initialState,
    reducers: {
        clearRetrieveEmployeePaymentFile: (state) => {
            state.retrieveEmployeePaymentFile = null;
            state.retrieveEmployeePaymentFileError = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(RetrieveEmployeePaymentFile.pending, (state) => {
                state.LoadRetrieveEmployeePaymentFile = true;
                state.retrieveEmployeePaymentFileError = null;
            })
            .addCase(RetrieveEmployeePaymentFile.fulfilled, (state, action) => {
                state.LoadRetrieveEmployeePaymentFile = false;
                state.retrieveEmployeePaymentFile = action.payload;
            })
            .addCase(RetrieveEmployeePaymentFile.rejected, (state, action) => {
                state.LoadRetrieveEmployeePaymentFile = false;
                state.retrieveEmployeePaymentFileError = action.payload;
            });
    },
});

export const { clearRetrieveEmployeePaymentFile } =
    retrieveEmployeePaymentFileSlice.actions;

export default retrieveEmployeePaymentFileSlice.reducer;
