import { createSlice } from "@reduxjs/toolkit";
import { RetrieveCourses } from "../../../actions/school/teachers/retrieveCourseActions";

const initialState = {
    retrieveCourse: null,
    LoadRetrieveCourse: false,
    retrieveCourseError: null,
};

const retrieveCourseSlice = createSlice({
    name: "retrieveCourse",
    initialState: initialState,
    reducers: {
        clearRetrieveCourse: (state) => {
            state.retrieveCourse = null;
            state.retrieveCourseError = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(RetrieveCourses.pending, (state) => {
                state.LoadRetrieveCourse = true;
                state.retrieveCourseError = null;
            })
            .addCase(RetrieveCourses.fulfilled, (state, action) => {
                state.LoadRetrieveCourse = false;
                state.retrieveCourse = action.payload;
            })
            .addCase(RetrieveCourses.rejected, (state, action) => {
                state.LoadRetrieveCourse = false;
                state.retrieveCourseError = action.payload;
            });
    },
});

export const { clearRetrieveCourse } = retrieveCourseSlice.actions;

export default retrieveCourseSlice.reducer;
