import React, { useEffect, useState } from "react";
import AuthContainer from "../../../components/common/authContainer";
import InputForm from "../../../components/inputForm";
import { Button, notification } from "antd";
import CustomSeparator from "../../../components/common/customrSeparator";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Toast from "../../../components/common/toast";
import { clearSignUp } from "../../../redux/reducers/auth/signUpReducer";
import { SignUp } from "../../../redux/actions/auth/signUpActions";
import { SCHOOL_SING_UP_TITLE } from "../../../constants/appLabel/appLabel";

const SignUpPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();
    const [form, setForm] = useState({
        phoneNumber: "",
        employeeID: "",
        username: "",
    });

    const { signUp, LoadSignUp, signUpError } = useSelector(
        (state) => state.signUp
    );

    const onHandleChangeInput = (event) => {
        const { name, value } = event.target;
        const formData = { ...form };
        formData[name] = value;
        setForm(formData);
    };

    const onHandleSubmit = () => {
        dispatch(
            SignUp({
                email: "",
                phone_number: form.phoneNumber,
                employee_id: form.employeeID,
                is_school: false,
                is_teacher: true,
                is_parent: false,
                is_f_user: false,
                name: "",
                username: form.username,
                password: "",
                country_code: "",
                education_level: "",
                state: "",
                city: "",
                street: "",
                is_level_1: false,
                is_level_2: false,
                is_level_3: true,
                is_boarding: false,
                is_public: false,
                is_private: false,
                other_address: "",
            })
        );
    };

    useEffect(() => {
        if (signUpError) {
            Toast(api, "Error", signUpError?.message);
            dispatch(clearSignUp());
        }

        if (signUp) {
            Toast(api, "Success", signUp?.message);
            dispatch(clearSignUp());
            setForm({ phoneNumber: "", employeeID: "", username: "" });
            navigate("/login");
        }
    }, [api, dispatch, navigate, signUp, signUpError]);

    return (
        <AuthContainer>
            {contextHolder}
            <div className="auth-container-box padding-top-40 padding-bottom-40 padding-left-30 padding-right-30">
                <div className="auth-title-container margin-bottom-20">
                    <span className="app-name">Sign up</span>
                </div>
                <div className="auth-form-container">
                    <span className="login-text-message">
                        {SCHOOL_SING_UP_TITLE}
                    </span>
                    <InputForm
                        type={"text"}
                        label={"Employee ID"}
                        name="employeeID"
                        placeholder={"Enter your employee ID"}
                        onChange={onHandleChangeInput}
                    />
                    <InputForm
                        type={"text"}
                        label={"Phone number"}
                        name="phoneNumber"
                        placeholder={"Enter phone number"}
                        onChange={onHandleChangeInput}
                    />
                    <InputForm
                        type={"text"}
                        label={"Username"}
                        name="username"
                        placeholder={"Enter username"}
                        onChange={onHandleChangeInput}
                    />
                </div>
                <CustomSeparator />
                <div className="flex-row-sb button-style margin-top-25">
                    <Link to="/login" className="signup-btn-link margin-top-10">
                        {"Go back"}
                    </Link>
                    <Button
                        onClick={onHandleSubmit}
                        loading={LoadSignUp}
                        size="large"
                        type="primary"
                        disabled={
                            form.employeeID === "" ||
                            form.phoneNumber === "" ||
                            form.username === ""
                                ? true
                                : false
                        }
                    >
                        {"Proceed"}
                    </Button>
                </div>
            </div>
        </AuthContainer>
    );
};

export default SignUpPage;
