import { createSlice } from "@reduxjs/toolkit";
import { RetrieveStudentClasses } from "../../../actions/school/teachers/retrieveStudentClassesAction";

const initialState = {
    retrieveStudentClasses: null,
    LoadRetrieveStudentClasses: false,
    retrieveStudentClassesError: null,
};

const retrieveStudentClassesSlice = createSlice({
    name: "retrieveStudentClasses",
    initialState: initialState,
    reducers: {
        clearRetrieveStudentClasses: (state) => {
            state.retrieveStudentClasses = null;
            state.retrieveStudentClassesError = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(RetrieveStudentClasses.pending, (state) => {
                state.LoadRetrieveStudentClasses = true;
                state.retrieveStudentClassesError = null;
            })
            .addCase(RetrieveStudentClasses.fulfilled, (state, action) => {
                state.LoadRetrieveStudentClasses = false;
                state.retrieveStudentClasses = action.payload;
            })
            .addCase(RetrieveStudentClasses.rejected, (state, action) => {
                state.LoadRetrieveStudentClasses = false;
                state.retrieveStudentClassesError = action.payload;
            });
    },
});

export const { clearRetrieveStudentClasses } =
    retrieveStudentClassesSlice.actions;

export default retrieveStudentClassesSlice.reducer;
