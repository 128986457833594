import { createSlice } from "@reduxjs/toolkit";
import { RetrieveFacilityTeachers } from "../../../actions/school/teachers/retrieveFacilityTeachersActions";

const initialState = {
    retrieveFacilityTeachers: null,
    LoadRetrieveFacilityTeachers: false,
    retrieveFacilityTeachersError: null,
};

const retrieveFacilityTeachersSlice = createSlice({
    name: "retrieveFacilityTeachers",
    initialState: initialState,
    reducers: {
        clearRetrieveFacilityTeachers: (state) => {
            state.retrieveFacilityTeachers = null;
            state.retrieveFacilityTeachersError = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(RetrieveFacilityTeachers.pending, (state) => {
                state.LoadRetrieveFacilityTeachers = true;
                state.retrieveFacilityTeachersError = null;
            })
            .addCase(RetrieveFacilityTeachers.fulfilled, (state, action) => {
                state.LoadRetrieveFacilityTeachers = false;
                state.retrieveFacilityTeachers = action.payload;
            })
            .addCase(RetrieveFacilityTeachers.rejected, (state, action) => {
                state.LoadRetrieveFacilityTeachers = false;
                state.retrieveFacilityTeachersError = action.payload;
            });
    },
});

export const { clearRetrieveFacilityTeachers } =
    retrieveFacilityTeachersSlice.actions;

export default retrieveFacilityTeachersSlice.reducer;
