import { createSlice } from "@reduxjs/toolkit";
import { FetchPendingPayment } from "../../../../actions/school/finance/payments/fetchPendingPaymentActions";

const initialState = {
  fetchPendingPayment: null,
  LoadFetchPendingPayment: false,
  fetchPendingPaymentError: null,
};

const fetchPendingPaymentSlice = createSlice({
  name: "fetchPendingPayment",
  initialState: initialState,
  reducers: {
    clearFetchPendingPayment: (state) => {
      state.fetchPendingPayment = null;
      state.fetchPendingPaymentError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(FetchPendingPayment.pending, (state) => {
        state.LoadFetchPendingPayment = true;
        state.fetchPendingPaymentError = null;
      })
      .addCase(FetchPendingPayment.fulfilled, (state, action) => {
        state.LoadFetchPendingPayment = false;
        state.fetchPendingPayment = action.payload;
      })
      .addCase(FetchPendingPayment.rejected, (state, action) => {
        state.LoadFetchPendingPayment = false;
        state.fetchPendingPaymentError = action.payload;
      });
  },
});

export const { clearFetchPendingPayment } = fetchPendingPaymentSlice.actions;

export default fetchPendingPaymentSlice.reducer;
