import { createSlice } from "@reduxjs/toolkit";
import { SetEmployeeCompensation } from "../../../../actions/school/finance/payments/setEmployeeCompensationAction";

const initialState = {
  setEmployeeCompensation: null,
  LoadSetEmployeeCompensation: false,
  setEmployeeCompensationError: null,
};

const setEmployeeCompensationSlice = createSlice({
  name: "setEmployeeCompensation",
  initialState: initialState,
  reducers: {
    clearSetEmployeeCompensation: (state) => {
      state.setEmployeeCompensation = null;
      state.setEmployeeCompensationError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(SetEmployeeCompensation.pending, (state) => {
        state.LoadSetEmployeeCompensation = true;
        state.setEmployeeCompensationError = null;
      })
      .addCase(SetEmployeeCompensation.fulfilled, (state, action) => {
        state.LoadSetEmployeeCompensation = false;
        state.setEmployeeCompensation = action.payload;
      })
      .addCase(SetEmployeeCompensation.rejected, (state, action) => {
        state.LoadSetEmployeeCompensation = false;
        state.setEmployeeCompensationError = action.payload;
      });
  },
});

export const { clearSetEmployeeCompensation } =
  setEmployeeCompensationSlice.actions;

export default setEmployeeCompensationSlice.reducer;
