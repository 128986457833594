/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Select, Table, Empty, Space, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RetrieveStudentSubjectMarks } from "../../../../redux/actions/teacher/levels/retrieveStudentSubjectMarksAction";
import teacherSelector from "../../../../redux/selectors/teacherSelector";
import { CgDetailsMore } from "react-icons/cg";
import StudentMarksDetails from "../studentMarksDetails";
import { LoadingOutlined } from "@ant-design/icons";
import { SearchOutlined } from "@ant-design/icons";
import { Switch } from "antd";
import { RetrieveAssessmentFile } from "../../../../redux/actions/teacher/levels/retrieveAssessmentFileAction";
import { SaveOutlined } from "@ant-design/icons";
import { Divider } from "antd";
import Toast from "../../../../components/common/toast";
import { notification } from "antd";
import { clearRetrieveStudentSubjectMarks } from "../../../../redux/reducers/teacher/levels/retrieveStudentSubjectMarksReducer";
import { Modal } from "antd";

const defaultOptionData = {
    formativeAssessment: true,
    summativeAssessment: false,
};

const StudentCoursesMarks = ({ dropdownData, subLevelId, folders }) => {
    const dispatch = useDispatch();
    const [api, contextHolder] = notification.useNotification();
    const [openCreateSummativeModal, setOpenCreateSummativeModal] =
        useState(false);
    const [openDisplayMarksModal, setOpenDisplayMarksModal] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [selectedStudent, setSelectedStudent] = useState();
    const [selectedSubject, setSelectedSubject] = useState({
        subject_id: undefined,
    });
    const [selectFolder, setSelectFolder] = useState({
        assessment_file_id: undefined,
    });
    const [selectedOption, setSelectedOption] = useState(defaultOptionData);
    const [checked, setChecked] = useState(false);

    const {
        retrieveStudentSubjectMarks: {
            retrieveStudentSubjectMarks,
            LoadRetrieveStudentSubjectMarks,
            retrieveStudentSubjectMarksError,
        },
        retrieveStudentMarksDetails: { LoadRetrieveStudentMarksDetails },
    } = useSelector(teacherSelector);

    const onHandleSelectSubject = (value) =>
        setSelectedSubject(() => ({ subject_id: value }));

    const onHandleSelectFolder = (value) =>
        setSelectFolder(() => ({ assessment_file_id: value }));

    const onCloseDrawer = () => {
        setSelectedStudent();
        setOpenDrawer(false);
    };

    const onHandleOpenDisplayMarksModal = () => setOpenDisplayMarksModal(true);
    const onHandleOpenCreateSummativeModal = () => {
        setOpenCreateSummativeModal(true);
        setChecked(true);
        setSelectedOption({
            formativeAssessment: false,
            summativeAssessment: false,
        });
    };

    const onHandleCloseDisplayMarksModal = () => {
        setOpenDisplayMarksModal(false);
        setSelectedOption(defaultOptionData);
    };

    const onHandleCloseCreateSummativeModal = () => {
        setOpenCreateSummativeModal(false);
        setChecked(false);
    };

    const onHandleOpenDrawer = () => setOpenDrawer(true);

    const subjectInfo =
        retrieveStudentSubjectMarks?.data?.subject_info &&
        retrieveStudentSubjectMarks?.data?.subject_info;

    const tableColumns = [
        {
            title: "Student code",
            dataIndex: "studentCode",
            key: "studentCode",
        },
        {
            title: "Student",
            dataIndex: "student",
            key: "student",
        },
        {
            title: "Scores",
            dataIndex: "totalScores",
            key: "totalScores",
            textAlign: "center",
        },
        subjectInfo?.new_total_marks
            ? {
                  title: "Total Marks Achieved",
                  dataIndex: "assessmentTotalMarks",
                  key: "assessmentTotalMarks",
                  textAlign: "center",
              }
            : {
                  title: "Subject's Total Marks",
                  dataIndex: "subjectTotalMarks",
                  key: "subjectTotalMarks",
                  textAlign: "center",
              },
    ];

    if (subjectInfo?.new_total_marks) {
        tableColumns.push({
            title: "Actions",
            dataIndex: "x",
            key: "x",
            render: (_, row) => (
                <Space>
                    <a
                        className="flex-row-sb deep-blue-text"
                        style={{ marginLeft: "10px" }}
                        onClick={() => {
                            setSelectedStudent(row);
                        }}
                    >
                        <CgDetailsMore size={21} />
                        Details
                    </a>
                </Space>
            ),
        });
    }

    const formative_assessment =
        retrieveStudentSubjectMarks?.data?.students_formative_assessment &&
        retrieveStudentSubjectMarks?.data?.students_formative_assessment.map(
            (student, index) => ({
                key: index,
                assessmentTotalMarks: student?.assessment_total_marks,
                student: `${student?.first_name} ${student?.last_name}`,
                studentId: student?.id,
                studentCode: student?.student_code,
                totalScores: student?.total_scores,
                subjectTotalMarks: subjectInfo?.total_marks,
            })
        );

    const summary_assessment =
        retrieveStudentSubjectMarks?.data?.students_summative_assessment &&
        retrieveStudentSubjectMarks?.data?.students_summative_assessment.map(
            (student, index) => ({
                key: index,
                assessmentTotalMarks: student?.assessment_total_marks,
                student: `${student?.first_name} ${student?.last_name}`,
                studentId: student?.id,
                studentCode: student?.student_code,
                totalScores: student?.total_scores,
                subjectTotalMarks: subjectInfo?.total_marks,
            })
        );

    const tableData = formative_assessment || summary_assessment;

    const onHandleRefresh = () => {
        if (selectedSubject?.subject_id && checked) {
            dispatch(
                RetrieveStudentSubjectMarks({
                    subject_id: selectedSubject?.subject_id,
                    level_id: subLevelId,
                    assessment_file_id: selectFolder?.assessment_file_id,
                    get_formative_assessment:
                        selectedOption.formativeAssessment,
                    get_summative_assessment:
                        selectedOption.summativeAssessment,
                    save_summative_assessment: checked || false,
                })
            );
        }

        if (selectedSubject?.subject_id) {
            dispatch(
                RetrieveStudentSubjectMarks({
                    subject_id: selectedSubject?.subject_id || "",
                    level_id: subLevelId,
                    assessment_file_id: selectFolder?.assessment_file_id,
                    get_formative_assessment:
                        selectedOption.formativeAssessment,
                    get_summative_assessment:
                        selectedOption.summativeAssessment,
                    save_summative_assessment: checked,
                })
            );
        }
    };

    useEffect(() => {
        const payload = {
            assessment_file_id: "",
            formative_assessment: false,
            level_id: subLevelId || "",
        };
        dispatch(RetrieveAssessmentFile(payload));
    }, [dispatch, subLevelId]);

    useEffect(() => {
        if (retrieveStudentSubjectMarksError) {
            Toast(api, "Error", retrieveStudentSubjectMarksError.message);
            dispatch(clearRetrieveStudentSubjectMarks());
        }

        if (retrieveStudentSubjectMarks?.data.marks_saved) {
            onHandleCloseCreateSummativeModal();
            dispatch(clearRetrieveStudentSubjectMarks());
            Toast(api, "Info", retrieveStudentSubjectMarks.message);
        }
        if (
            retrieveStudentSubjectMarks &&
            !retrieveStudentSubjectMarks?.data.marks_saved
        ) {
            onHandleCloseDisplayMarksModal();
        }
    }, [
        api,
        dispatch,
        retrieveStudentSubjectMarksError,
        retrieveStudentSubjectMarks,
    ]);

    return (
        <>
            <div className="flex-column width-100-percent">
                {contextHolder}
                <div className="flex-row-sb">
                    <Space>
                        <Button
                            ghost
                            type="primary"
                            onClick={onHandleOpenCreateSummativeModal}
                        >
                            Create Summative
                        </Button>
                        <Button
                            ghost
                            type="primary"
                            onClick={onHandleOpenDisplayMarksModal}
                        >
                            Display student marks
                        </Button>
                    </Space>
                </div>
                <Divider />
                {tableData && tableData.length !== 0 && (
                    <Table
                        loading={
                            LoadRetrieveStudentSubjectMarks ||
                            LoadRetrieveStudentMarksDetails
                        }
                        columns={tableColumns}
                        dataSource={tableData}
                        pagination={false}
                        locale={{
                            emptyText: (
                                <Empty description="Click the button below to display students' marks" />
                            ),
                        }}
                    />
                )}
                {!tableData && (
                    <Empty
                        description="Click the button below to display students' marks"
                        className="margin-top-10 margin-bottom-20 width-100-percent"
                    >
                        <div
                            className="flex-row"
                            style={{ justifyContent: "center" }}
                        >
                            <Button
                                type="primary"
                                style={{ alignSelf: "center" }}
                                onClick={onHandleOpenDisplayMarksModal}
                            >
                                Display students marks
                            </Button>
                        </div>
                    </Empty>
                )}
            </div>
            <Modal
                onCancel={onHandleCloseDisplayMarksModal}
                title={`Display student marks`}
                open={openDisplayMarksModal}
                closeIcon={true}
                footer={[]}
            >
                <div className="flex-colum">
                    <div className="flex-colum margin-top-10">
                        <span className="align-text-left margin-bottom-10">
                            Select a subject
                        </span>
                        <Select
                            placeholder="Select a subject"
                            style={{
                                textAlign: "left",
                            }}
                            options={dropdownData}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                (option?.label?.toLowerCase() ?? "").includes(
                                    input.toLowerCase()
                                )
                            }
                            showSearch
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? "")
                                    .toLowerCase()
                                    .localeCompare(
                                        (optionB?.label ?? "").toLowerCase()
                                    )
                            }
                            aria-label="Select a subject"
                            value={selectedSubject.subject_id}
                            onChange={onHandleSelectSubject}
                        />
                    </div>
                    <div className="flex-colum margin-top-10">
                        <span className="align-text-left margin-bottom-10">
                            Select Assessment File
                        </span>
                        <Select
                            placeholder="Select Assessment File"
                            style={{
                                textAlign: "left",
                            }}
                            options={folders}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                (option?.label?.toLowerCase() ?? "").includes(
                                    input.toLowerCase()
                                )
                            }
                            showSearch
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? "")
                                    .toLowerCase()
                                    .localeCompare(
                                        (optionB?.label ?? "").toLowerCase()
                                    )
                            }
                            value={selectFolder?.assessment_file_id}
                            aria-label="Select Assessment File"
                            onChange={onHandleSelectFolder}
                        />
                    </div>
                    <Divider />
                    <div className="flex-row-sb padding-bottom-10 padding-top-10">
                        <div style={{ alignSelf: "center" }}>
                            Display formative assessment
                        </div>
                        <Switch
                            checked={selectedOption.formativeAssessment}
                            onClick={(data) =>
                                setSelectedOption((prev) => ({
                                    ...prev,
                                    formativeAssessment: data,
                                    summativeAssessment: false,
                                }))
                            }
                            className="custom-switch"
                            style={{ marginLeft: "10px" }}
                        />
                    </div>
                    <div className="flex-row-sb padding-bottom-10 padding-top-10">
                        <div style={{ alignSelf: "center" }}>
                            Display summative assessment
                        </div>
                        <Switch
                            checked={selectedOption.summativeAssessment}
                            onClick={(data) =>
                                setSelectedOption((prev) => ({
                                    ...prev,
                                    summativeAssessment: data,
                                    formativeAssessment: false,
                                }))
                            }
                            className="custom-switch"
                            style={{ marginLeft: "10px" }}
                        />
                    </div>

                    <Button
                        type="primary"
                        onClick={onHandleRefresh}
                        className="margin-top-25"
                        disabled={
                            selectedSubject?.subject_id === undefined ||
                            selectFolder?.assessment_file_id === undefined
                                ? true
                                : false
                        }
                    >
                        {LoadRetrieveStudentSubjectMarks && (
                            <LoadingOutlined
                                size={30}
                                style={{
                                    alignSelf: "center",
                                }}
                            />
                        )}
                        {!LoadRetrieveStudentSubjectMarks && (
                            <>
                                {checked ? (
                                    <>
                                        <SaveOutlined
                                            size={30}
                                            style={{ alignSelf: "center" }}
                                        />
                                        <span style={{ marginLeft: "10px" }}>
                                            Save
                                        </span>
                                    </>
                                ) : (
                                    <>
                                        <SearchOutlined
                                            size={30}
                                            style={{ alignSelf: "center" }}
                                        />
                                        <span style={{ marginLeft: "10px" }}>
                                            Display
                                        </span>
                                    </>
                                )}
                            </>
                        )}
                    </Button>
                </div>
            </Modal>
            <Modal
                onCancel={onHandleCloseCreateSummativeModal}
                title={`Create Summative`}
                open={openCreateSummativeModal}
                closeIcon={true}
                footer={[]}
            >
                <Divider />
                <div className="flex-colum margin-top-10">
                    <span className="align-text-left margin-bottom-10">
                        Select a subject
                    </span>
                    <Select
                        placeholder="Select a subject"
                        style={{
                            textAlign: "left",
                        }}
                        options={dropdownData}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            (option?.label?.toLowerCase() ?? "").includes(
                                input.toLowerCase()
                            )
                        }
                        showSearch
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                                .toLowerCase()
                                .localeCompare(
                                    (optionB?.label ?? "").toLowerCase()
                                )
                        }
                        aria-label="Select a subject"
                        value={selectedSubject.subject_id}
                        onChange={onHandleSelectSubject}
                    />
                </div>
                <div className="flex-colum margin-top-10">
                    <span className="align-text-left margin-bottom-10">
                        Select Assessment File
                    </span>
                    <Select
                        placeholder="Select Assessment File"
                        style={{
                            textAlign: "left",
                        }}
                        options={folders}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            (option?.label?.toLowerCase() ?? "").includes(
                                input.toLowerCase()
                            )
                        }
                        showSearch
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                                .toLowerCase()
                                .localeCompare(
                                    (optionB?.label ?? "").toLowerCase()
                                )
                        }
                        value={selectFolder?.assessment_file_id}
                        aria-label="Select Assessment File"
                        onChange={onHandleSelectFolder}
                    />
                </div>
                <div className="flex-row padding-bottom-10 margin-top-20">
                    <div style={{ alignSelf: "center", color: "#707070" }}>
                        You are about to generate a summative assessment file
                        from these formative assessment files. Please slide the
                        switch to the right to generate the file
                    </div>
                    <Switch
                        checked={checked}
                        onClick={(data) => setChecked(data)}
                        className="custom-switch"
                        style={{ marginLeft: "10px" }}
                    />
                </div>
                <Divider />
                <Button
                    type="primary"
                    size="large"
                    onClick={onHandleRefresh}
                    className="margin-top-25 width-100-percent"
                >
                    {LoadRetrieveStudentSubjectMarks && (
                        <LoadingOutlined
                            size={30}
                            style={{
                                alignSelf: "center",
                            }}
                        />
                    )}
                    {!LoadRetrieveStudentSubjectMarks && (
                        <>
                            <span style={{ marginLeft: "10px" }}>
                                Generate now
                            </span>
                        </>
                    )}
                </Button>
            </Modal>

            <StudentMarksDetails
                subLevelId={subLevelId}
                openDrawer={openDrawer}
                studentData={selectedStudent}
                onCloseDrawer={onCloseDrawer}
                onHandleOpenDrawer={onHandleOpenDrawer}
                selectFolder={selectFolder?.assessment_file_id}
            />
        </>
    );
};

export default StudentCoursesMarks;
