import { createSlice } from "@reduxjs/toolkit";
import { SetLanguage } from "../../actions/auth/setLanguage";

const initialState = {
    languageData: null,
    loading: false,
    error: null,
};

const setLanguageSlice = createSlice({
    name: "setLanguage",
    initialState: initialState,
    reducers: {
        setLanguage: (state, action) => {
            state.languageData = action.payload;
        },
        clearSetLanguageData: (state) => {
            state.languageData = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(SetLanguage.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(SetLanguage.fulfilled, (state, action) => {
                state.loading = false;
                state.languageData = action.payload;
            })
            .addCase(SetLanguage.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export const { setLanguage, clearSetLanguageData } = setLanguageSlice.actions;

export default setLanguageSlice.reducer;
