import { createSlice } from "@reduxjs/toolkit";
import { RetrieveSchoolTeacherSchedule } from "../../../actions/school/teachers/retrieveSchoolTeacherScheduleActions";

const initialState = {
    retrieveSchoolTeacherSchedule: null,
    LoadRetrieveSchoolTeacherSchedule: false,
    retrieveSchoolTeacherScheduleError: null,
};

const retrieveSchoolTeacherScheduleSlice = createSlice({
    name: "retrieveSchoolTeacherSchedule",
    initialState: initialState,
    reducers: {
        clearRetrieveSchoolTeacherSchedule: (state) => {
            state.retrieveSchoolTeacherSchedule = null;
            state.retrieveSchoolTeacherScheduleError = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(RetrieveSchoolTeacherSchedule.pending, (state) => {
                state.LoadRetrieveSchoolTeacherSchedule = true;
                state.retrieveSchoolTeacherScheduleError = null;
            })
            .addCase(
                RetrieveSchoolTeacherSchedule.fulfilled,
                (state, action) => {
                    state.LoadRetrieveSchoolTeacherSchedule = false;
                    state.retrieveSchoolTeacherSchedule = action.payload;
                }
            )
            .addCase(
                RetrieveSchoolTeacherSchedule.rejected,
                (state, action) => {
                    state.LoadRetrieveSchoolTeacherSchedule = false;
                    state.retrieveSchoolTeacherScheduleError = action.payload;
                }
            );
    },
});

export const { clearRetrieveSchoolTeacherSchedule } =
    retrieveSchoolTeacherScheduleSlice.actions;

export default retrieveSchoolTeacherScheduleSlice.reducer;
