import React, { useEffect } from "react";
import moment from "moment";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Drawer, Button, Space, Table, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";

import Toast from "../../../../components/common/toast";
import { RetrieveStudentMarksDetails } from "../../../../redux/actions/teacher/levels/retrieveStudentMarksDetailsAction";
import { clearRetrieveStudentMarksDetails } from "../../../../redux/reducers/teacher/levels/retrieveStudentMarksDetailsReducer";
import teacherSelector from "../../../../redux/selectors/teacherSelector";
import { StudentResponsiveLine } from "../studentMarksDetails/Graph";
import CustomSeparator from "../../../../components/common/customrSeparator/index";

const StudentMarksDetails = ({
    studentData,
    openDrawer,
    onCloseDrawer,
    subLevelId,
    onHandleOpenDrawer,
    selectFolder,
}) => {
    const dispatch = useDispatch();
    const [api, contextHolder] = notification.useNotification();

    const {
        retrieveStudentMarksDetails: {
            retrieveStudentMarksDetails,
            retrieveStudentMarksDetailsError,
        },
    } = useSelector(teacherSelector);

    useEffect(() => {
        if (retrieveStudentMarksDetailsError) {
            Toast(api, "Error", retrieveStudentMarksDetailsError.message);
            dispatch(clearRetrieveStudentMarksDetails());
        }

        if (retrieveStudentMarksDetails) {
            onHandleOpenDrawer();
        }
    }, [
        api,
        dispatch,
        onHandleOpenDrawer,
        retrieveStudentMarksDetails,
        retrieveStudentMarksDetailsError,
    ]);

    const tableColumns = [
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
        },
        {
            title: "Assessment",
            dataIndex: "file_name",
            key: "file_name",
        },
        {
            title: "Score",
            dataIndex: "student_scores",
            key: "student_scores",
        },
        {
            title: "Total Marks Achieved",
            dataIndex: "total_marks",
            key: "total_marks",
        },
    ];

    const studentMarks =
        retrieveStudentMarksDetails?.data &&
        retrieveStudentMarksDetails?.data.map((item, index) => ({
            key: index,
            attended: item?.item,
            date: moment(item?.created_at).format("YYYY-MM-DD"),
            file_name: item?.file_name,
            student_scores: item?.student_scores,
            total_marks: item?.total_marks,
        }));

    useEffect(() => {
        if (studentData?.studentId) {
            dispatch(
                RetrieveStudentMarksDetails({
                    level_id: subLevelId,
                    student_id: studentData?.studentId,
                    assessment_file_id: selectFolder,
                })
            );
        }
    }, [dispatch, selectFolder, studentData?.studentId, subLevelId]);

    const convertToGraphData =
        studentMarks &&
        studentMarks.map((item) => ({
            x: item?.file_name,
            y: item?.student_scores,
            score: item?.student_scores,
            totalMarks: item?.total_marks,
        }));

    return (
        <div>
            {contextHolder}
            <Drawer
                width={650}
                title={`${studentData?.student}`}
                placement="right"
                open={openDrawer}
                closable={false}
                destroyOnClose={false}
                closeIcon
                extra={
                    <Space>
                        <Button
                            shape="circle"
                            type="text"
                            danger
                            onClick={() => {
                                onCloseDrawer();
                                dispatch(clearRetrieveStudentMarksDetails());
                            }}
                        >
                            <AiOutlineCloseCircle size={25} />
                        </Button>
                    </Space>
                }
            >
                <div className="flex-column">
                    <div className="flex-row-sb grey-border-bottom padding-bottom-10">
                        <strong>Total Subject Marks</strong>
                        <div>{studentData?.subjectTotalMarks}</div>
                    </div>
                    <CustomSeparator size={5} />
                    <div className="flex-row-sb grey-border-bottom padding-bottom-10">
                        <strong>Cumulative Assessment Marks</strong>
                        <div>{studentData?.assessmentTotalMarks}</div>
                    </div>
                    <CustomSeparator size={5} />
                    <div className="flex-row-sb ">
                        <strong>Aggregate Student Marks</strong>
                        <div>{studentData?.totalScores}</div>
                    </div>
                </div>
                <CustomSeparator />
                <Table
                    size="small"
                    key="details-marks"
                    columns={tableColumns}
                    dataSource={studentMarks}
                    pagination={false}
                />
            </Drawer>
        </div>
    );
};

export default StudentMarksDetails;
