import { createSlice } from "@reduxjs/toolkit";
import { ResetUserPassword } from "../../actions/auth/resetPasswordActions";

const initialState = {
    resetUserPassword: null,
    loadResetUserPassword: false,
    error: null,
};

const resetUserPasswordSlice = createSlice({
    name: "resetUserPassword",
    initialState: initialState,
    reducers: {
        clearUserResetPasswordData: (state) => {
            state.resetUserPassword = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(ResetUserPassword.pending, (state) => {
                state.loadResetUserPassword = true;
                state.error = null;
            })
            .addCase(ResetUserPassword.fulfilled, (state, action) => {
                state.loadResetUserPassword = false;
                state.resetUserPassword = action.payload;
            })
            .addCase(ResetUserPassword.rejected, (state, action) => {
                state.loadResetUserPassword = false;
                state.error = action.payload;
            });
    },
});

export const { clearUserResetPasswordData } = resetUserPasswordSlice.actions;
export const resetUserPassword = (state) =>
    state.resetUserPassword.resetUserPassword;

export default resetUserPasswordSlice.reducer;
