import React from "react";
import "./index.css";

const DashboardCard = ({
    title,
    number,
    style,
    Icon,
    iconColor,
    background,
}) => {
    return (
        <div className="dashboard-card-box">
            <div className="dashboard-card-box__row">
                <div className="icon-style" style={{ background: background }}>
                    <Icon size={30} color={iconColor} />
                </div>
                <span className="dashboard-card-title">{title}</span>
            </div>
            <div className="dashboard-card-box__row-top-10">
                <span className="dashboard-card-box__number" style={style}>
                    {number}
                </span>
            </div>
        </div>
    );
};

export default DashboardCard;
