import { createSlice } from "@reduxjs/toolkit";
import { FetchManageFeeType } from "../../../../actions/school/finance/manageFees/manageFeeActions";

const initialState = {
  fetchManageFeeType: null,
  LoadFetchManageFeeType: false,
  fetchManageFeeTypeError: null,
};

const fetchManageFeeTypeSlice = createSlice({
  name: "fetchManageFeeType",
  initialState: initialState,
  reducers: {
    clearFetchManageFeeType: (state) => {
      state.fetchManageFeeType = null;
      state.fetchManageFeeTypeError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(FetchManageFeeType.pending, (state) => {
        state.LoadFetchManageFeeType = true;
        state.fetchManageFeeTypeError = null;
      })
      .addCase(FetchManageFeeType.fulfilled, (state, action) => {
        state.LoadFetchManageFeeType = false;
        state.fetchManageFeeType = action.payload;
      })
      .addCase(FetchManageFeeType.rejected, (state, action) => {
        state.LoadFetchManageFeeType = false;
        state.fetchManageFeeTypeError = action.payload;
      });
  },
});

export const { clearFetchManageFeeType } = fetchManageFeeTypeSlice.actions;

export default fetchManageFeeTypeSlice.reducer;
