import { createSlice } from "@reduxjs/toolkit";
import { FetchCashReportData } from "../../../../actions/school/finance/report/fetchCashReportAction";

const initialState = {
  fetchCashReport: null,
  LoadFetchCashReport: false,
  fetchCashReportError: null,
};

const fetchCashReportSlice = createSlice({
  name: "fetchCashReport",
  initialState: initialState,
  reducers: {
    clearFetchCashReport: (state) => {
      state.fetchCashReport = null;
      state.fetchCashReportError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(FetchCashReportData.pending, (state) => {
        state.LoadFetchCashReport = true;
        state.fetchCashReportError = null;
      })
      .addCase(FetchCashReportData.fulfilled, (state, action) => {
        state.LoadFetchCashReport = false;
        state.fetchCashReport = action.payload;
      })
      .addCase(FetchCashReportData.rejected, (state, action) => {
        state.LoadFetchCashReport = false;
        state.fetchCashReportError = action.payload;
      });
  },
});

export const { clearFetchCashReport } = fetchCashReportSlice.actions;

export default fetchCashReportSlice.reducer;
