import { createSlice } from "@reduxjs/toolkit";
import { FetchEducationSystem } from "../../../actions/school/otherConfiguration/fetchEducationSystemAction";

const initialState = {
    fetchEducationSystem: null,
    LoadFetchEducationSystem: false,
    fetchEducationSystemError: null,
};

const fetchEducationSystemSlice = createSlice({
    name: "fetchEducationSystem",
    initialState: initialState,
    reducers: {
        clearFetchEducationSystem: (state) => {
            state.fetchEducationSystem = null;
            state.fetchEducationSystemError = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(FetchEducationSystem.pending, (state) => {
                state.LoadFetchEducationSystem = true;
                state.fetchEducationSystemError = null;
            })
            .addCase(FetchEducationSystem.fulfilled, (state, action) => {
                state.LoadFetchEducationSystem = false;
                state.fetchEducationSystem = action.payload;
            })
            .addCase(FetchEducationSystem.rejected, (state, action) => {
                state.LoadFetchEducationSystem = false;
                state.fetchEducationSystemError = action.payload;
            });
    },
});

export const { clearFetchEducationSystem } = fetchEducationSystemSlice.actions;

export default fetchEducationSystemSlice.reducer;
