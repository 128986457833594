import { createSlice } from "@reduxjs/toolkit";
import { CreateFormativeAssessmentFile } from "../../../actions/teacher/levels/creatFormativeAssessmentFileAction";

const initialState = {
    createFormativeAssessmentFile: null,
    LoadCreateFormativeAssessmentFile: false,
    createFormativeAssessmentFileError: null,
};

const createFormativeAssessmentFileSlice = createSlice({
    name: "createFormativeAssessmentFile",
    initialState: initialState,
    reducers: {
        clearCreateFormativeAssessmentFile: (state) => {
            state.createFormativeAssessmentFile = null;
            state.createFormativeAssessmentFileError = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(CreateFormativeAssessmentFile.pending, (state) => {
                state.LoadCreateFormativeAssessmentFile = true;
                state.createFormativeAssessmentFileError = null;
            })
            .addCase(
                CreateFormativeAssessmentFile.fulfilled,
                (state, action) => {
                    state.LoadCreateFormativeAssessmentFile = false;
                    state.createFormativeAssessmentFile = action.payload;
                }
            )
            .addCase(
                CreateFormativeAssessmentFile.rejected,
                (state, action) => {
                    state.LoadCreateFormativeAssessmentFile = false;
                    state.createFormativeAssessmentFileError = action.payload;
                }
            );
    },
});

export const { clearCreateFormativeAssessmentFile } =
    createFormativeAssessmentFileSlice.actions;

export default createFormativeAssessmentFileSlice.reducer;
