import { createSlice } from "@reduxjs/toolkit";
import { RetrieveStudentCoursesMarks } from "../../../actions/school/students/retrieveStudentCoursesMarksAction";

const initialState = {
    retrieveStudentCoursesMarks: null,
    LoadRetrieveStudentCoursesMarks: false,
    retrieveStudentCoursesMarksError: null,
};

const retrieveStudentCoursesMarksSlice = createSlice({
    name: "retrieveStudentCoursesMarks",
    initialState: initialState,
    reducers: {
        clearRetrieveStudentCoursesMarks: (state) => {
            state.retrieveStudentCoursesMarks = null;
            state.retrieveStudentCoursesMarksError = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(RetrieveStudentCoursesMarks.pending, (state) => {
                state.LoadRetrieveStudentCoursesMarks = true;
                state.retrieveStudentCoursesMarksError = null;
            })
            .addCase(RetrieveStudentCoursesMarks.fulfilled, (state, action) => {
                state.LoadRetrieveStudentCoursesMarks = false;
                state.retrieveStudentCoursesMarks = action.payload;
            })
            .addCase(RetrieveStudentCoursesMarks.rejected, (state, action) => {
                state.LoadRetrieveStudentCoursesMarks = false;
                state.retrieveStudentCoursesMarksError = action.payload;
            });
    },
});

export const { clearRetrieveStudentCoursesMarks } =
    retrieveStudentCoursesMarksSlice.actions;

export default retrieveStudentCoursesMarksSlice.reducer;
