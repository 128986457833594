import { createSlice } from "@reduxjs/toolkit";
import { AddTeacher } from "../../../actions/school/teachers/addTeacherActions";

const initialState = {
    addTeacher: null,
    LoadAddTeacher: false,
    addTeacherError: null,
};

const addTeacherSlice = createSlice({
    name: "addTeacher",
    initialState: initialState,
    reducers: {
        clearAddTeacher: (state) => {
            state.addTeacher = null;
            state.addTeacherError = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(AddTeacher.pending, (state) => {
                state.LoadAddTeacher = true;
                state.addTeacherError = null;
            })
            .addCase(AddTeacher.fulfilled, (state, action) => {
                state.LoadAddTeacher = false;
                state.addTeacher = action.payload;
            })
            .addCase(AddTeacher.rejected, (state, action) => {
                state.LoadAddTeacher = false;
                state.addTeacherError = action.payload;
            });
    },
});

export const { clearAddTeacher } = addTeacherSlice.actions;

export default addTeacherSlice.reducer;
