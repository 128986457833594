import { createSlice } from "@reduxjs/toolkit";
import { FetchEmployeeList } from "../../../actions/school/employee/fetchEmployeeListActions";

const initialState = {
  fetchEmployeeList: null,
  LoadFetchEmployeeList: false,
  fetchEmployeeListError: null,
};

const fetchEmployeeListSlice = createSlice({
  name: "fetchEmployeeList",
  initialState: initialState,
  reducers: {
    clearFetchEmployeeList: (state) => {
      state.fetchEmployeeList = null;
      state.fetchEmployeeListError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(FetchEmployeeList.pending, (state) => {
        state.LoadFetchEmployeeList = true;
        state.fetchEmployeeListError = null;
      })
      .addCase(FetchEmployeeList.fulfilled, (state, action) => {
        state.LoadFetchEmployeeList = false;
        state.fetchEmployeeList = action.payload;
      })
      .addCase(FetchEmployeeList.rejected, (state, action) => {
        state.LoadFetchEmployeeList = false;
        state.fetchEmployeeListError = action.payload;
      });
  },
});

export const { clearFetchEmployeeList } = fetchEmployeeListSlice.actions;

export default fetchEmployeeListSlice.reducer;
