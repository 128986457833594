import { createSlice } from "@reduxjs/toolkit";
import { FetchDefaultData } from "../../../actions/school/otherConfiguration/fetchDefaultDataActions";

const initialState = {
  fetchDefaultData: null,
  LoadFetchDefaultData: false,
  fetchDefaultDataError: null,
};

const fetchDefaultDataSlice = createSlice({
  name: "fetchDefaultData",
  initialState: initialState,
  reducers: {
    clearFetchDefaultData: (state) => {
      state.fetchDefaultData = null;
      state.fetchDefaultDataError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(FetchDefaultData.pending, (state) => {
        state.LoadFetchDefaultData = true;
        state.fetchDefaultDataError = null;
      })
      .addCase(FetchDefaultData.fulfilled, (state, action) => {
        state.LoadFetchDefaultData = false;
        state.fetchDefaultData = action.payload;
      })
      .addCase(FetchDefaultData.rejected, (state, action) => {
        state.LoadFetchDefaultData = false;
        state.fetchDefaultDataError = action.payload;
      });
  },
});

export const { clearFetchDefaultData } = fetchDefaultDataSlice.actions;

export default fetchDefaultDataSlice.reducer;
