import { createSlice } from "@reduxjs/toolkit";
import { FetchFacilityUsers } from "../../../actions/school/users/fetchFacilityUsersActions";

const initialState = {
  fetchFacilityUsers: null,
  LoadFetchFacilityUsers: false,
  fetchFacilityUsersError: null,
};

const fetchFacilityUsersSlice = createSlice({
  name: "fetchFacilityUsers",
  initialState: initialState,
  reducers: {
    clearFetchFacilityUsers: (state) => {
      state.fetchFacilityUsers = null;
      state.fetchFacilityUsersError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(FetchFacilityUsers.pending, (state) => {
        state.LoadFetchFacilityUsers = true;
        state.fetchFacilityUsersError = null;
      })
      .addCase(FetchFacilityUsers.fulfilled, (state, action) => {
        state.LoadFetchFacilityUsers = false;
        state.fetchFacilityUsers = action.payload;
      })
      .addCase(FetchFacilityUsers.rejected, (state, action) => {
        state.LoadFetchFacilityUsers = false;
        state.fetchFacilityUsersError = action.payload;
      });
  },
});

export const { clearFetchFacilityUsers } = fetchFacilityUsersSlice.actions;

export default fetchFacilityUsersSlice.reducer;
