import { createSlice } from "@reduxjs/toolkit";
import { RetrieveStudentMarksDetails } from "../../../actions/teacher/levels/retrieveStudentMarksDetailsAction";

const initialState = {
    retrieveStudentMarksDetails: null,
    LoadRetrieveStudentMarksDetails: false,
    retrieveStudentMarksDetailsError: null,
};

const retrieveStudentMarksDetailsSlice = createSlice({
    name: "retrieveStudentMarksDetails",
    initialState: initialState,
    reducers: {
        clearRetrieveStudentMarksDetails: (state) => {
            state.retrieveStudentMarksDetails = null;
            state.retrieveStudentMarksDetailsError = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(RetrieveStudentMarksDetails.pending, (state) => {
                state.LoadRetrieveStudentMarksDetails = true;
                state.retrieveStudentMarksDetailsError = null;
            })
            .addCase(RetrieveStudentMarksDetails.fulfilled, (state, action) => {
                state.LoadRetrieveStudentMarksDetails = false;
                state.retrieveStudentMarksDetails = action.payload;
            })
            .addCase(RetrieveStudentMarksDetails.rejected, (state, action) => {
                state.LoadRetrieveStudentMarksDetails = false;
                state.retrieveStudentMarksDetailsError = action.payload;
            });
    },
});

export const { clearRetrieveStudentMarksDetails } =
    retrieveStudentMarksDetailsSlice.actions;

export default retrieveStudentMarksDetailsSlice.reducer;
