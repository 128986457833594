import React, { useEffect, useState } from "react";
import { Modal, Button, Select } from "antd";
import InputForm from "../../../../components/inputForm";
import CustomSeparator from "../../../../components/common/customrSeparator";
import Toast from "../../../../components/common/toast";
import { useDispatch } from "react-redux";
import { RetrieveAssessmentFile } from "../../../../redux/actions/teacher/levels/retrieveAssessmentFileAction";
import { clearCreateFormativeAssessmentFile } from "../../../../redux/reducers/teacher/levels/createFormativeAssessmentFileReducer";
import { CreateFormativeAssessmentFile } from "../../../../redux/actions/teacher/levels/creatFormativeAssessmentFileAction";

const defaultData = {
    fileName: "",
    assessmentType: undefined,
    totalMarks: "",
    subjectId: undefined,
    yearId: "",
    termId: "",
    class: "",
};

const GenerateAssessmentFileModal = ({
    api,
    open,
    onClose,
    loading,
    assessmentTypes,
    subLevelId,
    createFormativeAssessmentFile,
    createFormativeAssessmentFileError,
    assessmentFileId,
}) => {
    const dispatch = useDispatch();
    const [form, setForm] = useState(defaultData);

    const onHandleSubmit = () => {
        const formData = {
            file_name: form.fileName,
            class_id: subLevelId,
            total_marks: parseInt(form.totalMarks),
            assessment_file_id: assessmentFileId,
            assessment_type: form.assessmentType || "",
            close_file: false,
        };
        dispatch(CreateFormativeAssessmentFile(formData));
    };

    const onHandleChangeInput = (event) => {
        const { name, value } = event.target;
        const formData = { ...form };
        formData[name] = value;
        setForm(formData);
    };

    const onHandleSelectAssessment = (value) =>
        setForm((prev) => ({ ...prev, assessmentType: value }));

    useEffect(() => {
        if (createFormativeAssessmentFileError) {
            Toast(api, "Error", createFormativeAssessmentFileError.message);
            dispatch(clearCreateFormativeAssessmentFile());
        }

        if (createFormativeAssessmentFile) {
            Toast(api, "Success", createFormativeAssessmentFile.message);
            dispatch(clearCreateFormativeAssessmentFile());
            setForm(defaultData);
            onClose();
            dispatch(
                RetrieveAssessmentFile({
                    assessment_file_id: assessmentFileId,
                    formative_assessment: true,
                    level_id: subLevelId,
                })
            );
        }
    }, [
        api,
        dispatch,
        createFormativeAssessmentFile,
        createFormativeAssessmentFileError,
        onClose,
        assessmentFileId,
        subLevelId,
    ]);

    return (
        <Modal
            title={"Generate new assessment file"}
            open={open}
            closeIcon={false}
            footer={[
                <div className="flex-row-sb" key={"gen-new-assessment-file"}>
                    <Button
                        danger
                        key="cancel"
                        onClick={() => {
                            onClose();
                            setForm(defaultData);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        ghost
                        type="primary"
                        loading={loading}
                        onClick={onHandleSubmit}
                    >
                        Proceed
                    </Button>
                </div>,
            ]}
        >
            <div className="flex-column margin-top-20">
                <div className="flex-colum margin-top-10">
                    <span className="align-text-left margin-bottom-10">
                        Select assessment type
                    </span>
                    <Select
                        placeholder={"Select assessment type"}
                        style={{
                            width: "100%",
                            textAlign: "left",
                        }}
                        options={assessmentTypes}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            (option?.label?.toLowerCase() ?? "").includes(
                                input.toLowerCase()
                            )
                        }
                        showSearch
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                                .toLowerCase()
                                .localeCompare(
                                    (optionB?.label ?? "").toLowerCase()
                                )
                        }
                        value={form.assessmentType}
                        onChange={onHandleSelectAssessment}
                    />
                </div>

                <InputForm
                    type={"text"}
                    label={"File name"}
                    name="fileName"
                    placeholder={"Enter file name"}
                    value={form.fileName}
                    onChange={onHandleChangeInput}
                />
                <InputForm
                    type={"text"}
                    label={"Marks"}
                    name="totalMarks"
                    placeholder={"Enter marks for this assessment"}
                    value={form.totalMarks}
                    onChange={onHandleChangeInput}
                />
                <CustomSeparator />
            </div>
        </Modal>
    );
};

export default GenerateAssessmentFileModal;
