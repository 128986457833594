/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RetrieveAssessmentFile } from "../../../../redux/actions/teacher/levels/retrieveAssessmentFileAction";
import teacherSelector from "../../../../redux/selectors/teacherSelector";
import { Badge, Button, Table, Empty, Space, notification } from "antd";
import CustomSeparator from "../../../../components/common/customrSeparator";
import {
    CLOSED_STEP,
    OPENED_STEP,
} from "../../../../constants/action-types/steps";
import otherConfigurationSelector from "../../../../redux/selectors/otherConfigurationSelector";
import GenerateAssessmentFileModal from "../GenerateAssessmentFileModal";
import { CgDetailsMore, CgFile, CgFolder } from "react-icons/cg";
import AssessmentFileDetails from "../AssessmentFileDetails";
import { clearRecordStudentScores } from "../../../../redux/reducers/teacher/levels/recordStudentScoresReducer";
import { LeftOutlined } from "@ant-design/icons";
import CreateAssessmentFolderModal from "../CreateAssessmentFolder";
import CloseFolderModal from "../CloseFolderModal";

export const retrieveAssessmentFileData = {
    assessment_file_id: "",
    formative_assessment: false,
};

const AssessmentFiles = ({ dropdownData, subLevelId }) => {
    const dispatch = useDispatch();
    const [api, contextHolder] = notification.useNotification();
    const [openGenFile, setOpenGenFile] = useState(false);
    const [openCreateFolder, setOpenCreateFolder] = useState(false);
    const [openCloseFolderModal, setOpenCloseFolderModal] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [selectedFolder, setSelectedFolder] = useState();
    const [selectedFile, setSelectedFile] = useState();
    const {
        fetchDefaultData: { fetchDefaultData, LoadFetchDefaultData },
        retrieveSchoolConfiguration: { retrieveSchoolConfiguration },
    } = useSelector(otherConfigurationSelector);

    const alignSelfCenter = { alignSelf: "center" };

    const {
        retrieveAssessmentFile: {
            retrieveAssessmentFile,
            LoadRetrieveAssessmentFile,
        },
        generateAssessmentFile: {
            generateAssessmentFile,
            LoadGenerateAssessmentFile,
            generateAssessmentFileError,
        },
        createFormativeAssessmentFile: {
            createFormativeAssessmentFile,
            LoadCreateFormativeAssessmentFile,
            createFormativeAssessmentFileError,
        },
    } = useSelector(teacherSelector);

    const currentTerm =
        retrieveSchoolConfiguration?.data &&
        retrieveSchoolConfiguration?.data?.studying_terms.find(
            (term) => term?.is_current_term === true
        );

    const currentYear =
        retrieveSchoolConfiguration?.data &&
        retrieveSchoolConfiguration?.data?.studying_years.find(
            (term) => term?.is_current_year === true
        );

    const assessmentTypes =
        fetchDefaultData?.data?.assessment_types &&
        fetchDefaultData?.data?.assessment_types;

    const onCloseGenFile = () => setOpenGenFile(false);
    const onOpenGenFile = () => setOpenGenFile(true);
    const onCloseCreateFolder = () => setOpenCreateFolder(false);
    const onOpenCreateFolder = () => setOpenCreateFolder(true);

    const onCloseCloseFolderModal = () => setOpenCloseFolderModal(false);
    const onOpenCloseFolderModal = () => setOpenCloseFolderModal(true);

    const onCloseDrawer = () => {
        setOpenDrawer(false);
        setSelectedFile();
        dispatch(clearRecordStudentScores());
    };

    const assessmentFiles = retrieveAssessmentFile?.data;

    const folders =
        assessmentFiles?.files &&
        assessmentFiles?.files.map((file, index) => ({
            key: index,
            canceled: file?.canceled,
            fileName: file?.file_name,
            id: file?.id,
            state: file?.status,
        }));

    const files =
        assessmentFiles?.formative_files &&
        assessmentFiles?.formative_files.map((file, index) => ({
            key: index,
            canceled: file?.canceled,
            fileName: file?.file_name,
            id: file?.id,
            state: file?.status,
            isFile: true,
            assessmentType:
                assessmentTypes &&
                assessmentTypes.filter(
                    (item, index) => item?.key === file?.assessment_type
                )[0]?.type,
        }));

    const tableData = !selectedFolder ? folders : files;

    const displayFolders = () => {
        setSelectedFolder();
        const payload = {
            ...retrieveAssessmentFileData,
            level_id: subLevelId,
        };
        dispatch(RetrieveAssessmentFile(payload));
    };

    const tableColumns = [
        {
            title: "File",
            dataIndex: "fileName",
            key: "fileName",
            render: (name) => (
                <div className="flex-row">
                    {!files ? (
                        <CgFolder size={20} color="orange" />
                    ) : (
                        <CgFile size={20} color="green" />
                    )}
                    <span className="margin-left-10">{name}</span>
                </div>
            ),
        },
        selectedFolder
            ? {
                  title: "Assessment Type",
                  dataIndex: "assessmentType",
                  key: "assessmentType",
              }
            : {},
        {
            title: "Status",
            dataIndex: "state",
            key: "state",
            render: (state) => (
                <Badge
                    status={
                        (state === OPENED_STEP && "success") ||
                        (state === CLOSED_STEP && "error")
                    }
                    text={
                        (state === OPENED_STEP && "Opened") ||
                        (state === CLOSED_STEP && "Closed")
                    }
                />
            ),
        },
        {
            title: "Actions",
            dataIndex: "x",
            key: "x",
            render: (_, row) => (
                <Space key="on-details">
                    <a
                        style={{ marginLeft: "10px" }}
                        className="flex-row-sb deep-blue-text"
                        onClick={() => {
                            if (row.isFile) {
                                setSelectedFile(row.id);
                            } else {
                                setSelectedFolder({
                                    folderId: row.id,
                                    folderName: row.fileName,
                                });
                            }
                        }}
                    >
                        <CgDetailsMore size={20} />
                        Details
                    </a>
                </Space>
            ),
        },
    ];

    useEffect(() => {
        if (selectedFolder) {
            dispatch(
                RetrieveAssessmentFile({
                    assessment_file_id: selectedFolder?.folderId,
                    formative_assessment: true,
                    level_id: subLevelId,
                })
            );
        }

        if (subLevelId) {
            const payload = {
                assessment_file_id: "",
                formative_assessment: false,
                level_id: subLevelId || "",
            };
            dispatch(RetrieveAssessmentFile(payload));
        }
    }, [dispatch, selectedFolder, subLevelId]);

    return (
        <div className="flex-column width-100-percent">
            {contextHolder}
            <div className="flex-row-sb width-100">
                <div className="flex-row">
                    <span className="text-bold"></span>
                    {selectedFolder && (
                        <div className="flex-row">
                            <Button ghost type="link" onClick={displayFolders}>
                                <LeftOutlined
                                    size={30}
                                    style={alignSelfCenter}
                                />
                                Back to folders
                            </Button>
                            <span
                                className="margin-left-10 flex-row"
                                style={alignSelfCenter}
                            >
                                <CgFolder color="orange" />
                                <i className="deep-green-text margin-left-10">
                                    {selectedFolder?.folderName}
                                </i>
                            </span>
                        </div>
                    )}
                </div>
                <Space>
                    <Button
                        ghost
                        type="primary"
                        onClick={onOpenCreateFolder}
                        disabled={selectedFolder ? true : false}
                    >
                        Create Assessments Folder
                    </Button>
                    <Button
                        ghost
                        type="primary"
                        onClick={onOpenGenFile}
                        disabled={!selectedFolder ? true : false}
                    >
                        Generate Assessment File
                    </Button>
                    <Button
                        danger
                        onClick={onOpenCloseFolderModal}
                        disabled={selectedFolder ? false : true}
                    >
                        Close folder
                    </Button>
                </Space>
            </div>
            <CustomSeparator size={5} />
            <Table
                size="small"
                loading={LoadRetrieveAssessmentFile || LoadFetchDefaultData}
                columns={tableColumns}
                dataSource={tableData}
                pagination={
                    tableData && tableData.length > 8 ? { pageSize: 8 } : false
                }
                locale={{
                    emptyText: <Empty description="No assessment folder yet" />,
                }}
            />
            <GenerateAssessmentFileModal
                api={api}
                open={openGenFile}
                onClose={onCloseGenFile}
                assessmentTypes={
                    assessmentTypes &&
                    assessmentTypes.map((item, index) => ({
                        key: index,
                        label: item?.type,
                        value: item?.key,
                    }))
                }
                subLevelId={subLevelId}
                createFormativeAssessmentFile={createFormativeAssessmentFile}
                loading={LoadCreateFormativeAssessmentFile}
                createFormativeAssessmentFileError={
                    createFormativeAssessmentFileError
                }
                assessmentFileId={selectedFolder?.folderId}
            />

            <CreateAssessmentFolderModal
                api={api}
                open={openCreateFolder}
                onClose={onCloseCreateFolder}
                subjects={dropdownData}
                yearId={currentYear?.id}
                termId={currentTerm?.id}
                subLevelId={subLevelId}
                generateAssessmentFile={generateAssessmentFile}
                loading={LoadGenerateAssessmentFile}
                generateAssessmentFileError={generateAssessmentFileError}
            />
            <AssessmentFileDetails
                api={api}
                open={openDrawer}
                onClose={onCloseDrawer}
                fileId={selectedFile}
                setOpenDrawer={setOpenDrawer}
            />
            <CloseFolderModal
                api={api}
                open={openCloseFolderModal}
                onClose={onCloseCloseFolderModal}
                createFormativeAssessmentFile={createFormativeAssessmentFile}
                loading={LoadCreateFormativeAssessmentFile}
                createFormativeAssessmentFileError={
                    createFormativeAssessmentFileError
                }
                fileId={selectedFolder?.folderId}
            />
        </div>
    );
};

export default AssessmentFiles;
