import { createSlice } from "@reduxjs/toolkit";
import { UpdatePaymentRequest } from "../../../../actions/school/finance/payments/updatePaymentRequestActions";

const initialState = {
    updatePaymentRequest: null,
    LoadUpdatePaymentRequest: false,
    updatePaymentRequestError: null,
};

const updatePaymentRequestSlice = createSlice({
    name: "updatePaymentRequest",
    initialState: initialState,
    reducers: {
        clearUpdatePaymentRequest: (state) => {
            state.updatePaymentRequest = null;
            state.updatePaymentRequestError = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(UpdatePaymentRequest.pending, (state) => {
                state.LoadUpdatePaymentRequest = true;
                state.updatePaymentRequestError = null;
            })
            .addCase(UpdatePaymentRequest.fulfilled, (state, action) => {
                state.LoadUpdatePaymentRequest = false;
                state.updatePaymentRequest = action.payload;
            })
            .addCase(UpdatePaymentRequest.rejected, (state, action) => {
                state.LoadUpdatePaymentRequest = false;
                state.updatePaymentRequestError = action.payload;
            });
    },
});

export const { clearUpdatePaymentRequest } = updatePaymentRequestSlice.actions;

export default updatePaymentRequestSlice.reducer;
