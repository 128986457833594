import { createSlice } from "@reduxjs/toolkit";
import { AddClassOption } from "../../../actions/school/levels/addClassOptionActions";

const initialState = {
  addClassOption: null,
  LoadAddClassOption: false,
  addClassOptionError: null,
};

const addClassOptionSlice = createSlice({
  name: "addClassOption",
  initialState: initialState,
  reducers: {
    clearAddClassOption: (state) => {
      state.addClassOption = null;
      state.addClassOptionError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(AddClassOption.pending, (state) => {
        state.LoadAddClassOption = true;
        state.addClassOptionError = null;
      })
      .addCase(AddClassOption.fulfilled, (state, action) => {
        state.LoadAddClassOption = false;
        state.addClassOption = action.payload;
      })
      .addCase(AddClassOption.rejected, (state, action) => {
        state.LoadAddClassOption = false;
        state.addClassOptionError = action.payload;
      });
  },
});

export const { clearAddClassOption } = addClassOptionSlice.actions;

export default addClassOptionSlice.reducer;
