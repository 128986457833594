import { createSlice } from "@reduxjs/toolkit";
import { UpdateFeeType } from "../../../../actions/school/finance/manageFees/updateFeeTypeAction";

const initialState = {
  updateFeeType: null,
  LoadUpdateFeeType: false,
  updateFeeTypeError: null,
};

const updateFeeTypeSlice = createSlice({
  name: "updateFeeType",
  initialState: initialState,
  reducers: {
    clearUpdateFeeType: (state) => {
      state.updateFeeType = null;
      state.updateFeeTypeError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(UpdateFeeType.pending, (state) => {
        state.LoadUpdateFeeType = true;
        state.updateFeeTypeError = null;
      })
      .addCase(UpdateFeeType.fulfilled, (state, action) => {
        state.LoadUpdateFeeType = false;
        state.updateFeeType = action.payload;
      })
      .addCase(UpdateFeeType.rejected, (state, action) => {
        state.LoadUpdateFeeType = false;
        state.updateFeeTypeError = action.payload;
      });
  },
});

export const { clearUpdateFeeType } = updateFeeTypeSlice.actions;

export default updateFeeTypeSlice.reducer;
