import React, { useState,useEffect } from "react";
import { RiMenuLine } from "react-icons/ri";
import { APP_NAME } from "../../../constants/appLabel/appLabel";
import AppLogo from "../../../assets/images/darassaIcon.svg";
import "./style.css";
import CustomSpacer from "../../../components/common/customSpacer";
import SchoolRegistration from "../registration/schoolRegistration";
import { useDispatch } from 'react-redux';
import { GlobalOutlined } from '@ant-design/icons';
import { Dropdown,  Space, Menu , Button, Flex} from 'antd';
import { FetchEducationSystem } from '../../../redux/actions/school/otherConfiguration/fetchEducationSystemAction';
import useLanguage from "../../../hooks/useLanguage";




const PubPageTopBar = () => {
    const dispatch =  useDispatch()
    const { currentLangText,  languageDropdownItems, onHandleDropdown, translate } = useLanguage()
    const [openSideBar, setOpenSideBar]: any = useState('0');
    const [openSchoolRegister, setOpenSchoolRegister] = useState(false)

    const onOpenSchoolRegisterModal = () => setOpenSchoolRegister(true)


    const menu = (
        <Menu onClick={onHandleDropdown}>{
            languageDropdownItems.map((item) => (
            <Menu.Item key={item.key}>{item.label}</Menu.Item>
        ))}</Menu>
    );

    useEffect(() => {
        if (openSchoolRegister) {
            dispatch(FetchEducationSystem({url: "RW"}))
        }
    },[dispatch, openSchoolRegister, ])

    return (
        <div className="pub-top-nav-bar flex-row-sb">
            <div className="left-content">
                <div className="logo-items flex-row">
                    <img src={AppLogo} alt={APP_NAME} />
                    <span>{APP_NAME}</span>
                </div>
            </div>
            <div className="right-content-mb-view">
                <button  onClick={() => setOpenSideBar('100%')}>
                    <RiMenuLine size={30} color="white" />
                </button>
            </div>
            <div className="side-bar_mobile-menu" style={{ width: openSideBar }}>
                <div className="mobile-view-btn">
                    <div className="left-content">
                        <div className="logo-items flex-row">
                            <img src={AppLogo} alt={APP_NAME} />
                        </div>
                    </div>
                    <button
                        onClick={() => setOpenSideBar("0")}
                    >
                        &times;
                    </button>
                </div>
                <div className="mobile-view-btn-group">
                    <a href="/#" className="text-size-16">
                      {translate("Student Registration")} 
                    </a>
                    <CustomSpacer size={50} />
                    <a href="/#" className="text-size-16">
                        {translate("Login")}
                    </a>
                    <a href="/#" className="text-size-16">
                        {translate("About Us")}
                    </a>
                    <a href="/#" className="text-size-16">
                        {translate("Contact Us")}
                    </a>
                    <Dropdown overlay={menu}>
                        <Button ghost  style={{color: "white", width: "100%", marginTop: "30px"}}>
                            <Space>
                            {currentLangText}
                            <GlobalOutlined />
                            </Space>
                        </Button>
                    </Dropdown>
                </div>

            </div>
            <Flex gap="small" align="center" className="right-content margin-right-30">
                <a href="/#" onClick={onOpenSchoolRegisterModal} className="text-size-16">
                   {translate("Student Registration")} 
                </a>
                <CustomSpacer size={50} />
                <a href="/#" className="text-size-16">
                    {translate("Login")}
                </a>
                <a href="/#" className="text-size-16">
                    {translate("About Us")}
                </a>
                <a href="/#" className="text-size-16">
                    {translate("Contact Us")}
                </a>
                <Dropdown overlay={menu}>
                    <Button ghost  style={{color: "white", width: "120px"}}>
                        <Space>
                        {currentLangText}
                        <GlobalOutlined />
                        </Space>
                    </Button>
                </Dropdown>
            </Flex>
            <SchoolRegistration open={openSchoolRegister} setOpen={setOpenSchoolRegister} />
        </div>
    );
};

export default PubPageTopBar;
