import { createAsyncThunk } from "@reduxjs/toolkit/dist";
import apiRequest from "../../../helpers/apiActions/apiRequest";
import endPoints from "../../../utils/Endpoint";

export const SetLanguage = createAsyncThunk(
    "setLanguage",
    async (requestData, { rejectWithValue }) => {
        const response = await apiRequest({
            url: endPoints.language,
            method: "POST",
            requestData: requestData,
            rejectWithValue: rejectWithValue,
        });
        return response;
    }
);
