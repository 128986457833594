import { createSlice } from "@reduxjs/toolkit";
import { SignUp } from "../../actions/auth/signUpActions";

const initialState = {
  signUp: null,
  LoadSignUp: false,
  signUpError: null,
};

const signUpSlice = createSlice({
  name: "signUp",
  initialState: initialState,
  reducers: {
    clearSignUp: (state) => {
      state.signUp = null;
      state.signUpError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(SignUp.pending, (state) => {
        state.LoadSignUp = true;
        state.signUpError = null;
      })
      .addCase(SignUp.fulfilled, (state, action) => {
        state.LoadSignUp = false;
        state.signUp = action.payload;
      })
      .addCase(SignUp.rejected, (state, action) => {
        state.LoadSignUp = false;
        state.signUpError = action.payload;
      });
  },
});

export const { clearSignUp } = signUpSlice.actions;

export default signUpSlice.reducer;
