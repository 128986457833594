import React from "react";
import "./index.css";

const CustomSpacer = ({ size = 20, background = "transparent" }) => {
    return (
        <div
            className="custom-spacer"
            style={{ width: `${size}px`, background: background }}
        ></div>
    );
};

export default CustomSpacer;
