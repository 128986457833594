import { createSlice } from "@reduxjs/toolkit";
import { LogoutUser } from "../../actions/auth/logoutActions";

const initialState = {
  userLogout: null,
  loading: false,
  error: null,
};

const logoutUserSlice = createSlice({
  name: "logoutUser",
  initialState: initialState,
  reducers: {
    clearUserLogoutData: (state) => {
      state.userLogout = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(LogoutUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(LogoutUser.fulfilled, (state, action) => {
        state.loading = false;
        state.userLogout = action.payload;
      })
      .addCase(LogoutUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearUserLogoutData } = logoutUserSlice.actions;
export const logoutUser = (state) => state.userLogout.userLogout;

export default logoutUserSlice.reducer;
