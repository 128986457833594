import React from "react";
import { APP_SLOGAN } from "../../../constants/appLabel/appLabel";
import "./style.css";
import CustomSpacer from "../../../components/common/customSpacer";
import useLanguage from "../../../hooks/useLanguage";

export const WelcomePage = () => {
    const { translate } = useLanguage();
    return (
        <div className="welcome-page-content flex-row-sb">
            <div className="welcome-page-left-content">
                <div>
                    <div className="app-slogan-text">
                        <span>{translate(`${APP_SLOGAN}`)}</span>
                    </div>

                    <div className="buttons-group-one">
                        <a href="/#">{translate("Get started for free")}</a>
                        <CustomSpacer size={40} />
                        <a href="/#">{translate("Student Registration")}</a>
                    </div>
                </div>
            </div>
        </div>
    );
};
