import { createSlice } from "@reduxjs/toolkit";
import { GenerateFinaleStudentMarks } from "../../../actions/teacher/levels/generateFinaleStudentMarksAction";

const initialState = {
    generateFinaleStudentMarks: null,
    LoadGenerateFinaleStudentMarks: false,
    generateFinaleStudentMarksError: null,
};

const generateFinaleStudentMarksSlice = createSlice({
    name: "generateFinaleStudentMarks",
    initialState: initialState,
    reducers: {
        clearGenerateFinaleStudentMarks: (state) => {
            state.generateFinaleStudentMarks = null;
            state.generateFinaleStudentMarksError = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GenerateFinaleStudentMarks.pending, (state) => {
                state.LoadGenerateFinaleStudentMarks = true;
                state.generateFinaleStudentMarksError = null;
            })
            .addCase(GenerateFinaleStudentMarks.fulfilled, (state, action) => {
                state.LoadGenerateFinaleStudentMarks = false;
                state.generateFinaleStudentMarks = action.payload;
            })
            .addCase(GenerateFinaleStudentMarks.rejected, (state, action) => {
                state.LoadGenerateFinaleStudentMarks = false;
                state.generateFinaleStudentMarksError = action.payload;
            });
    },
});

export const { clearGenerateFinaleStudentMarks } =
    generateFinaleStudentMarksSlice.actions;

export default generateFinaleStudentMarksSlice.reducer;
