import {
    MailOutlined,
    HomeOutlined,
    TeamOutlined,
    ShoppingCartOutlined,
    BankOutlined,
    BookOutlined,
    UsergroupAddOutlined,
    ControlOutlined,
    SolutionOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { PiStudent } from "react-icons/pi";
import { MdManageAccounts, MdBook } from "react-icons/md";

export const navBarItems = [
    {
        key: "dashboard",
        icon: (
            <span className="menu-icon">
                <HomeOutlined />
            </span>
        ),
        label: <Link to="/dashboard">Home</Link>,
    },
    {
        key: "products",
        icon: (
            <span className="menu-icon">
                <ShoppingCartOutlined />
            </span>
        ),
        label: "Products",
    },
    {
        key: "sub1",
        icon: (
            <span className="menu-icon">
                <BankOutlined />
            </span>
        ),
        label: "Finance",
        children: [
            {
                key: "employee-compensation",
                label: (
                    <Link to="/employee-compensation">Salary Management</Link>
                ),
            },
            {
                key: "payments",
                label: <Link to="/payments">Student Billing</Link>,
            },
            {
                key: "reports",
                label: <Link to="/report">Financial Reports</Link>,
            },
        ],
    },
    {
        key: "sub2",
        icon: (
            <span className="menu-icon">
                <TeamOutlined />
            </span>
        ),
        label: "Students",
        children: [
            {
                key: "manage-students",
                label: <Link to="/manage-students">Manage Students</Link>,
            },
            {
                key: "school-students-evaluation",
                label: (
                    <Link to="/school-students-evaluation">
                        Student Evaluation
                    </Link>
                ),
            },
            // {
            //     key: "discipline",
            //     label: "Discipline",
            // },
        ],
    },
    {
        key: "teachers",
        icon: (
            <span className="menu-icon">
                <UsergroupAddOutlined />
            </span>
        ),
        label: <Link to="/teachers">Teachers</Link>,
    },
    {
        key: "employees",
        icon: (
            <span className="menu-icon">
                <SolutionOutlined />
            </span>
        ),
        label: <Link to="/employees">Employees</Link>,
    },
    {
        key: "library",
        icon: (
            <span className="menu-icon">
                <BookOutlined />
            </span>
        ),
        label: <Link to="/school-library">Library</Link>,
    },
    {
        key: "divider",
        type: "divider",
    },
    { type: "divider" },
    {
        key: "notifications",
        icon: (
            <span className="menu-icon">
                <MailOutlined />
            </span>
        ),
        label: "Notifications",
    },
    {
        key: "configuration",
        icon: (
            <span className="menu-icon">
                <ControlOutlined />
            </span>
        ),
        label: <Link to="/configuration">Configuration</Link>,
    },
    {
        key: "manage-account",
        icon: (
            <span className="menu-icon">
                <MdManageAccounts size={20} />
            </span>
        ),
        label: <Link to="/manage-account">Manage Account</Link>,
    },
];

export const teacherNavBarItems = [
    {
        key: "teacher-dashboard",
        icon: (
            <span className="menu-icon">
                <HomeOutlined />
            </span>
        ),
        label: <Link to="/teacher-dashboard">Home</Link>,
    },
    {
        key: "teacher-classes",
        icon: (
            <span className="menu-icon">
                <MdBook size={20} />
            </span>
        ),
        label: <Link to="/teacher-classes">Manage Classes</Link>,
    },
    {
        key: "teacher-students",
        icon: (
            <span className="menu-icon">
                <PiStudent size={20} />
            </span>
        ),
        label: <Link to="/teacher-students">Students</Link>,
    },
];
