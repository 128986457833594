import { createSlice } from "@reduxjs/toolkit";
import { RetrieveAllStudentsMarks } from "../../../actions/teacher/levels/retrieveAlltudentsMarksAction";

const initialState = {
    retrieveAllStudentsMarks: null,
    LoadRetrieveAllStudentsMarks: false,
    retrieveAllStudentsMarksError: null,
};

const retrieveAllStudentsMarksSlice = createSlice({
    name: "retrieveAllStudentsMarks",
    initialState: initialState,
    reducers: {
        clearRetrieveAllStudentsMarks: (state) => {
            state.retrieveAllStudentsMarks = null;
            state.retrieveAllStudentsMarksError = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(RetrieveAllStudentsMarks.pending, (state) => {
                state.LoadRetrieveAllStudentsMarks = true;
                state.retrieveAllStudentsMarksError = null;
            })
            .addCase(RetrieveAllStudentsMarks.fulfilled, (state, action) => {
                state.LoadRetrieveAllStudentsMarks = false;
                state.retrieveAllStudentsMarks = action.payload;
            })
            .addCase(RetrieveAllStudentsMarks.rejected, (state, action) => {
                state.LoadRetrieveAllStudentsMarks = false;
                state.retrieveAllStudentsMarksError = action.payload;
            });
    },
});

export const { clearRetrieveAllStudentsMarks } =
    retrieveAllStudentsMarksSlice.actions;

export default retrieveAllStudentsMarksSlice.reducer;
