const URL_API =
    process.env.REACT_APP_ENV === "development"
        ? process.env.REACT_APP_URL_API
        : "http://64.225.3.178:5000/api";

const defaultUrls = { apiV1: URL_API };
const token = localStorage.getItem("userToken");

const apiRequest = async ({ url, method, requestData, rejectWithValue }) => {
    const requestHeader = {
        "Content-Type": "application/json",
        Authorization: !token ? "" : `Bearer ${token}`,
    };

    const postRequestOptions = {
        method: method,
        headers: requestHeader,
        body: JSON.stringify(requestData),
    };

    const fetchRequestOptions = {
        method: method,
        headers: requestHeader,
    };

    try {
        const response = await fetch(
            `${defaultUrls.apiV1}/${url}`,
            ["POST", "PUT", "DELETE"].includes(method)
                ? postRequestOptions
                : fetchRequestOptions
        );

        if (!response.ok) {
            const error = await response.json();
            throw error;
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        return rejectWithValue(error);
    }
};

export default apiRequest;
