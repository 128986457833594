import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import authSelector from "../redux/selectors/authSelector";
import { SetLanguage } from "../redux/actions/auth/setLanguage";
import {
    languageDropdownItems,
    defaultLang,
} from "../../src/constants/appLabel/languageItem";

const useLanguage = () => {
    const dispatch = useDispatch();
    const lang = localStorage.getItem("lang");
    const [currentLang, setCurrentLang] = useState();
    const [currentLangText, setCurrentLangText] = useState();

    const {
        setLanguage: { languageData, loading },
    } = useSelector(authSelector);

    const onHandleDropdown = ({ key }) => {
        const lang = languageDropdownItems.find((item) => item.key === key);
        setCurrentLangText(lang.label);
        localStorage.setItem("lang", lang.key);
    };

    const onHandleChangeLang = (key) => {
        const setDefaultLang = languageDropdownItems.find(
            (item) => item.key === key
        );
        setCurrentLang(setDefaultLang.key);
        setCurrentLangText(setDefaultLang.label);
    };

    const onHandleTranslate = (text) => {
        const storedItems = localStorage.getItem("langData");

        if (storedItems) {
            const parseItems = JSON.parse(storedItems);
            const translation = parseItems.find((t) => t[defaultLang] === text);
            return !translation ? text : translation[currentLang];
        }
        return text;
    };

    useEffect(() => {
        dispatch(SetLanguage({ language: currentLang || defaultLang }));
    }, [dispatch, currentLang]);

    useEffect(() => {
        if (!lang) {
            onHandleChangeLang(defaultLang);
        }

        if (lang) {
            onHandleChangeLang(lang);
        }

        if (languageData) {
            const filterLanguage = languageData?.data?.map((item) => item);
            localStorage.setItem("langData", JSON.stringify(filterLanguage));
        }
    }, [lang, languageData]);

    return {
        loading,
        onHandleDropdown,
        currentLangText,
        languageDropdownItems,
        translate: onHandleTranslate,
    };
};

export default useLanguage;
