import { Select } from "antd";
import React from "react";

const levelOrganizedOption = [
    {
        value: "is_level_one",
        label: "Level one",
    },
    {
        value: "is_level_two",
        label: "Level two",
    },
    {
        value: "is_level_three",
        label: "Level three",
    },
];

const LevelOrganized = () => {
    return (
        <Select
            showSearch
            style={{ width: "45%" }}
            size="large"
            placeholder="Select your level of study you organize"
            optionFilterProp="children"
            filterOption={(input, option) =>
                (option?.label ?? "").includes(input)
            }
            filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={levelOrganizedOption}
        />
    );
};

export default LevelOrganized;
