import { createSlice } from "@reduxjs/toolkit";
import { FetchGlobalSummary } from "../../../actions/school/summary/fetchGlobalSummaryActions";

const initialState = {
    fetchGlobalSummary: null,
    LoadFetchGlobalSummary: false,
    fetchGlobalSummaryError: null,
};

const fetchGlobalSummarySlice = createSlice({
    name: "fetchGlobalSummary",
    initialState: initialState,
    reducers: {
        clearFetchGlobalSummary: (state) => {
            state.fetchGlobalSummary = null;
            state.fetchGlobalSummaryError = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(FetchGlobalSummary.pending, (state) => {
                state.LoadFetchGlobalSummary = true;
                state.fetchGlobalSummaryError = null;
            })
            .addCase(FetchGlobalSummary.fulfilled, (state, action) => {
                state.LoadFetchGlobalSummary = false;
                state.fetchGlobalSummary = action.payload;
            })
            .addCase(FetchGlobalSummary.rejected, (state, action) => {
                state.LoadFetchGlobalSummary = false;
                state.fetchGlobalSummaryError = action.payload;
            });
    },
});

export const { clearFetchGlobalSummary } = fetchGlobalSummarySlice.actions;

export default fetchGlobalSummarySlice.reducer;
