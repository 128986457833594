import { createSlice } from "@reduxjs/toolkit";
import { RetrieveBorrowingStudents } from "../../../actions/school/library/retrieveBorrowingStudentsAction";

const initialState = {
    retrieveBorrowingStudents: null,
    loadingRetrieveBorrowingStudents: false,
    retrieveBorrowingStudentsError: null,
};

const retrieveBorrowingStudentsSlice = createSlice({
    name: "retrieveBorrowingStudents",
    initialState: initialState,
    reducers: {
        clearRetrieveBorrowingStudents: (state) => {
            state.retrieveBorrowingStudents = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(RetrieveBorrowingStudents.pending, (state) => {
                state.loadingRetrieveBorrowingStudents = true;
                state.retrieveBorrowingStudentsError = null;
            })
            .addCase(RetrieveBorrowingStudents.fulfilled, (state, action) => {
                state.loadingRetrieveBorrowingStudents = false;
                state.retrieveBorrowingStudents = action.payload;
            })
            .addCase(RetrieveBorrowingStudents.rejected, (state, action) => {
                state.loadingRetrieveBorrowingStudents = false;
                state.retrieveBorrowingStudentsError = action.payload;
            });
    },
});

export const { clearRetrieveBorrowingStudents } =
    retrieveBorrowingStudentsSlice.actions;

export default retrieveBorrowingStudentsSlice.reducer;
