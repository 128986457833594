import { createSlice } from "@reduxjs/toolkit";
import { LoginUser } from "../../actions/auth/authActions";

const initialState = {
  userData: null,
  loading: false,
  error: null,
};

const loginUserSlice = createSlice({
  name: "loginUser",
  initialState: initialState,
  reducers: {
    setUser: (state, action) => {
      state.userData = action.payload;
    },
    clearUserLoginData: (state) => {
      state.userData = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(LoginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(LoginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.userData = action.payload;
      })
      .addCase(LoginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setUser, clearUserLoginData } = loginUserSlice.actions;
export const selectUser = (state) => state.userData.userData;

export default loginUserSlice.reducer;
