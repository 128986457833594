import { createSlice } from "@reduxjs/toolkit";
import { RetrieveClassesStudents } from "../../../actions/school/students/retrieveClassesStudentsAction";

const initialState = {
    retrieveClassesStudents: null,
    LoadRetrieveClassesStudents: false,
    retrieveClassesStudentsError: null,
};

const retrieveClassesStudentsSlice = createSlice({
    name: "retrieveClassesStudents",
    initialState: initialState,
    reducers: {
        clearRetrieveClassesStudents: (state) => {
            state.retrieveClassesStudents = null;
            state.retrieveClassesStudentsError = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(RetrieveClassesStudents.pending, (state) => {
                state.LoadRetrieveClassesStudents = true;
                state.retrieveClassesStudentsError = null;
            })
            .addCase(RetrieveClassesStudents.fulfilled, (state, action) => {
                state.LoadRetrieveClassesStudents = false;
                state.retrieveClassesStudents = action.payload;
            })
            .addCase(RetrieveClassesStudents.rejected, (state, action) => {
                state.LoadRetrieveClassesStudents = false;
                state.retrieveClassesStudentsError = action.payload;
            });
    },
});

export const { clearRetrieveClassesStudents } =
    retrieveClassesStudentsSlice.actions;

export default retrieveClassesStudentsSlice.reducer;
