import { createSlice } from "@reduxjs/toolkit";
import { RetrieveStudentSubjectMarks } from "../../../actions/teacher/levels/retrieveStudentSubjectMarksAction";

const initialState = {
    retrieveStudentSubjectMarks: null,
    LoadRetrieveStudentSubjectMarks: false,
    retrieveStudentSubjectMarksError: null,
};

const retrieveStudentSubjectMarksSlice = createSlice({
    name: "retrieveStudentSubjectMarks",
    initialState: initialState,
    reducers: {
        clearRetrieveStudentSubjectMarks: (state) => {
            state.retrieveStudentSubjectMarks = null;
            state.retrieveStudentSubjectMarksError = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(RetrieveStudentSubjectMarks.pending, (state) => {
                state.LoadRetrieveStudentSubjectMarks = true;
                state.retrieveStudentSubjectMarksError = null;
            })
            .addCase(RetrieveStudentSubjectMarks.fulfilled, (state, action) => {
                state.LoadRetrieveStudentSubjectMarks = false;
                state.retrieveStudentSubjectMarks = action.payload;
            })
            .addCase(RetrieveStudentSubjectMarks.rejected, (state, action) => {
                state.LoadRetrieveStudentSubjectMarks = false;
                state.retrieveStudentSubjectMarksError = action.payload;
            });
    },
});

export const { clearRetrieveStudentSubjectMarks } =
    retrieveStudentSubjectMarksSlice.actions;

export default retrieveStudentSubjectMarksSlice.reducer;
