import { createSlice } from "@reduxjs/toolkit";
import { UpdateClassOption } from "../../../actions/school/levels/updateClassOptionActions";

const initialState = {
  updateClassOption: null,
  LoadUpdateClassOption: false,
  updateClassOptionError: null,
};

const updateClassOptionSlice = createSlice({
  name: "updateClassOption",
  initialState: initialState,
  reducers: {
    clearUpdateClassOption: (state) => {
      state.updateClassOption = null;
      state.updateClassOptionError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(UpdateClassOption.pending, (state) => {
        state.LoadUpdateClassOption = true;
        state.updateClassOptionError = null;
      })
      .addCase(UpdateClassOption.fulfilled, (state, action) => {
        state.LoadUpdateClassOption = false;
        state.updateClassOption = action.payload;
      })
      .addCase(UpdateClassOption.rejected, (state, action) => {
        state.LoadUpdateClassOption = false;
        state.updateClassOptionError = action.payload;
      });
  },
});

export const { clearUpdateClassOption } = updateClassOptionSlice.actions;

export default updateClassOptionSlice.reducer;
